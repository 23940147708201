// Extract YouTube video ID from URL
const getYoutubeVideoId = (url) => {
  const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
  const match = url.match(regExp);
  return (match && match[2].length === 11) ? match[2] : null;
};

// Get service type from URL
export const getServiceType = (url) => {
  const hostname = url.hostname.toLowerCase();
  
  // Social Media & Content
  if (hostname.includes('youtube.com') || hostname.includes('youtu.be')) return 'youtube';
  if (hostname.includes('github.com')) return 'github';
  if (hostname.includes('medium.com')) return 'medium';
  if (hostname.includes('twitter.com') || hostname.includes('x.com')) return 'twitter';
  if (hostname.includes('linkedin.com')) return 'linkedin';
  if (hostname.includes('instagram.com')) return 'instagram';
  if (hostname.includes('facebook.com')) return 'facebook';
  if (hostname.includes('twitch.tv')) return 'twitch';
  if (hostname.includes('reddit.com')) return 'reddit';
  if (hostname.includes('pinterest.com')) return 'pinterest';
  
  // Development & Tech
  if (hostname.includes('stackoverflow.com')) return 'stackoverflow';
  if (hostname.includes('codepen.io')) return 'codepen';
  if (hostname.includes('npmjs.com')) return 'npm';
  if (hostname.includes('dev.to')) return 'devto';
  if (hostname.includes('hashnode.com')) return 'hashnode';
  if (hostname.includes('gitlab.com')) return 'gitlab';
  if (hostname.includes('bitbucket.org')) return 'bitbucket';
  if (hostname.includes('netlify.com')) return 'netlify';
  if (hostname.includes('vercel.com')) return 'vercel';
  if (hostname.includes('firebase.google.com')) return 'firebase';
  
  // Creative & Design
  if (hostname.includes('behance.net')) return 'behance';
  if (hostname.includes('dribbble.com')) return 'dribbble';
  if (hostname.includes('vimeo.com')) return 'vimeo';
  
  // Music & Audio
  if (hostname.includes('spotify.com')) return 'spotify';
  if (hostname.includes('soundcloud.com')) return 'soundcloud';
  
  // Productivity & Communication
  if (hostname.includes('notion.so')) return 'notion';
  if (hostname.includes('wordpress.com')) return 'wordpress';
  if (hostname.includes('slack.com')) return 'slack';
  if (hostname.includes('discord.com')) return 'discord';
  if (hostname.includes('telegram.org')) return 'telegram';
  if (hostname.includes('trello.com')) return 'trello';
  if (hostname.includes('atlassian.net')) {
    if (url.pathname.includes('jira')) return 'jira';
  }

  return 'link';
};

// Get metadata based on URL patterns
export const getLinkMetadata = async (link) => {
  const url = new URL(link.url);
  const type = getServiceType(url);
  let metadata = null;

  console.log('Getting metadata for URL:', link.url); // Debug log
  
  // Try to fetch OpenGraph metadata first
  try {
    const response = await fetch(`https://api.microlink.io?url=${encodeURIComponent(link.url)}`);
    const data = await response.json();
    console.log('Microlink API response:', data); // Debug log
    
    if (data.status === 'success') {
      metadata = {
        type,
        title: data.data.title,
        description: data.data.description,
        image: data.data.image?.url || data.data.logo?.url || '',
        favicon: data.data.logo?.url || ''
      };

      // For YouTube videos, add video-specific metadata
      if (type === 'youtube') {
        const videoId = getYoutubeVideoId(link.url);
        metadata = {
          ...metadata,
          image: `https://img.youtube.com/vi/${videoId}/maxresdefault.jpg`,
          fallbackImage: `https://img.youtube.com/vi/${videoId}/0.jpg`,
          videoId,
          description: metadata.description || 'YouTube Video'
        };
      }

      console.log('Processed metadata:', metadata); // Debug log
      return metadata;
    }
  } catch (error) {
    console.error('Error fetching OpenGraph metadata:', error);
  }
  
  // Fallback handlers for specific services
  
  // YouTube fallback
  if (type === 'youtube') {
    const videoId = getYoutubeVideoId(link.url);
    if (videoId) {
      metadata = {
        type,
        title: link.title || 'YouTube Video',
        image: `https://img.youtube.com/vi/${videoId}/maxresdefault.jpg`,
        fallbackImage: `https://img.youtube.com/vi/${videoId}/0.jpg`,
        description: 'YouTube Video',
        videoId
      };
      console.log('YouTube fallback metadata:', metadata); // Debug log
      return metadata;
    }
  }
  
  // GitHub fallback
  if (type === 'github') {
    try {
      const parts = url.pathname.split('/').filter(Boolean);
      if (parts.length >= 2) {
        const response = await fetch(`https://api.github.com/repos/${parts[0]}/${parts[1]}`);
        const data = await response.json();
        
        metadata = {
          type,
          title: data.full_name || link.title,
          description: data.description || 'GitHub Repository',
          image: data.owner?.avatar_url || '',
          stats: {
            stars: data.stargazers_count,
            forks: data.forks_count,
            language: data.language
          }
        };
        console.log('GitHub fallback metadata:', metadata); // Debug log
        return metadata;
      }
    } catch (error) {
      console.error('Error fetching GitHub metadata:', error);
    }
  }

  // Final fallback
  metadata = {
    type,
    title: link.title || url.hostname.replace('www.', ''),
    description: url.hostname.replace('www.', ''),
    image: ''
  };
  console.log('Final fallback metadata:', metadata); // Debug log
  return metadata;
};
