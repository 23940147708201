import React, { useState, useEffect } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { userService } from '../services/firestore';

import ProfileHeader from '../components/profile/ProfileHeader';
import ProfileAbout from '../components/profile/ProfileAbout';
import ProfileSocialLinks from '../components/profile/ProfileSocialLinks';
import ProfileModules from '../components/profile/ProfileModules';
import Modal from '../components/common/Modal';
import ThemeCustomizer from '../components/profile/ThemeCustomizer';
import Toast from '../components/common/Toast';

const Profile = () => {
  const { user } = useAuth();
  const [showCopiedToast, setShowCopiedToast] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [isEditingTheme, setIsEditingTheme] = useState(false);
  
  const [profile, setProfile] = useState({
    name: '',
    username: '',
    email: '',
    location: '',
    about: '',
    socialLinks: [],
    theme: {
      primaryColor: '#4F46E5',
      secondaryColor: '#9333EA',
      gradientType: 'linear',
      gradientDirection: '45deg',
      gradientPosition: 'center',
      textColor: '#FFFFFF',
      pattern: 'none'
    }
  });

  // Initialize profile with user data
  useEffect(() => {
    if (user) {
      const fetchProfile = async () => {
        try {
          // Get the full profile data from Firestore
          const profileData = await userService.getUserProfile(user.username);
          if (profileData) {
            const socialLinks = [];
            if (profileData.github_url?.trim()) socialLinks.push({ url: profileData.github_url });
            if (profileData.linkedin_url?.trim()) socialLinks.push({ url: profileData.linkedin_url });
            if (profileData.twitter_url?.trim()) socialLinks.push({ url: profileData.twitter_url });
            if (profileData.website_url?.trim()) socialLinks.push({ url: profileData.website_url });
            
            setProfile({
              name: profileData.name || '',
              username: profileData.username || '',
              email: profileData.email || '',
              location: profileData.location || '',
              about: profileData.about || '',
              socialLinks,
              theme: profileData.theme || {
                primaryColor: '#4F46E5',
                secondaryColor: '#9333EA',
                gradientType: 'linear',
                gradientDirection: '45deg',
                gradientPosition: 'center',
                textColor: '#FFFFFF'
              }
            });
          }
        } catch (error) {
          console.error('Error fetching profile:', error);
        }
      };

      fetchProfile();
    }
  }, [user]);

  const handleProfileChange = (field, value) => {
    setProfile(prev => ({
      ...prev,
      [field]: value
    }));
  };

  const copyProfileLink = async () => {
    if (profile.username) {
      const profileLink = `${window.location.origin}/profile/${profile.username}`;
      try {
        await navigator.clipboard.writeText(profileLink);
        setShowCopiedToast(true);
        setTimeout(() => setShowCopiedToast(false), 2000);
      } catch (err) {
        console.error('Failed to copy:', err);
      }
    }
  };

  const validateUsername = (username) => {
    if (!username) return true; // Allow empty username (backend will generate one)
    const usernameRegex = /^[a-zA-Z0-9_-]+$/;
    return usernameRegex.test(username);
  };

  const handleSave = async () => {
    try {
      if (profile.username && !validateUsername(profile.username)) {
        throw new Error('Username can only contain letters, numbers, dashes, and underscores');
      }

      const validLinks = profile.socialLinks
        .filter(link => link && link.url && link.url.trim() !== '')
        .slice(0, 4);

      const userData = {
        name: profile.name,
        username: profile.username,
        email: profile.email,
        location: profile.location,
        about: profile.about,
        github_url: validLinks[0]?.url || '',
        linkedin_url: validLinks[1]?.url || '',
        twitter_url: validLinks[2]?.url || '',
        website_url: validLinks[3]?.url || '',
        theme: profile.theme
      };

      await userService.updateUserProfile(user.uid, userData);
      setIsEditing(false);
    } catch (error) {
      console.error('Failed to update profile:', error);
    }
  };

  const handleCancel = () => {
    if (user) {
      const socialLinks = [];
      if (user.github_url?.trim()) socialLinks.push({ url: user.github_url });
      if (user.linkedin_url?.trim()) socialLinks.push({ url: user.linkedin_url });
      if (user.twitter_url?.trim()) socialLinks.push({ url: user.twitter_url });
      if (user.website_url?.trim()) socialLinks.push({ url: user.website_url });

      setProfile(prevProfile => ({
        ...prevProfile,
        name: user.name || '',
        username: user.username || '',
        email: user.email || '',
        location: user.location || '',
        about: user.about || '',
        socialLinks,
        theme: user.theme || {
          primaryColor: '#4F46E5',
          secondaryColor: '#9333EA',
          gradientType: 'linear',
          gradientDirection: '45deg',
          gradientPosition: 'center',
          textColor: '#FFFFFF',
          pattern: 'none'
        }
      }));
    }
    setIsEditing(false);
  };

  const updateSocialLink = (index, field, value) => {
    const newSocialLinks = [...profile.socialLinks];
    newSocialLinks[index] = { ...newSocialLinks[index], [field]: value };
    setProfile({ ...profile, socialLinks: newSocialLinks });
  };

  const addSocialLink = () => {
    if (profile.socialLinks.length < 4) {
      setProfile(prev => ({
        ...prev,
        socialLinks: [...prev.socialLinks, { url: '' }]
      }));
    }
  };

  const removeSocialLink = (index) => {
    setProfile(prev => ({
      ...prev,
      socialLinks: prev.socialLinks.filter((_, i) => i !== index)
    }));
  };

  return (
    <div className="min-h-screen bg-gray-50 dark:bg-gray-900">
      <div className="max-w-4xl mx-auto px-4 py-6 space-y-8">
        {/* Profile Information Section */}
        <div className="bg-white dark:bg-gray-800 rounded-xl shadow-lg overflow-hidden">
          <ProfileHeader
            profile={profile}
            isEditing={isEditing}
            onEdit={() => setIsEditing(true)}
            onSave={handleSave}
            onCancel={handleCancel}
            onProfileChange={handleProfileChange}
            onCopyLink={copyProfileLink}
            onEditTheme={() => setIsEditingTheme(true)}
          />
          
          <div className="relative px-4 sm:px-6 pb-6">
            <ProfileAbout 
              about={profile.about}
              isEditing={isEditing}
              onChange={(value) => handleProfileChange('about', value)}
            />

            <ProfileSocialLinks 
              socialLinks={profile.socialLinks}
              isEditing={isEditing}
              onAdd={addSocialLink}
              onUpdate={updateSocialLink}
              onRemove={removeSocialLink}
            />

          </div>
        </div>

        {/* Modules Section */}
        <ProfileModules />

        {/* Toast Notification */}
        <Toast
          message="Profile link copied to clipboard!"
          isVisible={showCopiedToast}
          className="fixed bottom-4 left-4 right-4 sm:left-auto sm:right-4 z-50"
        />

        {/* Theme Customization Modal */}
        <Modal
          isOpen={isEditingTheme}
          onClose={() => setIsEditingTheme(false)}
          title="Customize Theme"
        >
          <ThemeCustomizer
            currentTheme={profile.theme}
            onSave={async (newTheme) => {
              try {
                await userService.updateUserProfile(user.uid, {
                  ...profile,
                  theme: newTheme
                });
                setProfile(prev => ({
                  ...prev,
                  theme: newTheme
                }));
                setIsEditingTheme(false);
              } catch (error) {
                console.error('Failed to update theme:', error);
              }
            }}
          />
        </Modal>
      </div>
    </div>
  );
};

export default Profile;
