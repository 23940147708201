import React, { useState, useEffect } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useNavigate } from 'react-router-dom';
import ComponentLibrary from '../components/template-builder/ComponentLibrary';
import TemplateCanvas from '../components/template-builder/TemplateCanvas';
import PropertiesPanel from '../components/template-builder/PropertiesPanel';
import { HiOutlineSave, HiOutlineDocumentDuplicate, HiOutlineTrash, HiArrowLeft } from 'react-icons/hi';

const TemplateBuilder = () => {
  const navigate = useNavigate();
  const [templateName, setTemplateName] = useState('Untitled Template');
  const [components, setComponents] = useState([]);
  const [selectedComponent, setSelectedComponent] = useState(null);
  const [isEditingName, setIsEditingName] = useState(false);

  useEffect(() => {
    // Check if device is mobile
    const isMobile = window.innerWidth <= 768;
    if (isMobile) {
      navigate('/templates');
    }

    // Add resize listener to redirect if window is resized to mobile dimensions
    const handleResize = () => {
      if (window.innerWidth <= 768) {
        navigate('/templates');
      }
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [navigate]);

  const handleComponentUpdate = (updatedComponents) => {
    setComponents(updatedComponents);
  };

  const handleComponentSelect = (componentId) => {
    const component = components.find(c => c.id === componentId);
    setSelectedComponent(component);
  };

  const handleComponentSettingsUpdate = (updatedComponent) => {
    setComponents(components.map(c => 
      c.id === updatedComponent.id ? updatedComponent : c
    ));
    setSelectedComponent(updatedComponent);
  };

  const handleComponentDelete = (componentId) => {
    setComponents(components.filter(c => c.id !== componentId));
    setSelectedComponent(null);
  };

  const handleSaveTemplate = () => {
    // TODO: Implement template saving logic
    const template = {
      name: templateName,
      components,
      lastModified: new Date().toISOString(),
    };
    console.log('Saving template:', template);
  };

  const handleDuplicateTemplate = () => {
    // TODO: Implement template duplication logic
    console.log('Duplicating template');
  };

  const handleDeleteTemplate = () => {
    // TODO: Implement template deletion logic
    console.log('Deleting template');
  };

  const handleBack = () => {
    navigate('/templates');
  };

  return (
    <DndProvider backend={HTML5Backend}>
      <div className="h-screen flex flex-col bg-white dark:bg-gray-900">
        {/* Header */}
        <div className="flex items-center justify-between px-6 py-4 border-b border-gray-200 dark:border-gray-700">
          <div className="flex items-center space-x-4">
            <button
              onClick={handleBack}
              className="flex items-center text-gray-600 dark:text-gray-300 hover:text-gray-900 dark:hover:text-white"
            >
              <HiArrowLeft className="w-6 h-6" />
            </button>
            {isEditingName ? (
              <input
                type="text"
                value={templateName}
                onChange={(e) => setTemplateName(e.target.value)}
                onBlur={() => setIsEditingName(false)}
                onKeyPress={(e) => e.key === 'Enter' && setIsEditingName(false)}
                className="px-2 py-1 border border-gray-300 dark:border-gray-600 rounded-md bg-white dark:bg-gray-800 text-gray-900 dark:text-white"
                autoFocus
              />
            ) : (
              <h1
                className="text-xl font-semibold text-gray-900 dark:text-white cursor-pointer hover:text-gray-600 dark:hover:text-gray-300"
                onClick={() => setIsEditingName(true)}
              >
                {templateName}
              </h1>
            )}
          </div>
          
          <div className="flex items-center space-x-4">
            <button
              onClick={handleSaveTemplate}
              className="flex items-center px-4 py-2 text-sm font-medium text-white bg-blue-600 rounded-lg hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            >
              <HiOutlineSave className="w-4 h-4 mr-2" />
              Save
            </button>
            
            <button
              onClick={handleDuplicateTemplate}
              className="flex items-center px-4 py-2 text-sm font-medium text-gray-700 dark:text-gray-200 bg-white dark:bg-gray-800 rounded-lg border border-gray-300 dark:border-gray-600 hover:bg-gray-50 dark:hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            >
              <HiOutlineDocumentDuplicate className="w-4 h-4 mr-2" />
              Duplicate
            </button>
            
            <button
              onClick={handleDeleteTemplate}
              className="flex items-center px-4 py-2 text-sm font-medium text-red-600 dark:text-red-400 bg-white dark:bg-gray-800 rounded-lg border border-gray-300 dark:border-gray-600 hover:bg-gray-50 dark:hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
            >
              <HiOutlineTrash className="w-4 h-4 mr-2" />
              Delete
            </button>
          </div>
        </div>

        {/* Main Content */}
        <div className="flex-1 flex overflow-hidden">
          {/* Component Library */}
          <ComponentLibrary />

          {/* Template Canvas */}
          <div className="flex-1 overflow-y-auto p-6">
            <TemplateCanvas
              components={components}
              onUpdate={handleComponentUpdate}
              onSelect={handleComponentSelect}
              selectedComponentId={selectedComponent?.id}
            />
          </div>

          {/* Properties Panel */}
          <PropertiesPanel
            selectedComponent={selectedComponent}
            onUpdate={handleComponentSettingsUpdate}
            onDelete={handleComponentDelete}
          />
        </div>
      </div>
    </DndProvider>
  );
};

export default TemplateBuilder;
