import React, { useState, Suspense, lazy } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { HiDocumentText } from 'react-icons/hi';

// Lazy load resume components
const WorkHistory = lazy(() => import('../resume/WorkHistory'));
const Skills = lazy(() => import('../resume/Skills'));
const Projects = lazy(() => import('../resume/Projects'));
const Education = lazy(() => import('../resume/Education'));

const ProfileResume = ({ resumeData, userModules }) => {
  const [activeTab, setActiveTab] = useState(null);

  if (!userModules?.resume || !resumeData) return null;

  const fadeInUp = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 }
  };

  const getAvailableTabs = () => {
    if (!resumeData) return [];
    
    return [
      { id: 'work', label: 'Experience', data: resumeData.workHistory },
      { id: 'education', label: 'Education', data: resumeData.education },
      { id: 'projects', label: 'Projects', data: resumeData.projects },
      { id: 'skills', label: 'Skills', data: resumeData.skills }
    ].filter(tab => tab.data?.length > 0);
  };

  const availableTabs = getAvailableTabs();
  
  if (availableTabs.length === 0) return null;

  // Set initial active tab if not set
  if (!activeTab && availableTabs.length > 0) {
    setActiveTab(availableTabs[0].id);
  }

  return (
    <motion.div 
      variants={fadeInUp}
      className="mt-8 bg-white dark:bg-gray-800 rounded-2xl shadow-lg overflow-hidden"
    >
      <div className="p-6">
        <motion.h2 
          variants={fadeInUp}
          className="text-xl font-bold text-gray-900 dark:text-white mb-6 flex items-center gap-2"
        >
          <HiDocumentText className="w-6 h-6 text-blue-500" />
          Resume
        </motion.h2>
        
        {/* Tab Navigation */}
        <motion.div 
          variants={fadeInUp}
          className="flex justify-center mb-6"
        >
          <div className="flex flex-wrap justify-center space-x-2 bg-gray-50 dark:bg-gray-700/50 rounded-lg p-1 shadow">
            {availableTabs.map(tab => (
              <motion.button
                key={tab.id}
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                onClick={() => setActiveTab(tab.id)}
                className={`px-6 py-2 rounded-md transition-colors ${
                  activeTab === tab.id
                    ? 'bg-blue-500 text-white'
                    : 'text-gray-600 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700'
                }`}
              >
                {tab.label}
              </motion.button>
            ))}
          </div>
        </motion.div>

        {/* Tab Content */}
        <AnimatePresence mode="wait">
          <motion.div 
            key={activeTab}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            transition={{ duration: 0.2 }}
            className="mt-6"
          >
            <Suspense fallback={<div className="animate-pulse">Loading...</div>}>
              {activeTab === 'work' && resumeData.workHistory?.length > 0 && (
                <WorkHistory 
                  workHistory={resumeData.workHistory} 
                  setWorkHistory={() => {}}
                  readOnly={true}
                />
              )}
              {activeTab === 'education' && resumeData.education?.length > 0 && (
                <Education 
                  education={resumeData.education} 
                  setEducation={() => {}}
                  readOnly={true}
                />
              )}
              {activeTab === 'projects' && resumeData.projects?.length > 0 && (
                <Projects 
                  projects={resumeData.projects} 
                  setProjects={() => {}}
                  readOnly={true}
                />
              )}
              {activeTab === 'skills' && resumeData.skills?.length > 0 && (
                <Skills 
                  skills={resumeData.skills} 
                  setSkills={() => {}}
                  readOnly={true}
                />
              )}
            </Suspense>
          </motion.div>
        </AnimatePresence>
      </div>
    </motion.div>
  );
};

export default ProfileResume;
