import React, { createContext, useContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth, db, signIn, signUp, logOut } from '../services/firebase';
import { doc, setDoc, getDoc, getDocs, collection, query, where, serverTimestamp } from 'firebase/firestore';
import LoadingSpinner from '../components/LoadingSpinner';

const AuthContext = createContext(null);

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};

export function AuthProvider({ children }) {
  const [user, setUser] = useState(null);
  const [error, setError] = useState(null);
  const [initialLoading, setInitialLoading] = useState(true);
  const navigate = useNavigate();

  // Function to fetch user profile from Firestore
  const fetchUserProfile = async (uid) => {
    try {
      const userDoc = await getDoc(doc(db, 'users', uid));
      if (userDoc.exists()) {
        return userDoc.data();
      }
      return null;
    } catch (error) {
      console.error('Error fetching user profile:', error);
      return null;
    }
  };

  // Listen for auth state changes
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (firebaseUser) => {
      if (firebaseUser) {
        // Get additional user data from Firestore
        const userData = await fetchUserProfile(firebaseUser.uid);
        setUser({
          uid: firebaseUser.uid,
          email: firebaseUser.email,
          ...userData
        });
      } else {
        setUser(null);
      }
      setInitialLoading(false);
    });

    return () => unsubscribe();
  }, []);

  const login = async (email, password) => {
    try {
      setError(null);
      const firebaseUser = await signIn(email, password);
      const userData = await fetchUserProfile(firebaseUser.uid);
      setUser({
        uid: firebaseUser.uid,
        email: firebaseUser.email,
        ...userData
      });
      return { success: true };
    } catch (error) {
      const message = error.code === 'auth/user-not-found' ? 'Invalid email or password' :
                     error.code === 'auth/wrong-password' ? 'Invalid email or password' :
                     'Failed to login';
      setError(message);
      return {
        success: false,
        error: message
      };
    }
  };

  const generateUsername = (firstName, lastName) => {
    // Remove special characters and spaces, convert to lowercase
    const cleanFirst = firstName.toLowerCase().replace(/[^a-z0-9]/g, '');
    const cleanLast = lastName.toLowerCase().replace(/[^a-z0-9]/g, '');
    return `${cleanFirst}-${cleanLast}`;
  };

  const findUniqueUsername = async (baseUsername) => {
    let username = baseUsername;
    let counter = 1;
    
    while (true) {
      // Check if username exists
      const usersRef = collection(db, 'users');
      const q = query(usersRef, where('username', '==', username));
      const querySnapshot = await getDocs(q);
      
      if (querySnapshot.empty) {
        return username; // Username is available
      }
      
      // Username exists, try next number
      username = `${baseUsername}-${counter}`;
      counter++;
    }
  };

  const register = async (userData) => {
    try {
      setError(null);
      const { email, password, firstName, lastName } = userData;
      
      // Generate username from first and last name
      const baseUsername = generateUsername(firstName, lastName);
      const uniqueUsername = await findUniqueUsername(baseUsername);
      
      // Create Firebase auth user
      const firebaseUser = await signUp(email, password);
      
      // Create user document in Firestore
      const userDocRef = doc(db, 'users', firebaseUser.uid);
      await setDoc(userDocRef, {
        name: `${firstName} ${lastName}`.trim(),
        username: uniqueUsername,
        email,
        about: "",
        github_url: "",
        linkedin_url: "",
        twitter_url: "",
        website_url: "",
        location: "",
        moduleOrder: ["resume", "payment", "extraLinks", "socialStats"],
        modules: {
          resume: false,
          payment: false,
          extraLinks: false,
          socialStats: false
        },
        socialLinks: [],
        theme: {
          gradientDirection: "180deg",
          gradientPosition: "center",
          gradientType: "linear",
          pattern: "",
          primaryColor: "#2563eb",
          secondaryColor: "#3b82f6",
          textColor: "#ffffff"
        },
        createdAt: serverTimestamp(),
        updatedAt: serverTimestamp()
      }, { merge: true }); // Use merge to prevent overwriting if document exists

      // Get the created user data
      const createdUserData = await fetchUserProfile(firebaseUser.uid);
      setUser({
        uid: firebaseUser.uid,
        email: firebaseUser.email,
        ...createdUserData
      });

      return { success: true };
    } catch (error) {
      const message = error.code === 'auth/email-already-in-use' ? 'Email already in use' :
                     error.code === 'auth/invalid-email' ? 'Invalid email address' :
                     'Failed to register';
      setError(message);
      return {
        success: false,
        error: message
      };
    }
  };

  const logout = async (redirectToAbout = true) => {
    try {
      await logOut();
      setUser(null);
      setError(null);
      if (redirectToAbout) {
        navigate('/about');
      }
    } catch (error) {
      console.error('Logout error:', error);
    }
  };

  const value = {
    user,
    error,
    loading: initialLoading,
    login,
    register,
    logout
  };

  if (initialLoading) {
    return <LoadingSpinner />;
  }

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  );
}
