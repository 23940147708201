import React, { useState } from 'react';
import { FaPlus, FaTrash, FaSave, FaEdit } from 'react-icons/fa';
import Modal from '../common/Modal';

const EducationForm = ({ edu, onSave, onCancel }) => {
  const [formData, setFormData] = useState(edu);

  const handleChange = (field, value) => {
    setFormData(prev => ({
      ...prev,
      [field]: value
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSave(formData);
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-4">
      <div>
        <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
          School/University
        </label>
        <input
          type="text"
          value={formData.school}
          onChange={(e) => handleChange('school', e.target.value)}
          className="w-full px-3 py-2 border rounded-lg dark:bg-gray-700 dark:border-gray-600 focus:ring-2 focus:ring-blue-500 focus:border-transparent"
          placeholder="Enter school name"
          required
        />
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
          Degree
        </label>
        <input
          type="text"
          value={formData.degree}
          onChange={(e) => handleChange('degree', e.target.value)}
          className="w-full px-3 py-2 border rounded-lg dark:bg-gray-700 dark:border-gray-600 focus:ring-2 focus:ring-blue-500 focus:border-transparent"
          placeholder="e.g., Bachelor's, Master's"
          required
        />
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
          Field of Study
        </label>
        <input
          type="text"
          value={formData.fieldOfStudy}
          onChange={(e) => handleChange('fieldOfStudy', e.target.value)}
          className="w-full px-3 py-2 border rounded-lg dark:bg-gray-700 dark:border-gray-600 focus:ring-2 focus:ring-blue-500 focus:border-transparent"
          placeholder="e.g., Computer Science"
        />
      </div>

      <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
        <div>
          <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
            Start Date
          </label>
          <input
            type="month"
            value={formData.startDate}
            onChange={(e) => handleChange('startDate', e.target.value)}
            className="w-full px-3 py-2 border rounded-lg dark:bg-gray-700 dark:border-gray-600 focus:ring-2 focus:ring-blue-500 focus:border-transparent"
            required
          />
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
            End Date
          </label>
          <input
            type="month"
            value={formData.endDate}
            onChange={(e) => handleChange('endDate', e.target.value)}
            className="w-full px-3 py-2 border rounded-lg dark:bg-gray-700 dark:border-gray-600 focus:ring-2 focus:ring-blue-500 focus:border-transparent"
          />
        </div>
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
          Description
        </label>
        <textarea
          value={formData.description}
          onChange={(e) => handleChange('description', e.target.value)}
          className="w-full px-3 py-2 border rounded-lg dark:bg-gray-700 dark:border-gray-600 focus:ring-2 focus:ring-blue-500 focus:border-transparent resize-y"
          rows="4"
          placeholder="Add relevant achievements, activities, or courses"
        />
      </div>

      <div className="flex flex-col sm:flex-row justify-end space-y-2 sm:space-y-0 sm:space-x-2 pt-2">
        <button
          type="button"
          onClick={onCancel}
          className="w-full sm:w-auto px-4 py-2 bg-gray-500 text-white rounded-lg hover:bg-gray-600 transition-colors duration-200"
        >
          Cancel
        </button>
        <button
          type="submit"
          className="w-full sm:w-auto px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition-colors duration-200"
        >
          Save
        </button>
      </div>
    </form>
  );
};

const EducationEntry = ({ edu, onEdit, onRemove, readOnly }) => {
  return (
    <div className="bg-white dark:bg-gray-800 p-4 sm:p-5 rounded-lg shadow-sm">
      <div className="relative">
        <div className="pr-12">
          <h3 className="text-lg font-semibold text-gray-800 dark:text-white break-words">
            {edu.school}
          </h3>
          <p className="text-gray-600 dark:text-gray-300 mt-1 break-words">
            {edu.degree}{edu.fieldOfStudy ? ` in ${edu.fieldOfStudy}` : ''}
          </p>
          <p className="text-sm text-gray-500 dark:text-gray-400 mt-1">
            {edu.startDate} - {edu.endDate || 'Present'}
          </p>
          {edu.description && (
            <p className="mt-3 text-gray-700 dark:text-gray-300 text-sm sm:text-base leading-relaxed break-words">
              {edu.description}
            </p>
          )}
        </div>

        {!readOnly && (
          <div className="absolute top-0 right-0 flex flex-col space-y-2">
            <button
              onClick={() => onEdit(edu)}
              className="p-2 text-blue-500 hover:text-blue-600 transition-colors duration-200"
              aria-label="Edit education"
            >
              <FaEdit />
            </button>
            <button
              onClick={() => onRemove(edu.id)}
              className="p-2 text-red-500 hover:text-red-600 transition-colors duration-200"
              aria-label="Delete education"
            >
              <FaTrash />
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

const Education = ({ education, setEducation, readOnly = false }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentEducation, setCurrentEducation] = useState(null);

  const handleAddEducation = () => {
    const newEducation = {
      id: Date.now(),
      school: '',
      degree: '',
      fieldOfStudy: '',
      startDate: '',
      endDate: '',
      description: ''
    };
    setCurrentEducation(newEducation);
    setIsModalOpen(true);
  };

  const handleEditEducation = (edu) => {
    setCurrentEducation(edu);
    setIsModalOpen(true);
  };

  const handleRemoveEducation = (id) => {
    setEducation(education.filter(edu => edu.id !== id));
  };

  const handleSaveEducation = (savedEducation) => {
    if (education.find(edu => edu.id === savedEducation.id)) {
      setEducation(education.map(edu => 
        edu.id === savedEducation.id ? savedEducation : edu
      ));
    } else {
      setEducation([...education, savedEducation]);
    }
    setIsModalOpen(false);
  };

  return (
    <div className="max-w-4xl mx-auto p-4">
      <div className="flex flex-col sm:flex-row justify-between items-center mb-6 space-y-4 sm:space-y-0">
        <h2 className="text-xl sm:text-2xl font-bold text-gray-800 dark:text-white">
          Education
        </h2>
        {!readOnly && (
          <button
            onClick={handleAddEducation}
            className="w-full sm:w-auto px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 
                     transition-colors duration-200 flex items-center justify-center"
          >
            <FaPlus className="mr-2" />
            <span>Add Education</span>
          </button>
        )}
      </div>

      <div className="space-y-4">
        {education.length === 0 ? (
          <div className="text-center py-8 text-gray-500 dark:text-gray-400">
            {readOnly ? 'No education entries available.' : 'No education entries added yet. Click "Add Education" to get started.'}
          </div>
        ) : (
          education.map((edu) => (
            <EducationEntry
              key={edu.id}
              edu={edu}
              onEdit={handleEditEducation}
              onRemove={handleRemoveEducation}
              readOnly={readOnly}
            />
          ))
        )}
      </div>

      <Modal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        title={currentEducation?.id ? 'Edit Education' : 'Add Education'}
      >
        {currentEducation && (
          <EducationForm
            edu={currentEducation}
            onSave={handleSaveEducation}
            onCancel={() => setIsModalOpen(false)}
          />
        )}
      </Modal>
    </div>
  );
};

export default Education;
