import React from 'react';
import {
  HiOutlineCog,
  HiOutlineTrash,
  HiOutlineExclamation,
  HiOutlineCheck
} from 'react-icons/hi';

const PropertiesPanel = ({ selectedComponent, onUpdate, onDelete }) => {
  if (!selectedComponent) {
    return (
      <div className="w-64 h-full bg-gray-50 dark:bg-gray-900/50 border-l border-gray-200 dark:border-gray-700 p-4">
        <div className="flex items-center justify-center h-full text-gray-500 dark:text-gray-400">
          <p className="text-sm">Select a component to edit its properties</p>
        </div>
      </div>
    );
  }

  const renderSettings = () => {
    switch (selectedComponent.type) {
      case 'text':
        return (
          <>
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                Font Size
              </label>
              <select
                value={selectedComponent.settings.fontSize}
                onChange={(e) => handleSettingChange('fontSize', e.target.value)}
                className="w-full px-3 py-2 rounded-lg border border-gray-200 dark:border-gray-600 bg-white dark:bg-gray-700 text-gray-800 dark:text-gray-200"
              >
                <option value="12px">Small</option>
                <option value="16px">Medium</option>
                <option value="20px">Large</option>
                <option value="24px">Extra Large</option>
              </select>
            </div>
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                Font Weight
              </label>
              <select
                value={selectedComponent.settings.fontWeight}
                onChange={(e) => handleSettingChange('fontWeight', e.target.value)}
                className="w-full px-3 py-2 rounded-lg border border-gray-200 dark:border-gray-600 bg-white dark:bg-gray-700 text-gray-800 dark:text-gray-200"
              >
                <option value="normal">Normal</option>
                <option value="medium">Medium</option>
                <option value="bold">Bold</option>
              </select>
            </div>
          </>
        );

      case 'input':
        return (
          <>
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                Placeholder Text
              </label>
              <input
                type="text"
                value={selectedComponent.settings.placeholder}
                onChange={(e) => handleSettingChange('placeholder', e.target.value)}
                className="w-full px-3 py-2 rounded-lg border border-gray-200 dark:border-gray-600 bg-white dark:bg-gray-700 text-gray-800 dark:text-gray-200"
              />
            </div>
          </>
        );

      case 'dropdown':
      case 'checkbox':
        return (
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
              Options
            </label>
            <div className="space-y-2">
              {selectedComponent.settings.options.map((option, index) => (
                <div key={index} className="flex items-center space-x-2">
                  <input
                    type="text"
                    value={option}
                    onChange={(e) => handleOptionChange(index, e.target.value)}
                    className="flex-1 px-3 py-2 rounded-lg border border-gray-200 dark:border-gray-600 bg-white dark:bg-gray-700 text-gray-800 dark:text-gray-200"
                  />
                  <button
                    onClick={() => handleRemoveOption(index)}
                    className="p-2 text-red-500 hover:text-red-700"
                  >
                    <HiOutlineTrash className="w-4 h-4" />
                  </button>
                </div>
              ))}
              <button
                onClick={handleAddOption}
                className="w-full px-3 py-2 text-sm text-blue-600 hover:text-blue-700 dark:text-blue-400 dark:hover:text-blue-300"
              >
                + Add Option
              </button>
            </div>
          </div>
        );

      default:
        return null;
    }
  };

  const handleSettingChange = (key, value) => {
    onUpdate({
      ...selectedComponent,
      settings: {
        ...selectedComponent.settings,
        [key]: value
      }
    });
  };

  const handleOptionChange = (index, value) => {
    const newOptions = [...selectedComponent.settings.options];
    newOptions[index] = value;
    handleSettingChange('options', newOptions);
  };

  const handleAddOption = () => {
    const newOptions = [...selectedComponent.settings.options, ''];
    handleSettingChange('options', newOptions);
  };

  const handleRemoveOption = (index) => {
    const newOptions = selectedComponent.settings.options.filter((_, i) => i !== index);
    handleSettingChange('options', newOptions);
  };

  return (
    <div className="w-64 h-full bg-gray-50 dark:bg-gray-900/50 border-l border-gray-200 dark:border-gray-700 p-4">
      <div className="mb-6">
        <h3 className="text-lg font-semibold text-gray-800 dark:text-white mb-2">
          Component Properties
        </h3>
        <p className="text-sm text-gray-600 dark:text-gray-400">
          {selectedComponent.type.charAt(0).toUpperCase() + selectedComponent.type.slice(1)}
        </p>
      </div>

      <div className="space-y-4">
        {renderSettings()}

        <div className="pt-4 border-t border-gray-200 dark:border-gray-700">
          <label className="flex items-center space-x-2 text-sm text-gray-700 dark:text-gray-300 mb-4">
            <input
              type="checkbox"
              checked={selectedComponent.settings.required}
              onChange={(e) => handleSettingChange('required', e.target.checked)}
              className="rounded border-gray-300 dark:border-gray-600 text-blue-600 focus:ring-blue-500"
            />
            <span>Required field</span>
          </label>

          <button
            onClick={() => onDelete(selectedComponent.id)}
            className="w-full px-4 py-2 text-sm text-red-600 hover:text-red-700 dark:text-red-400 dark:hover:text-red-300 rounded-lg border border-red-200 dark:border-red-800 hover:bg-red-50 dark:hover:bg-red-900/20 transition-colors duration-200"
          >
            Delete Component
          </button>
        </div>
      </div>
    </div>
  );
};

export default PropertiesPanel;
