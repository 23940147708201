import React from 'react';
import { motion } from 'framer-motion';
import { HiUserGroup } from 'react-icons/hi';
import { useNavigate } from 'react-router-dom';

const ProfileTeams = ({ teams }) => {
  const navigate = useNavigate();

  if (!teams?.length) return null;

  const fadeInUp = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 }
  };

  const staggerContainer = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1
      }
    }
  };

  const cardHover = {
    scale: 1.02,
    transition: { duration: 0.2 }
  };

  return (
    <motion.div 
      variants={fadeInUp}
      className="mt-8 bg-white dark:bg-gray-800 rounded-2xl shadow-lg p-6"
    >
      <motion.h2 
        variants={fadeInUp}
        className="text-xl font-bold text-gray-900 dark:text-white mb-6 flex items-center gap-2"
      >
        <HiUserGroup className="w-6 h-6 text-blue-500" />
        Teams
      </motion.h2>
      <motion.div 
        variants={staggerContainer}
        className="grid gap-4"
      >
        {teams.map(team => (
          <motion.div 
            key={team.id}
            variants={fadeInUp}
            whileHover={cardHover}
            onClick={() => navigate(`/teams/${team.id}`)}
            className="group cursor-pointer"
          >
            <div className="flex items-center gap-4 p-4 bg-gray-50 dark:bg-gray-700/50 rounded-xl transition-all duration-200 hover:bg-gray-100 dark:hover:bg-gray-700 hover:shadow-md">
              <div className="w-12 h-12 rounded-lg bg-blue-100 dark:bg-blue-900/30 flex items-center justify-center">
                <HiUserGroup className="w-6 h-6 text-blue-500" />
              </div>
              <div>
                <h3 className="font-medium text-gray-900 dark:text-white group-hover:text-blue-500 transition-colors duration-200">
                  {team.name}
                </h3>
                <p className="text-sm text-gray-500 dark:text-gray-400">
                  {team.members} members
                </p>
              </div>
            </div>
          </motion.div>
        ))}
      </motion.div>
    </motion.div>
  );
};

export default ProfileTeams;
