import React from 'react';

const LoadingSpinner = () => {
  return (
    <div className="fixed inset-0 flex items-center justify-center bg-white dark:bg-gray-900 bg-opacity-95 dark:bg-opacity-95">
      <div className="flex flex-col items-center">
        <img 
          src="/logo512.png" 
          alt="App Logo" 
          className="w-16 h-16 mb-4 animate-pulse"
        />
       
       
      </div>
    </div>
  );
};

export default LoadingSpinner;
