import React, { useState, useEffect } from 'react';
import { FiX } from 'react-icons/fi';
import ModuleToggle from './ModuleToggle';
import { useModules } from '../../contexts/ModulesContext';

const ModuleCustomization = ({ isOpen, onClose, availableModules, onOpenSettings }) => {
  const { moduleOrder, updateModuleOrder } = useModules();
  const [currentOrder, setCurrentOrder] = useState([]);
  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    if (!moduleOrder || !availableModules) return;

    // Filter moduleOrder to only include available modules
    const validOrder = moduleOrder.filter(moduleName => {
      const isValid = availableModules.some(module => module.name === moduleName);
      return isValid;
    });
    setCurrentOrder(validOrder);
    setIsReady(true);
  }, [moduleOrder, availableModules]);

  if (!isOpen || !isReady) return null;

  return (
    <div className="fixed inset-0 z-50 overflow-hidden">
      <div 
        className="fixed inset-0 bg-black bg-opacity-50 transition-opacity"
        onClick={onClose}
      />

      <div className="fixed inset-y-0 right-0 max-w-xl w-full bg-white dark:bg-gray-800 shadow-xl flex flex-col">
        <div className="flex items-center justify-between p-6 border-b border-gray-200 dark:border-gray-700">
          <h2 className="text-xl font-semibold text-gray-900 dark:text-white">
            Customize Your Profile
          </h2>
          <button
            onClick={onClose}
            className="p-2 rounded-lg hover:bg-gray-100 dark:hover:bg-gray-700 transition-colors"
            aria-label="Close customization"
          >
            <FiX className="w-5 h-5 text-gray-500 dark:text-gray-400" />
          </button>
        </div>

        <div className="flex-1 overflow-y-auto p-6">
          <p className="text-sm md:text-base text-gray-600 dark:text-gray-400 mb-6">
            Enable modules and configure their settings to personalize your profile experience.
          </p>
          
          {isReady && currentOrder.length > 0 ? (
            <div className="space-y-4">
              {currentOrder.map((moduleName, index) => {
                const module = availableModules.find(m => m.name === moduleName);
                if (!module) {
                  console.warn(`Module ${moduleName} not found in available modules`);
                  return null;
                }

                return (
                  <div key={`module-${moduleName}`}>
                    <ModuleToggle
                      moduleName={moduleName}
                      title={module.title}
                      description={module.description}
                      icon={module.icon}
                      onOpenSettings={() => onOpenSettings(moduleName)}
                    />
                  </div>
                );
              })}
            </div>
          ) : (
            <div className="p-4 text-gray-500">Loading modules...</div>
          )}
        </div>

        <div className="p-6 border-t border-gray-200 dark:border-gray-700">
          <button
            onClick={onClose}
            className="w-full px-4 py-2 text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 rounded-lg transition-colors"
          >
            Done
          </button>
        </div>
      </div>
    </div>
  );
};

export default ModuleCustomization;
