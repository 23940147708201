// SoundCloud profile fetching using RapidAPI
const RAPID_API_KEY = '94dfe38c00msh2095861bdba1943p179b8ejsna0dc6af4cd8b';
const RAPID_API_HOST = 'soundcloud-scraper.p.rapidapi.com';

// Get user stats
export const scrapeSoundcloudStats = async (handle) => {
  if (!handle) {
    throw new Error('SoundCloud profile URL is required');
  }

  try {
    // Ensure the handle is a full SoundCloud URL
    const profileUrl = handle.startsWith('https://soundcloud.com/') 
      ? handle 
      : `https://soundcloud.com/${handle}`;

    const response = await fetch(
      `https://${RAPID_API_HOST}/v1/user/profile?user=${encodeURIComponent(profileUrl)}`,
      {
        headers: {
          'X-RapidAPI-Key': RAPID_API_KEY,
          'X-RapidAPI-Host': RAPID_API_HOST,
          'Accept': 'application/json'
        }
      }
    );

    if (!response.ok) {
      const error = await response.json();
      throw new Error(error.message || 'Failed to fetch SoundCloud stats');
    }

    const data = await response.json();
    
    if (!data) {
      throw new Error('Profile not found');
    }

    return {
      username: data.permalink || handle,
      platformUsername: data.username || null,
      followers: data.followerCount || 0,
      following: data.followingCount || 0,
      posts: data.trackCount || 0,
      profilePic: data.avatar_url || null,
      description: data.description || null,
      tracks: data.trackCount || 0,
      likes: data.likesCount || 0
    };
  } catch (error) {
    console.error('Error fetching SoundCloud stats:', error);
    throw error;
  }
};
