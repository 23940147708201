import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { 
  FaLink, 
  FaYoutube, 
  FaMedium, 
  FaGithub, 
  FaTwitter, 
  FaLinkedin,
  FaInstagram,
  FaFacebook,
  FaTwitch,
  FaReddit,
  FaSpotify,
  FaSoundcloud,
  FaBehance,
  FaDribbble,
  FaWordpress,
  FaStackOverflow,
  FaCodepen,
  FaNpm,
  FaDiscord,
  FaTelegram,
  FaVimeo,
  FaPinterest,
  FaGitlab,
  FaBitbucket,
  FaTrello,
  FaJira,
  FaSlack,
  FaMusic,
  FaVideo,
  FaNewspaper,
  FaCode,
  FaImage,
  FaPodcast
} from 'react-icons/fa';
import { 
  SiNotion, 
  SiDevdotto, 
  SiHashnode,
  SiNetlify,
  SiVercel,
  SiFirebase
} from 'react-icons/si';
import Modal from '../common/Modal';
import { getLinkMetadata } from '../../services/linkMetadata';

const ProfileExtraLinks = ({ extraLinks, userModules }) => {
  const [linksWithMetadata, setLinksWithMetadata] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isVideoModalOpen, setIsVideoModalOpen] = useState(false);
  const [currentVideoId, setCurrentVideoId] = useState(null);

  const shimmer = {
    hidden: {
      backgroundPosition: "200% 0",
    },
    visible: {
      backgroundPosition: "-200% 0",
      transition: {
        duration: 8,
        ease: "linear",
        repeat: Infinity
      }
    }
  };

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1,
        delayChildren: 0.2
      }
    }
  };

  const cardVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { 
      opacity: 1, 
      y: 0,
      transition: {
        duration: 0.5,
        ease: "easeOut"
      }
    },
    hover: {
      scale: 1.02,
      transition: {
        type: "spring",
        stiffness: 400,
        damping: 17
      }
    }
  };

  const imageVariants = {
    hover: {
      scale: 1.1,
      transition: {
        duration: 0.4,
        ease: "easeOut"
      }
    }
  };

  const typeIconVariants = {
    initial: { 
      rotate: -5,
      scale: 0.9,
      opacity: 0.4
    },
    hover: { 
      rotate: [-5, -7, -5],
      scale: 0.95,
      opacity: 0.6,
      transition: {
        rotate: {
          duration: 0.4,
          ease: "easeInOut"
        },
        scale: {
          duration: 0.2
        }
      }
    }
  };

  useEffect(() => {
    if (!extraLinks?.length) {
      setLoading(false);
      return;
    }

    const processLinks = async () => {
      try {
        const processedLinks = await Promise.all(
          extraLinks.map(async (link) => ({
            ...link,
            metadata: await getLinkMetadata(link)
          }))
        );

        setLinksWithMetadata(processedLinks);
      } catch (error) {
        console.error('Error processing links:', error);
      } finally {
        setLoading(false);
      }
    };

    processLinks();
  }, [extraLinks]);

  const handleLinkClick = (e, link) => {
    if (link.metadata.type === 'youtube' && link.metadata.videoId) {
      e.preventDefault();
      setCurrentVideoId(link.metadata.videoId);
      setIsVideoModalOpen(true);
    }
  };

  const getTypeIcon = (type, metadata) => {
    const baseClass = "w-2.5 h-2.5 sm:w-3.5 sm:h-3.5 text-white/80";
    let icon = null;
    let bgColor = "from-blue-400/40 to-blue-500/40";
    
    if (type?.toLowerCase().includes('youtube') || metadata?.type === 'video') {
      icon = <FaVideo className={baseClass} />;
      bgColor = "from-red-400/40 to-red-500/40";
    } else if (type?.toLowerCase().includes('spotify') || type?.toLowerCase().includes('soundcloud') || metadata?.type === 'music') {
      icon = <FaMusic className={baseClass} />;
      bgColor = "from-green-400/40 to-green-500/40";
    } else if (type?.toLowerCase().includes('medium') || metadata?.type === 'article') {
      icon = <FaNewspaper className={baseClass} />;
      bgColor = "from-yellow-400/40 to-yellow-500/40";
    } else if (type?.toLowerCase().includes('github') || metadata?.type === 'code') {
      icon = <FaCode className={baseClass} />;
      bgColor = "from-purple-400/40 to-purple-500/40";
    } else if (type?.toLowerCase().includes('behance') || type?.toLowerCase().includes('dribbble') || metadata?.type === 'image') {
      icon = <FaImage className={baseClass} />;
      bgColor = "from-pink-400/40 to-pink-500/40";
    } else if (metadata?.type === 'podcast') {
      icon = <FaPodcast className={baseClass} />;
      bgColor = "from-indigo-400/40 to-indigo-500/40";
    } else {
      icon = <FaLink className={baseClass} />;
    }

    return (
      <motion.div
        variants={typeIconVariants}
        initial="initial"
        whileHover="hover"
        className={`absolute top-1 sm:top-2 right-1 sm:right-2 w-4 h-4 sm:w-5 sm:h-5 rounded-md sm:rounded-lg bg-gradient-to-br ${bgColor} flex items-center justify-center shadow-[0_1px_2px_rgba(0,0,0,0.05)] backdrop-blur-[1px] transform rotate-[-5deg] z-10`}
      >
        {icon}
      </motion.div>
    );
  };

  const getIcon = (type) => {
    const iconClass = "w-5 h-5 flex-shrink-0";
    
    switch (type?.toLowerCase()) {
      case 'youtube': return <FaYoutube className={`${iconClass} text-red-500`} />;
      case 'github': return <FaGithub className={`${iconClass} text-gray-900 dark:text-gray-100`} />;
      case 'medium': return <FaMedium className={`${iconClass} text-gray-800 dark:text-gray-200`} />;
      case 'twitter': return <FaTwitter className={`${iconClass} text-blue-400`} />;
      case 'linkedin': return <FaLinkedin className={`${iconClass} text-blue-600`} />;
      case 'instagram': return <FaInstagram className={`${iconClass} text-pink-500`} />;
      case 'facebook': return <FaFacebook className={`${iconClass} text-blue-600`} />;
      case 'twitch': return <FaTwitch className={`${iconClass} text-purple-500`} />;
      case 'reddit': return <FaReddit className={`${iconClass} text-orange-500`} />;
      case 'pinterest': return <FaPinterest className={`${iconClass} text-red-500`} />;
      case 'stackoverflow': return <FaStackOverflow className={`${iconClass} text-orange-500`} />;
      case 'codepen': return <FaCodepen className={`${iconClass} text-gray-800 dark:text-gray-200`} />;
      case 'npm': return <FaNpm className={`${iconClass} text-red-500`} />;
      case 'devto': return <SiDevdotto className={`${iconClass} text-gray-800 dark:text-gray-200`} />;
      case 'hashnode': return <SiHashnode className={`${iconClass} text-blue-500`} />;
      case 'gitlab': return <FaGitlab className={`${iconClass} text-orange-500`} />;
      case 'bitbucket': return <FaBitbucket className={`${iconClass} text-blue-500`} />;
      case 'netlify': return <SiNetlify className={`${iconClass} text-blue-500`} />;
      case 'vercel': return <SiVercel className={`${iconClass} text-black dark:text-white`} />;
      case 'firebase': return <SiFirebase className={`${iconClass} text-orange-500`} />;
      case 'behance': return <FaBehance className={`${iconClass} text-blue-600`} />;
      case 'dribbble': return <FaDribbble className={`${iconClass} text-pink-500`} />;
      case 'vimeo': return <FaVimeo className={`${iconClass} text-blue-600`} />;
      case 'spotify': return <FaSpotify className={`${iconClass} text-green-500`} />;
      case 'soundcloud': return <FaSoundcloud className={`${iconClass} text-orange-500`} />;
      case 'notion': return <SiNotion className={`${iconClass} text-gray-800 dark:text-gray-200`} />;
      case 'wordpress': return <FaWordpress className={`${iconClass} text-blue-500`} />;
      case 'slack': return <FaSlack className={`${iconClass} text-purple-500`} />;
      case 'discord': return <FaDiscord className={`${iconClass} text-indigo-500`} />;
      case 'telegram': return <FaTelegram className={`${iconClass} text-blue-500`} />;
      case 'trello': return <FaTrello className={`${iconClass} text-blue-500`} />;
      case 'jira': return <FaJira className={`${iconClass} text-blue-500`} />;
      default: return <FaLink className={`${iconClass} text-gray-500 dark:text-gray-400`} />;
    }
  };

  if (!userModules?.extraLinks || !extraLinks?.length) {
    return null;
  }

  return (
    <motion.div
      initial="hidden"
      animate="visible"
      variants={containerVariants}
      className="mt-8 rounded-2xl shadow-lg overflow-hidden relative"
    >
      <motion.div
        variants={shimmer}
        initial="hidden"
        animate="visible"
        className="absolute inset-0 bg-gradient-to-r from-transparent via-blue-300/10 to-transparent dark:via-blue-400/5"
        style={{
          backgroundSize: "200% 100%"
        }}
      />
      
      <div className="relative p-6 bg-gradient-to-br from-blue-500/10 via-indigo-400/5 to-purple-300/10 dark:from-blue-900/20 dark:via-indigo-800/15 dark:to-purple-700/20 backdrop-blur-sm">
        <motion.div 
          className="flex items-center gap-2 mb-6"
          whileHover={{ scale: 1.02 }}
        >
          <motion.div
            whileHover={{ rotate: [0, -10, 10, -10, 0], transition: { duration: 0.5 } }}
          >
            <FaLink className="w-6 h-6 text-blue-500 dark:text-blue-400" />
          </motion.div>
          <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
            Featured Links
          </h3>
        </motion.div>

        <AnimatePresence>
          {loading ? (
            <motion.div 
              className="space-y-4"
              variants={containerVariants}
            >
              {[1, 2].map((i) => (
                <motion.div
                  key={i}
                  variants={cardVariants}
                  className="animate-pulse flex h-32 bg-white/50 dark:bg-gray-800/50 rounded-xl backdrop-blur-sm"
                >
                  <div className="w-48 bg-gray-200 dark:bg-gray-700 rounded-l-xl"></div>
                  <div className="flex-1 p-4">
                    <div className="h-4 bg-gray-200 dark:bg-gray-700 rounded w-3/4 mb-2"></div>
                    <div className="h-3 bg-gray-200 dark:bg-gray-700 rounded w-1/2"></div>
                  </div>
                </motion.div>
              ))}
            </motion.div>
          ) : (
            <motion.div 
              className="space-y-4"
              variants={containerVariants}
            >
              {linksWithMetadata.map((link, index) => (
                <motion.a
                  key={index}
                  href={link.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={(e) => handleLinkClick(e, link)}
                  className="group flex overflow-hidden rounded-xl bg-white/50 dark:bg-gray-800/50 backdrop-blur-sm border border-blue-200/20 dark:border-blue-700/20 hover:border-blue-500 dark:hover:border-blue-400 transition-all duration-300 h-32"
                  variants={cardVariants}
                  whileHover="hover"
                >
                  {getTypeIcon(link.metadata.type, link.metadata)}
                  
                  {link.metadata.image && (
                    <motion.div 
                      className="relative w-48 overflow-hidden bg-gray-100 dark:bg-gray-800"
                      variants={imageVariants}
                    >
                      <img
                        src={link.metadata.image}
                        alt={link.metadata.title}
                        className="w-full h-full object-cover"
                        onError={(e) => {
                          if (link.metadata.fallbackImage && e.target.src !== link.metadata.fallbackImage) {
                            e.target.src = link.metadata.fallbackImage;
                          } else {
                            e.target.style.display = 'none';
                          }
                        }}
                      />
                      <div className="absolute inset-0 bg-gradient-to-r from-black/60 to-transparent opacity-0 group-hover:opacity-100 transition-opacity duration-300"></div>
                    </motion.div>
                  )}
                  
                  <div className="flex-1 p-4 flex flex-col justify-center transform transition-transform duration-300 group-hover:translate-x-2">
                    <div className="flex items-center gap-2 mb-2">
                      <motion.div whileHover={{ scale: 1.1, rotate: 360 }} transition={{ duration: 0.3 }}>
                        {getIcon(link.metadata.type)}
                      </motion.div>
                      <span className="text-sm text-gray-500 dark:text-gray-400 truncate">
                        {new URL(link.url).hostname.replace('www.', '')}
                      </span>
                    </div>
                    
                    <h4 className="font-medium text-gray-900 dark:text-white mb-2 line-clamp-2 group-hover:text-blue-500 dark:group-hover:text-blue-400 transition-colors">
                      {link.metadata.title}
                    </h4>
                    
                    {link.metadata.description && (
                      <p className="text-sm text-gray-600 dark:text-gray-300 line-clamp-1">
                        {link.metadata.description}
                      </p>
                    )}

                    {link.metadata.stats && (
                      <motion.div 
                        className="flex items-center gap-4 mt-2 text-sm text-gray-500 dark:text-gray-400"
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{ delay: 0.2 }}
                      >
                        {link.metadata.stats.stars && (
                          <span>⭐ {link.metadata.stats.stars.toLocaleString()}</span>
                        )}
                        {link.metadata.stats.forks && (
                          <span>🔱 {link.metadata.stats.forks.toLocaleString()}</span>
                        )}
                        {link.metadata.stats.language && (
                          <span>📝 {link.metadata.stats.language}</span>
                        )}
                      </motion.div>
                    )}
                  </div>
                </motion.a>
              ))}
            </motion.div>
          )}
        </AnimatePresence>
      </div>

      <Modal
        isOpen={isVideoModalOpen}
        onClose={() => {
          setIsVideoModalOpen(false);
          setCurrentVideoId(null);
        }}
        title="YouTube Video"
      >
        <div className="relative pb-[56.25%] h-0">
          <iframe
            src={`https://www.youtube.com/embed/${currentVideoId}`}
            title="YouTube video player"
            className="absolute top-0 left-0 w-full h-full"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        </div>
      </Modal>
    </motion.div>
  );
};

export default ProfileExtraLinks;
