import React, { useState } from 'react';
import { useModules } from '../../contexts/ModulesContext';
import { FiSettings } from 'react-icons/fi';

const ModuleToggle = ({ moduleName, title, description, icon: Icon, onOpenSettings }) => {
  const { modules, toggleModule, isLoading } = useModules();
  const [isHovered, setIsHovered] = useState(false);

  return (
    <div 
      className="bg-white dark:bg-gray-800 rounded-xl shadow-lg p-4 sm:p-6 relative group"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <div className="flex flex-col sm:flex-row sm:items-center sm:justify-between gap-4">
        {/* Content Section */}
        <div className="flex items-start sm:items-center gap-3 flex-1">
          {Icon && (
            <div className="flex-shrink-0 mt-1 sm:mt-0">
              <Icon className="w-6 h-6 text-blue-500" />
            </div>
          )}
          <div className="flex-1 min-w-0">
            <h3 className="text-lg font-semibold text-gray-900 dark:text-white truncate">
              {title}
            </h3>
            <p className="text-sm text-gray-600 dark:text-gray-400 mt-0.5">
              {description}
            </p>
          </div>
        </div>

        {/* Controls Section */}
        <div className="flex items-center gap-4">
          {/* Settings Button - Only visible when module is enabled */}
          {modules[moduleName] && (
            <button
              onClick={onOpenSettings}
              className={`
                p-2 rounded-lg transition-all duration-200
                ${isHovered ? 'opacity-100' : 'opacity-0 sm:opacity-60'}
                hover:bg-gray-100 dark:hover:bg-gray-700
                focus:outline-none focus:ring-2 focus:ring-blue-500
                group-hover:opacity-100
              `}
              aria-label={`Configure ${title}`}
            >
              <FiSettings className="w-5 h-5 text-gray-600 dark:text-gray-400" />
            </button>
          )}

          {/* Toggle Switch */}
          <label className="relative inline-flex items-center cursor-pointer">
            <input
              type="checkbox"
              className="sr-only peer"
              checked={modules[moduleName] || false}
              onChange={() => toggleModule(moduleName)}
              disabled={isLoading}
            />
            <div className={`
              w-14 h-8 bg-gray-200 rounded-full peer
              dark:bg-gray-700 
              peer-focus:ring-4 
              peer-focus:ring-blue-300 
              dark:peer-focus:ring-blue-800 
              peer-checked:after:translate-x-full 
              peer-checked:after:border-white 
              after:content-[''] 
              after:absolute 
              after:top-1 
              after:left-1 
              after:bg-white 
              after:border-gray-300 
              after:border 
              after:rounded-full 
              after:h-6 
              after:w-6 
              after:transition-all
              dark:border-gray-600 
              peer-checked:bg-blue-600
              ${isLoading ? 'opacity-50 cursor-not-allowed' : ''}
            `}>
              <span className="absolute inset-0 -m-4" />
            </div>
            
            {/* Loading indicator */}
            {isLoading && (
              <div className="absolute inset-0 flex items-center justify-center">
                <div className="w-4 h-4 border-2 border-blue-500 border-t-transparent rounded-full animate-spin"></div>
              </div>
            )}
          </label>
        </div>
      </div>

      {/* Mobile touch feedback */}
      <style jsx>{`
        @media (hover: none) {
          .peer:active + div::after {
            transform: scale(0.9);
          }
          .peer:checked:active + div::after {
            transform: translateX(100%) scale(0.9);
          }
        }
      `}</style>
    </div>
  );
};

export default ModuleToggle;
