import React, { createContext, useContext } from 'react';

const AuthModalContext = createContext();

export const AuthModalProvider = ({ children, onAuthClick }) => {
  return (
    <AuthModalContext.Provider value={onAuthClick}>
      {children}
    </AuthModalContext.Provider>
  );
};

export const useAuthModal = () => {
  const context = useContext(AuthModalContext);
  if (context === undefined) {
    throw new Error('useAuthModal must be used within an AuthModalProvider');
  }
  return context;
};
