import React from 'react';
import { motion } from 'framer-motion';

const ProfileSkeleton = () => {
  return (
    <div className="max-w-2xl mx-auto px-4 py-8">
      <div className="bg-white dark:bg-gray-800 rounded-2xl shadow-lg overflow-hidden">
        {/* Banner Skeleton */}
        <div className="h-32 bg-gray-200 dark:bg-gray-700 animate-pulse"></div>
        
        <div className="px-6 pb-6">
          {/* Avatar Skeleton */}
          <div className="relative -mt-16 mb-4">
            <div className="w-32 h-32 mx-auto rounded-full bg-gray-300 dark:bg-gray-600 animate-pulse"></div>
          </div>

          {/* Name & Username Skeleton */}
          <div className="text-center mb-6">
            <div className="h-8 w-48 mx-auto bg-gray-300 dark:bg-gray-600 rounded animate-pulse mb-2"></div>
            <div className="h-4 w-32 mx-auto bg-gray-200 dark:bg-gray-700 rounded animate-pulse"></div>
          </div>

          {/* About Skeleton */}
          <div className="mt-6 mb-6">
            <div className="h-4 w-24 bg-gray-300 dark:bg-gray-600 rounded animate-pulse mb-4"></div>
            <div className="space-y-2">
              <div className="h-4 w-full bg-gray-200 dark:bg-gray-700 rounded animate-pulse"></div>
              <div className="h-4 w-5/6 bg-gray-200 dark:bg-gray-700 rounded animate-pulse"></div>
              <div className="h-4 w-4/6 bg-gray-200 dark:bg-gray-700 rounded animate-pulse"></div>
            </div>
          </div>

          {/* Social Links Skeleton */}
          <div className="flex flex-wrap justify-center gap-3">
            {[1, 2, 3, 4].map((_, index) => (
              <div 
                key={index}
                className="w-10 h-10 rounded-lg bg-gray-200 dark:bg-gray-700 animate-pulse"
              ></div>
            ))}
          </div>
        </div>
      </div>

      {/* Resume Section Skeleton */}
      <div className="mt-8 bg-white dark:bg-gray-800 rounded-2xl shadow-lg overflow-hidden">
        <div className="p-6">
          <div className="h-6 w-32 bg-gray-300 dark:bg-gray-600 rounded animate-pulse mb-6"></div>
          
          {/* Tab Navigation Skeleton */}
          <div className="flex justify-center mb-6">
            <div className="flex space-x-2">
              {[1, 2, 3].map((_, index) => (
                <div 
                  key={index}
                  className="w-24 h-8 bg-gray-200 dark:bg-gray-700 rounded animate-pulse"
                ></div>
              ))}
            </div>
          </div>

          {/* Content Skeleton */}
          <div className="space-y-4">
            {[1, 2, 3].map((_, index) => (
              <div key={index} className="flex gap-4">
                <div className="w-1/4 h-16 bg-gray-200 dark:bg-gray-700 rounded animate-pulse"></div>
                <div className="w-3/4 space-y-2">
                  <div className="h-4 w-3/4 bg-gray-200 dark:bg-gray-700 rounded animate-pulse"></div>
                  <div className="h-4 w-1/2 bg-gray-200 dark:bg-gray-700 rounded animate-pulse"></div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileSkeleton;
