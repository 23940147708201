import React, { createContext, useContext, useState, useEffect } from 'react';
import { useAuth } from './AuthContext';
import { userService } from '../services/firestore';

const ModulesContext = createContext();

export const useModules = () => {
  const context = useContext(ModulesContext);
  if (!context) {
    throw new Error('useModules must be used within a ModulesProvider');
  }
  return context;
};

export const ModulesProvider = ({ children }) => {
  const { user } = useAuth();
  const [modules, setModules] = useState({
    resume: false,
    payment: false,
    extraLinks: false,
    socialStats: false
  });
  const [moduleOrder, setModuleOrder] = useState([
    'resume',
    'payment',
    'extraLinks',
    'socialStats'
  ]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchModules = async () => {
      if (!user) {
        setModules({
          resume: false,
          payment: false,
          extraLinks: false,
          socialStats: false
        });
        setIsLoading(false);
        return;
      }

      try {
        // Get user profile from Firestore which includes modules configuration
        const userData = await userService.getUserById(user.uid);
        if (userData && userData.modules) {
          // Ensure socialStats module exists in the modules configuration
          const updatedModules = {
            ...userData.modules,
            socialStats: userData.modules.socialStats ?? false
          };
          // Update Firestore if socialStats was missing
          if (!('socialStats' in userData.modules)) {
            await userService.updateModules(user.uid, updatedModules);
          }
          setModules(updatedModules);
          // Set module order if it exists, otherwise use default order
          if (userData.moduleOrder) {
            setModuleOrder(userData.moduleOrder);
          } else {
            const defaultOrder = [
              'resume',
              'payment',
              'extraLinks',
              'socialStats'
            ];
            await userService.updateModuleOrder(user.uid, defaultOrder);
            setModuleOrder(defaultOrder);
          }
        } else {
          // If no modules configuration exists, set defaults
          const defaultModules = {
            resume: false,
            payment: false,
            extraLinks: false,
            socialStats: false
          };
          
          const defaultOrder = [
            'resume',
            'payment',
            'extraLinks',
            'socialStats'
          ];
          
          // Save default modules and order to Firestore
          await userService.updateModules(user.uid, defaultModules);
          await userService.updateModuleOrder(user.uid, defaultOrder);
          setModules(defaultModules);
          setModuleOrder(defaultOrder);
        }
      } catch (error) {
        console.error('Error fetching modules:', error);
        setModules({
          resume: false,
          payment: false,
          extraLinks: false,
          socialStats: false
        });
      } finally {
        setIsLoading(false);
      }
    };

    fetchModules();
  }, [user]);

  const toggleModule = async (moduleName) => {
    if (!user) return;

    try {
      const updatedModules = {
        ...modules,
        [moduleName]: !modules[moduleName]
      };

      // Update modules in Firestore
      await userService.updateModules(user.uid, updatedModules);
      setModules(updatedModules);
    } catch (error) {
      console.error(`Error toggling ${moduleName} module:`, error);
    }
  };

  const updateModuleOrder = async (newOrder) => {
    if (!user) return;

    try {
      // Validate that all modules in the new order are valid module names
      const validModules = ['resume', 'payment', 'extraLinks', 'socialStats'];
      const validatedOrder = newOrder.filter(moduleName => validModules.includes(moduleName));
      
      // Update module order in Firestore
      await userService.updateModuleOrder(user.uid, validatedOrder);
      setModuleOrder(validatedOrder);
    } catch (error) {
      console.error('Error updating module order:', error);
    }
  };

  return (
    <ModulesContext.Provider
      value={{
        modules,
        moduleOrder,
        toggleModule,
        updateModuleOrder,
        isLoading
      }}
    >
      {children}
    </ModulesContext.Provider>
  );
};
