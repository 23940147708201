import React from 'react';
import { FaClipboard } from 'react-icons/fa';

const Toast = ({ message, isVisible }) => {
  if (!isVisible) return null;

  return (
    <div className="fixed bottom-4 right-4 bg-gray-800 text-white px-4 py-2 rounded-lg shadow-lg flex items-center space-x-2">
      <FaClipboard className="text-sm" />
      <span>{message}</span>
    </div>
  );
};

export default Toast;
