import { db } from './firebase';
import {
  collection,
  doc,
  getDoc,
  getDocs,
  setDoc,
  updateDoc,
  deleteDoc,
  query,
  where,
  arrayUnion,
  arrayRemove,
  serverTimestamp
} from 'firebase/firestore';

// User Profile Operations
export const userService = {
  async searchUsers(searchTerm) {
    try {
      const usersRef = collection(db, 'users');
      // Convert search term to lowercase for case-insensitive search
      const lowercaseSearch = searchTerm.toLowerCase();
      
      // Get all users (we'll filter in memory since Firestore doesn't support OR conditions well)
      const querySnapshot = await getDocs(usersRef);
      
      // Filter users that match the search term in name or username
      const results = querySnapshot.docs
        .map(doc => ({
          id: doc.id,
          ...doc.data()
        }))
        .filter(user =>
          user.name?.toLowerCase().includes(lowercaseSearch) ||
          user.username?.toLowerCase().includes(lowercaseSearch)
        )
        .slice(0, 10); // Limit to 10 results
      
      return results;
    } catch (error) {
      console.error('Error searching users:', error);
      throw error;
    }
  },

  async getUserProfile(username) {
    try {
      // Query users collection to find user by username
      const usersRef = collection(db, 'users');
      const q = query(usersRef, where('username', '==', username));
      const querySnapshot = await getDocs(q);
      
      if (querySnapshot.empty) {
        return null;
      }

      const userData = querySnapshot.docs[0].data();
      return {
        ...userData,
        id: querySnapshot.docs[0].id
      };
    } catch (error) {
      console.error('Error getting user profile:', error);
      throw error;
    }
  },

  async getUserById(uid) {
    try {
      const userRef = doc(db, 'users', uid);
      const userDoc = await getDoc(userRef);
      
      if (!userDoc.exists()) {
        return null;
      }

      return {
        ...userDoc.data(),
        id: userDoc.id
      };
    } catch (error) {
      console.error('Error getting user by ID:', error);
      throw error;
    }
  },

  async updateUserProfile(uid, profileData) {
    try {
      const userRef = doc(db, 'users', uid);
      await updateDoc(userRef, {
        ...profileData,
        updatedAt: serverTimestamp()
      });
      return true;
    } catch (error) {
      console.error('Error updating user profile:', error);
      throw error;
    }
  },

  async updateModules(uid, modules) {
    try {
      const userRef = doc(db, 'users', uid);
      await updateDoc(userRef, {
        modules,
        updatedAt: serverTimestamp()
      });
      return modules;
    } catch (error) {
      console.error('Error updating modules:', error);
      throw error;
    }
  },

  async updateModuleOrder(uid, moduleOrder) {
    try {
      const userRef = doc(db, 'users', uid);
      await updateDoc(userRef, {
        moduleOrder,
        updatedAt: serverTimestamp()
      });
      return moduleOrder;
    } catch (error) {
      console.error('Error updating module order:', error);
      throw error;
    }
  }
};

// Social Links Operations
export const socialLinksService = {
  async getUserSocialLinks(username) {
    try {
      const user = await userService.getUserProfile(username);
      if (!user) return null;
      
      const socialLinksRef = doc(db, 'socialLinks', user.id);
      const socialLinksDoc = await getDoc(socialLinksRef);
      
      return socialLinksDoc.exists() ? socialLinksDoc.data() : {};
    } catch (error) {
      console.error('Error getting social links:', error);
      throw error;
    }
  },

  async updateUserSocialLinks(uid, socialLinks) {
    try {
      const socialLinksRef = doc(db, 'socialLinks', uid);
      await setDoc(socialLinksRef, {
        links: socialLinks,
        updatedAt: serverTimestamp()
      });
      return socialLinks;
    } catch (error) {
      console.error('Error updating social links:', error);
      throw error;
    }
  }
};

// Payment Links Operations
export const paymentLinksService = {
  async getPaymentLinks(username) {
    try {
      const user = await userService.getUserProfile(username);
      if (!user) return [];
      
      const paymentLinksRef = doc(db, 'paymentLinks', user.id);
      const paymentLinksDoc = await getDoc(paymentLinksRef);
      
      return paymentLinksDoc.exists() ? paymentLinksDoc.data().links : [];
    } catch (error) {
      console.error('Error getting payment links:', error);
      return [];
    }
  },

  async updatePaymentLinks(uid, paymentLinks) {
    try {
      const links = Array.isArray(paymentLinks) ? paymentLinks : paymentLinks.paymentLinks;
      const validLinks = links
        .filter(link => link.platform && link.username)
        .map(link => ({
          platform: link.platform.toLowerCase().replace(/\s+/g, ''),
          username: link.username
        }));

      const paymentLinksRef = doc(db, 'paymentLinks', uid);
      await setDoc(paymentLinksRef, {
        links: validLinks,
        updatedAt: serverTimestamp()
      });
      return validLinks;
    } catch (error) {
      console.error('Error updating payment links:', error);
      throw error;
    }
  }
};

// Extra Links Operations
export const extraLinksService = {
  async getExtraLinks(username) {
    try {
      const user = await userService.getUserProfile(username);
      if (!user) return [];
      
      const extraLinksRef = doc(db, 'extraLinks', user.id);
      const extraLinksDoc = await getDoc(extraLinksRef);
      
      return extraLinksDoc.exists() ? extraLinksDoc.data().links : [];
    } catch (error) {
      console.error('Error getting extra links:', error);
      return [];
    }
  },

  async updateExtraLinks(uid, links) {
    try {
      const extraLinksRef = doc(db, 'extraLinks', uid);
      await setDoc(extraLinksRef, {
        links: links.map(link => ({
          title: link.title.trim(),
          url: link.url.trim()
        })),
        updatedAt: serverTimestamp()
      });
      return links;
    } catch (error) {
      console.error('Error updating extra links:', error);
      throw error;
    }
  }
};

// Connections Operations
export const connectionsService = {
  async sendRequest(fromUid, toUid) {
    try {
      const connectionRef = doc(db, 'connections', `${fromUid}_${toUid}`);
      await setDoc(connectionRef, {
        from: fromUid,
        to: toUid,
        status: 'pending',
        createdAt: serverTimestamp()
      });
      return true;
    } catch (error) {
      console.error('Error sending connection request:', error);
      throw error;
    }
  },

  async acceptRequest(connectionId) {
    try {
      const connectionRef = doc(db, 'connections', connectionId);
      await updateDoc(connectionRef, {
        status: 'accepted',
        acceptedAt: serverTimestamp()
      });
      return true;
    } catch (error) {
      console.error('Error accepting connection request:', error);
      throw error;
    }
  },

  async declineRequest(connectionId) {
    try {
      const connectionRef = doc(db, 'connections', connectionId);
      await updateDoc(connectionRef, {
        status: 'declined',
        declinedAt: serverTimestamp()
      });
      return true;
    } catch (error) {
      console.error('Error declining connection request:', error);
      throw error;
    }
  },

  async removeConnection(connectionId) {
    try {
      const connectionRef = doc(db, 'connections', connectionId);
      await deleteDoc(connectionRef);
      return true;
    } catch (error) {
      console.error('Error removing connection:', error);
      throw error;
    }
  },

  async getConnections(uid) {
    try {
      const connectionsRef = collection(db, 'connections');
      // Get connections where user is either 'from' or 'to'
      const [fromSnapshot, toSnapshot] = await Promise.all([
        getDocs(query(
          connectionsRef,
          where('status', '==', 'accepted'),
          where('from', '==', uid)
        )),
        getDocs(query(
          connectionsRef,
          where('status', '==', 'accepted'),
          where('to', '==', uid)
        ))
      ]);
      
      // Combine both sets of connections
      const connections = [
        ...fromSnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        })),
        ...toSnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }))
      ];
      
      return connections;
    } catch (error) {
      console.error('Error getting connections:', error);
      throw error;
    }
  },

  async getPendingRequests(uid) {
    try {
      const connectionsRef = collection(db, 'connections');
      const [inboundSnapshot, outboundSnapshot] = await Promise.all([
        getDocs(query(
          connectionsRef,
          where('status', '==', 'pending'),
          where('to', '==', uid)
        )),
        getDocs(query(
          connectionsRef,
          where('status', '==', 'pending'),
          where('from', '==', uid)
        ))
      ]);

      return {
        inbound: inboundSnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        })),
        outbound: outboundSnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }))
      };
    } catch (error) {
      console.error('Error getting pending requests:', error);
      throw error;
    }
  }
};

// Resume Operations
export const resumeService = {
  async getResume(username) {
    try {
      const user = await userService.getUserProfile(username);
      if (!user) return null;
      
      const resumeRef = doc(db, 'resumes', user.id);
      const resumeDoc = await getDoc(resumeRef);
      
      return resumeDoc.exists() ? resumeDoc.data() : null;
    } catch (error) {
      console.error('Error getting resume:', error);
      throw error;
    }
  },

  async saveResume(uid, resumeData) {
    try {
      const resumeRef = doc(db, 'resumes', uid);
      await setDoc(resumeRef, {
        ...resumeData,
        updatedAt: serverTimestamp()
      });
      return true;
    } catch (error) {
      console.error('Error saving resume:', error);
      throw error;
    }
  }
};

// Social Stats Operations
export const socialStatsService = {
  async getStats(uid, platform) {
    try {
      if (!uid || !platform) {
        throw new Error('User ID and platform are required');
      }
      
      const statsRef = doc(db, 'socialStats', `${uid}_${platform}`);
      const statsDoc = await getDoc(statsRef);
      
      return statsDoc.exists() ? statsDoc.data() : null;
    } catch (error) {
      console.error('Error getting social stats:', error);
      throw error;
    }
  },

  async saveStats(uid, platform, statsData) {
    try {
      if (!uid || !platform || !statsData) {
        throw new Error('User ID, platform, and stats data are required');
      }

      // Ensure required fields are present
      if (!statsData.platform_username) {
        throw new Error('Platform username is required in stats data');
      }

      // Clean and validate data before saving
      const cleanData = {
        platform: platform,
        platform_username: statsData.platform_username,
        followers: typeof statsData.followers === 'number' ? statsData.followers : 0,
        following: typeof statsData.following === 'number' ? statsData.following : 0,
        posts: typeof statsData.posts === 'number' ? statsData.posts : 0,
        views: typeof statsData.views === 'number' ? statsData.views : 0,
        ratio: typeof statsData.ratio === 'number' ? statsData.ratio : 0,
        fullName: typeof statsData.fullName === 'string' ? statsData.fullName : '',
        bio: typeof statsData.bio === 'string' ? statsData.bio : '',
        isVerified: Boolean(statsData.isVerified),
        updatedAt: serverTimestamp(),
        lastScrapedAt: serverTimestamp()
      };

      const statsRef = doc(db, 'socialStats', `${uid}_${platform}`);
      await setDoc(statsRef, cleanData);
      
      return true;
    } catch (error) {
      console.error('Error saving social stats:', error);
      throw error;
    }
  }
};