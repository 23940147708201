import React, { useState, useEffect } from 'react';
import { useModules } from '../../contexts/ModulesContext';
import { HiDocumentText } from 'react-icons/hi';
import { FaMoneyBillWave, FaChevronLeft, FaChevronRight, FaLink, FaHashtag } from 'react-icons/fa';
import { FiSettings } from 'react-icons/fi';
import ModuleCustomization from './ModuleCustomization';
import ModuleSettings from './ModuleSettings';
import Resume from '../resume/Resume';
import PaymentLinks from '../payment/PaymentLinks';
import ExtraLinks from '../links/ExtraLinks';
import SocialStatsModule from '../social-stats/SocialStatsModule';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import styles from './ProfileModules.module.css';

const AVAILABLE_MODULES = [
  {
    name: 'resume',
    title: 'Resume Builder',
    description: 'Enable this module to showcase your professional experience, skills, and achievements',
    icon: HiDocumentText,
    component: Resume,
    settingsComponent: () => (
      <div className="space-y-4">
        <div className="space-y-2">
          <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
            Resume Visibility
          </label>
          <select className="w-full px-3 py-2 rounded-lg border border-gray-300 dark:border-gray-600 bg-white dark:bg-gray-700 text-gray-900 dark:text-white">
            <option value="public">Public - Anyone can view</option>
            <option value="private">Private - Only you can view</option>
            <option value="connections">Connections Only</option>
          </select>
        </div>
        <div className="space-y-2">
          <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
            Default Resume Template
          </label>
          <select className="w-full px-3 py-2 rounded-lg border border-gray-300 dark:border-gray-600 bg-white dark:bg-gray-700 text-gray-900 dark:text-white">
            <option value="modern">Modern</option>
            <option value="classic">Classic</option>
            <option value="minimal">Minimal</option>
          </select>
        </div>
      </div>
    )
  },
  {
    name: 'payment',
    title: 'Payment Links',
    description: 'Enable this module to add payment links like PayPal, Cash App, and Venmo to your profile',
    icon: FaMoneyBillWave,
    component: PaymentLinks,
    settingsComponent: () => (
      <div className="space-y-4">
        <div className="space-y-2">
          <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
            Payment Links Display
          </label>
          <select className="w-full px-3 py-2 rounded-lg border border-gray-300 dark:border-gray-600 bg-white dark:bg-gray-700 text-gray-900 dark:text-white">
            <option value="grid">Grid View</option>
            <option value="list">List View</option>
          </select>
        </div>
        <div className="space-y-2">
          <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
            Default Currency
          </label>
          <select className="w-full px-3 py-2 rounded-lg border border-gray-300 dark:border-gray-600 bg-white dark:bg-gray-700 text-gray-900 dark:text-white">
            <option value="USD">USD ($)</option>
            <option value="EUR">EUR (€)</option>
            <option value="GBP">GBP (£)</option>
          </select>
        </div>
      </div>
    )
  },
  {
    name: 'extraLinks',
    title: 'Extra Links',
    description: 'Enable this module to add custom links to your profile, such as YouTube videos, SoundCloud tracks, blog posts, and more',
    icon: FaLink,
    component: ExtraLinks,
    settingsComponent: () => (
      <div className="space-y-4">
        <div className="space-y-2">
          <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
            Links Display
          </label>
          <select className="w-full px-3 py-2 rounded-lg border border-gray-300 dark:border-gray-600 bg-white dark:bg-gray-700 text-gray-900 dark:text-white">
            <option value="grid">Grid View</option>
            <option value="list">List View</option>
          </select>
        </div>
        <div className="space-y-2">
          <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
            Link Preview
          </label>
          <select className="w-full px-3 py-2 rounded-lg border border-gray-300 dark:border-gray-600 bg-white dark:bg-gray-700 text-gray-900 dark:text-white">
            <option value="enabled">Show link previews when available</option>
            <option value="disabled">Disable link previews</option>
          </select>
        </div>
      </div>
    )
  },
  {
    name: 'socialStats',
    title: 'Social Media Stats',
    description: 'Enable this module to display your social media follower counts and engagement metrics',
    icon: FaHashtag,
    component: SocialStatsModule,
    settingsComponent: () => (
      <div className="space-y-4">
        <div className="space-y-2">
          <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
            Auto Refresh
          </label>
          <select className="w-full px-3 py-2 rounded-lg border border-gray-300 dark:border-gray-600 bg-white dark:bg-gray-700 text-gray-900 dark:text-white">
            <option value="never">Never</option>
            <option value="hourly">Every Hour</option>
            <option value="daily">Daily</option>
          </select>
        </div>
        <div className="space-y-2">
          <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
            Stats Visibility
          </label>
          <select className="w-full px-3 py-2 rounded-lg border border-gray-300 dark:border-gray-600 bg-white dark:bg-gray-700 text-gray-900 dark:text-white">
            <option value="public">Public - Anyone can view</option>
            <option value="private">Private - Only you can view</option>
            <option value="connections">Connections Only</option>
          </select>
        </div>
      </div>
    )
  }
];

const CustomPrevArrow = ({ onClick }) => (
  <button
    onClick={onClick}
    className="nav-arrow nav-arrow-prev absolute left-4 top-1/2 -translate-y-1/2 z-10 bg-white dark:bg-gray-700 rounded-full p-2 shadow-lg hover:bg-gray-100 dark:hover:bg-gray-600 transition-colors md:w-10 md:h-10 w-8 h-8 flex items-center justify-center"
    aria-label="Previous module"
  >
    <FaChevronLeft className="md:w-5 md:h-5 w-4 h-4 text-gray-600 dark:text-gray-300" />
  </button>
);

const CustomNextArrow = ({ onClick }) => (
  <button
    onClick={onClick}
    className="nav-arrow nav-arrow-next absolute right-4 top-1/2 -translate-y-1/2 z-10 bg-white dark:bg-gray-700 rounded-full p-2 shadow-lg hover:bg-gray-100 dark:hover:bg-gray-600 transition-colors md:w-10 md:h-10 w-8 h-8 flex items-center justify-center"
    aria-label="Next module"
  >
    <FaChevronRight className="md:w-5 md:h-5 w-4 h-4 text-gray-600 dark:text-gray-300" />
  </button>
);

const ProfileModules = () => {
  const { modules, moduleOrder } = useModules();
  const [activeSettings, setActiveSettings] = useState(null);
  const [isCustomizing, setIsCustomizing] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    
    checkMobile();
    window.addEventListener('resize', checkMobile);
    return () => window.removeEventListener('resize', checkMobile);
  }, []);

  const handleOpenSettings = (moduleName) => {
    setActiveSettings(moduleName);
  };

  const handleCloseSettings = () => {
    setActiveSettings(null);
  };

  // Filter to only include valid and enabled modules
  const activeModules = moduleOrder
    .filter(moduleName =>
      modules[moduleName] && // module is enabled
      AVAILABLE_MODULES.some(module => module.name === moduleName) // module exists in AVAILABLE_MODULES
    )
    .map(moduleName => AVAILABLE_MODULES.find(module => module.name === moduleName))
    .filter(Boolean); // remove any undefined entries

  const sliderSettings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />,
    customPaging: (i) => (
      <div className="w-2.5 h-2.5 md:w-3 md:h-3 mx-1 rounded-full bg-gray-300 dark:bg-gray-600 hover:bg-gray-400 dark:hover:bg-gray-500 transition-colors">
        <span className="sr-only">Slide {i + 1}</span>
      </div>
    ),
    touchThreshold: 10,
    swipeToSlide: true
  };

  return (
    <div className="space-y-6 md:space-y-8">
      {/* Header with Settings Button */}
      <div className="flex items-center justify-between px-1">
        <h2 className="text-lg md:text-xl font-semibold text-gray-900 dark:text-white">
          Active Modules
        </h2>
        <div className="flex items-center gap-3">
          <div className="text-xs md:text-sm text-gray-500 dark:text-gray-400">
            {activeModules.length} {activeModules.length === 1 ? 'module' : 'modules'} enabled
          </div>
          <button
            onClick={() => setIsCustomizing(true)}
            className="p-2 rounded-lg hover:bg-gray-100 dark:hover:bg-gray-700 transition-colors"
            aria-label="Customize modules"
          >
            <FiSettings className="w-5 h-5 text-gray-600 dark:text-gray-400" />
          </button>
        </div>
      </div>

      {/* Active Modules Section */}
      {activeModules.length > 0 ? (
        <div className="relative pb-8 md:pb-10">
          <Slider {...sliderSettings}>
            {activeModules.map(module => (
              <div key={`${module.name}-content`} className="outline-none h-full">
                <div className={`bg-white dark:bg-gray-800 rounded-xl shadow-lg overflow-hidden transition-all duration-300 ease-in-out mx-1 md:mx-2 ${styles.moduleContainer}`}>
                  <div className="p-4 md:p-6 h-full flex flex-col">
                    <div className="flex items-center justify-between mb-3 md:mb-4">
                      <h3 className="text-base md:text-lg font-semibold text-gray-900 dark:text-white">
                        {module.title}
                      </h3>
                      <button
                        onClick={() => handleOpenSettings(module.name)}
                        className="text-xs md:text-sm text-blue-600 dark:text-blue-400 hover:text-blue-700 dark:hover:text-blue-300 px-3 py-1.5 rounded-full bg-blue-50 dark:bg-blue-900/30"
                      >
                        Settings
                      </button>
                    </div>
                    <div className={styles.moduleContent}>
                      <module.component />
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      ) : (
        <div className="bg-white dark:bg-gray-800 rounded-xl shadow-lg p-8 text-center">
          <p className="text-gray-600 dark:text-gray-400">
            No modules are currently enabled. Click the settings icon above to customize your profile.
          </p>
        </div>
      )}

      {/* Module Settings Modal */}
      {AVAILABLE_MODULES.map(module => (
        <ModuleSettings
          key={`${module.name}-settings`}
          isOpen={activeSettings === module.name}
          onClose={handleCloseSettings}
          module={module}
        >
          {module.settingsComponent && <module.settingsComponent />}
        </ModuleSettings>
      ))}

      {/* Module Customization Drawer */}
      <ModuleCustomization
        isOpen={isCustomizing}
        onClose={() => setIsCustomizing(false)}
        availableModules={AVAILABLE_MODULES}
        onOpenSettings={handleOpenSettings}
      />
    </div>
  );
};

export default ProfileModules;
