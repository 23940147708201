import React, { useState } from 'react';
import { FaEdit, FaCheck, FaTimes, FaCopy, FaGlobe, FaPalette } from 'react-icons/fa';
import { HiQrcode, HiX } from 'react-icons/hi';
import { QRCodeSVG } from 'qrcode.react';

const getPatternBackground = (pattern) => {
  switch (pattern) {
    case 'dots':
      return `radial-gradient(circle at 1px 1px, rgba(255,255,255,0.15) 1px, transparent 0)`;
    case 'lines':
      return `linear-gradient(0deg, transparent 9px, rgba(255,255,255,0.15) 10px)`;
    case 'grid':
      return `linear-gradient(0deg, transparent 9px, rgba(255,255,255,0.15) 10px),
              linear-gradient(90deg, transparent 9px, rgba(255,255,255,0.15) 10px)`;
    case 'diagonal':
      return `linear-gradient(45deg, rgba(255,255,255,0.15) 25%, transparent 25%),
              linear-gradient(-45deg, rgba(255,255,255,0.15) 25%, transparent 25%)`;
    default:
      return 'none';
  }
};

const getPatternSize = (pattern) => {
  switch (pattern) {
    case 'dots':
      return '20px 20px';
    case 'lines':
      return '20px 20px';
    case 'grid':
      return '20px 20px';
    case 'diagonal':
      return '20px 20px';
    default:
      return 'auto';
  }
};

const ProfileHeader = ({
  profile,
  isEditing,
  onEdit,
  onSave,
  onCancel,
  onProfileChange,
  onCopyLink,
  onEditTheme
}) => {
  const [showQRCode, setShowQRCode] = useState(false);

  const getInitials = (name) => {
    if (!name) return '';
    return name
      .split(' ')
      .map(word => word[0])
      .join('')
      .toUpperCase()
      .slice(0, 2);
  };

  const validateUsername = (username) => {
    const usernameRegex = /^[a-zA-Z0-9_-]+$/;
    return usernameRegex.test(username);
  };

  const handleUsernameChange = (value) => {
    if (value === '' || validateUsername(value)) {
      onProfileChange('username', value);
    }
  };

  const openPublicProfile = () => {
    if (profile.username) {
      window.open(`/profile/${profile.username}`, '_blank');
    }
  };

  const getProfileUrl = () => {
    return `${window.location.origin}/profile/${profile.username}`;
  };

  return (
    <>
      <div
        className="h-32 relative"
        style={{
          background: profile.theme ? (
            profile.theme.gradientType === 'linear' ?
              `linear-gradient(${profile.theme.gradientDirection}, ${profile.theme.primaryColor}, ${profile.theme.secondaryColor})` :
              `radial-gradient(circle at ${profile.theme.gradientPosition}, ${profile.theme.primaryColor}, ${profile.theme.secondaryColor})`
          ) : 'linear-gradient(90deg, #4776E6, #8E54E9)',
          backgroundImage: profile.theme?.pattern !== 'none' ? `${getPatternBackground(profile.theme?.pattern)}, ${
            profile.theme.gradientType === 'linear' ?
              `linear-gradient(${profile.theme.gradientDirection}, ${profile.theme.primaryColor}, ${profile.theme.secondaryColor})` :
              `radial-gradient(circle at ${profile.theme.gradientPosition}, ${profile.theme.primaryColor}, ${profile.theme.secondaryColor})`
          }` : undefined,
          backgroundSize: profile.theme?.pattern !== 'none' ? `${getPatternSize(profile.theme?.pattern)}, 100% 100%` : '100% 100%'
        }}
      >
        {!isEditing && (
          <>
            <div className="absolute top-4 left-4 flex space-x-2">
              <button
                onClick={onCopyLink}
                className="p-2 bg-white/20 rounded-full hover:bg-white/30 transition-colors"
                title="Copy Profile Link"
              >
                <FaCopy className="text-white text-xl" />
              </button>
              <button
                onClick={openPublicProfile}
                className="p-2 bg-white/20 rounded-full hover:bg-white/30 transition-colors"
                title="Go to Public Profile"
              >
                <FaGlobe className="text-white text-xl" />
              </button>
              <button
                onClick={() => setShowQRCode(true)}
                className="p-2 bg-white/20 rounded-full hover:bg-white/30 transition-colors"
                title="Show QR Code"
              >
                <HiQrcode className="text-white text-xl" />
              </button>
            </div>
            <div className="absolute top-4 right-4 flex space-x-2">
              <button
                onClick={onEditTheme}
                className="p-2 bg-white/20 rounded-full hover:bg-white/30 transition-colors"
                title="Edit Theme"
              >
                <FaPalette className="text-white text-xl" />
              </button>
              <button
                onClick={onEdit}
                className="p-2 bg-white/20 rounded-full hover:bg-white/30 transition-colors"
              >
                <FaEdit className="text-white text-xl" />
              </button>
            </div>
          </>
        )}
        {isEditing && (
          <div className="absolute top-4 right-4 flex space-x-2">
            <button
              onClick={onSave}
              className="p-2 bg-green-500 rounded-full hover:bg-green-600 transition-colors"
            >
              <FaCheck className="text-white text-xl" />
            </button>
            <button
              onClick={onCancel}
              className="p-2 bg-red-500 rounded-full hover:bg-red-600 transition-colors"
            >
              <FaTimes className="text-white text-xl" />
            </button>
          </div>
        )}
      </div>
      
      <div className="relative px-6 pb-6">
        <div className="flex flex-col items-center">
          <div className="w-24 h-24 sm:w-32 sm:h-32 -mt-12 sm:-mt-16 rounded-full border-4 border-white dark:border-gray-800 overflow-hidden bg-white">
            <div
              className="w-full h-full flex items-center justify-center"
              style={{
                background: profile.theme ? (
                  profile.theme.gradientType === 'linear' ?
                    `linear-gradient(${profile.theme.gradientDirection}, ${profile.theme.primaryColor}, ${profile.theme.secondaryColor})` :
                    `radial-gradient(circle at ${profile.theme.gradientPosition}, ${profile.theme.primaryColor}, ${profile.theme.secondaryColor})`
                ) : 'linear-gradient(90deg, #4776E6, #8E54E9)',
                backgroundImage: profile.theme?.pattern !== 'none' ? `${getPatternBackground(profile.theme?.pattern)}, ${
                  profile.theme.gradientType === 'linear' ?
                    `linear-gradient(${profile.theme.gradientDirection}, ${profile.theme.primaryColor}, ${profile.theme.secondaryColor})` :
                    `radial-gradient(circle at ${profile.theme.gradientPosition}, ${profile.theme.primaryColor}, ${profile.theme.secondaryColor})`
                }` : undefined,
                backgroundSize: profile.theme?.pattern !== 'none' ? `${getPatternSize(profile.theme?.pattern)}, 100% 100%` : '100% 100%'
              }}
            >
              <span
                className="text-3xl sm:text-4xl font-bold"
                style={{ color: profile.theme?.textColor || '#FFFFFF' }}
              >
                {getInitials(profile.name)}
              </span>
            </div>
          </div>
          <div className="mt-4 text-center flex-grow max-w-md mx-auto w-full">
            {isEditing ? (
              <div className="space-y-2">
                <input
                  type="text"
                  value={profile.name}
                  onChange={(e) => onProfileChange('name', e.target.value)}
                  className="w-full px-3 py-2 border rounded-lg dark:bg-gray-700 dark:border-gray-600"
                  placeholder="Full Name"
                />
                <div>
                  <input
                    type="text"
                    value={profile.username}
                    onChange={(e) => handleUsernameChange(e.target.value)}
                    className="w-full px-3 py-2 border rounded-lg dark:bg-gray-700 dark:border-gray-600"
                    placeholder="Username"
                  />
                  <p className="mt-1 text-xs text-gray-500">
                    Only letters, numbers, dashes, and underscores allowed
                  </p>
                </div>
                <input
                  type="email"
                  value={profile.email}
                  onChange={(e) => onProfileChange('email', e.target.value)}
                  className="w-full px-3 py-2 border rounded-lg dark:bg-gray-700 dark:border-gray-600"
                  placeholder="Email"
                />
                <input
                  type="text"
                  value={profile.location}
                  onChange={(e) => onProfileChange('location', e.target.value)}
                  className="w-full px-3 py-2 border rounded-lg dark:bg-gray-700 dark:border-gray-600"
                  placeholder="Location"
                />
              </div>
            ) : (
              <>
                <h2 className="text-2xl font-bold text-gray-800 dark:text-gray-100">{profile.name}</h2>
                <p className="text-gray-600 dark:text-gray-400">@{profile.username}</p>
                <p className="text-gray-600 dark:text-gray-400">{profile.email}</p>
                <p className="mt-1 text-sm text-gray-500 dark:text-gray-500">{profile.location}</p>
              </>
            )}
          </div>
        </div>
      </div>

      {/* QR Code Modal */}
      {showQRCode && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
          <div className="bg-white dark:bg-gray-800 rounded-xl p-6 max-w-sm w-full relative">
            <button
              onClick={() => setShowQRCode(false)}
              className="absolute top-4 right-4 text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-200"
            >
              <HiX className="w-6 h-6" />
            </button>
            <h3 className="text-xl font-semibold text-gray-900 dark:text-white mb-4">
              Profile QR Code
            </h3>
            <div className="bg-white p-4 rounded-lg flex justify-center">
              <QRCodeSVG
                value={getProfileUrl()}
                size={200}
                level="H"
                includeMargin={true}
              />
            </div>
            <p className="mt-4 text-sm text-gray-600 dark:text-gray-400 text-center">
              Scan this QR code to view the profile
            </p>
          </div>
        </div>
      )}
    </>
  );
};

export default ProfileHeader;
