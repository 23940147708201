import React from 'react';
import { useDrag } from 'react-dnd';

const DraggableComponent = ({ type, label, icon: Icon }) => {
  const [{ isDragging }, drag] = useDrag({
    type: 'TEMPLATE_COMPONENT',
    item: { type },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  return (
    <div
      ref={drag}
      className={`
        flex items-center p-3 mb-2 rounded-lg cursor-move
        ${isDragging ? 'opacity-50' : 'opacity-100'}
        bg-white dark:bg-gray-800 
        border border-gray-200 dark:border-gray-700
        hover:border-blue-500 dark:hover:border-blue-400
        transition-all duration-200
      `}
    >
      {Icon && <Icon className="w-5 h-5 mr-2 text-gray-500 dark:text-gray-400" />}
      <span className="text-sm text-gray-700 dark:text-gray-300">{label}</span>
    </div>
  );
};

export default DraggableComponent;
