import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { HiSearch, HiUser, HiOfficeBuilding } from 'react-icons/hi';
import { userService } from '../services/firestore';
import { collection, getDocs, query, limit } from 'firebase/firestore';
import { db } from '../services/firebase';
import debounce from 'lodash/debounce';

const UserSearch = ({ inPanel = false, onUserClick }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [results, setResults] = useState([]);
  const [suggestedUsers, setSuggestedUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  // Fetch suggested users - get most recently joined users
  const fetchSuggestedUsers = async () => {
    try {
      // For now, we'll just get the first 5 users as suggestions
      // In a real app, you might want to implement more sophisticated suggestion logic
      const usersRef = collection(db, 'users');
      const querySnapshot = await getDocs(query(usersRef, limit(5)));
      const users = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setSuggestedUsers(users);
    } catch (err) {
      console.error('Failed to fetch suggested users:', err);
    }
  };

  useEffect(() => {
    fetchSuggestedUsers();
  }, []);

  // Debounced search function
  const debouncedSearch = useCallback(
    debounce(async (term) => {
      if (!term.trim()) {
        setResults([]);
        return;
      }

      setLoading(true);
      setError(null);

      try {
        const searchResults = await userService.searchUsers(term);
        setResults(searchResults);
      } catch (err) {
        setError('Failed to search users. Please try again.');
        setResults([]);
      } finally {
        setLoading(false);
      }
    }, 300),
    []
  );

  useEffect(() => {
    debouncedSearch(searchTerm);
    return () => debouncedSearch.cancel();
  }, [searchTerm, debouncedSearch]);

  const handleUserClick = (username) => {
    navigate(`/profile/${username}`);
    if (inPanel && onUserClick) {
      onUserClick();
    }
  };

  const renderUserCard = (user) => (
    <div
      key={user.username}
      onClick={() => handleUserClick(user.username)}
      className="group cursor-pointer bg-white dark:bg-gray-800 rounded-lg shadow-sm hover:shadow-md transition-all duration-200 p-4"
    >
      <div className="flex items-center space-x-4">
        {/* User Avatar/Initials */}
        <div className="flex-shrink-0 w-12 h-12 bg-gradient-to-br from-blue-500 to-purple-600 rounded-full flex items-center justify-center text-white font-bold">
          {user.name.split(' ').map(n => n[0]).join('').toUpperCase().slice(0, 2)}
        </div>

        {/* User Info */}
        <div className="flex-1 min-w-0">
          <p className="text-sm font-medium text-gray-900 dark:text-white truncate group-hover:text-blue-500">
            {user.name}
          </p>
          <div className="flex items-center text-sm text-gray-500 dark:text-gray-400">
            <HiUser className="flex-shrink-0 mr-1.5 h-4 w-4" />
            <span className="truncate">@{user.username}</span>
          </div>
          {user.company && (
            <div className="flex items-center text-sm text-gray-500 dark:text-gray-400 mt-1">
              <HiOfficeBuilding className="flex-shrink-0 mr-1.5 h-4 w-4" />
              <span className="truncate">{user.company}</span>
            </div>
          )}
        </div>
      </div>
    </div>
  );

  return (
    <div className={`w-full ${!inPanel && 'max-w-2xl mx-auto px-4'}`}>
      {/* Search Input */}
      <div className="relative">
        <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
          <HiSearch className="h-5 w-5 text-gray-400" />
        </div>
        <input
          type="text"
          className="block w-full pl-10 pr-3 py-3 border border-gray-300 rounded-lg bg-white dark:bg-gray-800 dark:border-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500 dark:focus:ring-blue-600 focus:border-transparent shadow-sm text-gray-900 dark:text-white placeholder-gray-500 dark:placeholder-gray-400"
          placeholder="Search users by name or username..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          autoFocus={inPanel}
        />
      </div>

      {/* Loading State */}
      {loading && (
        <div className="flex justify-center mt-4">
          <div className="w-6 h-6 border-2 border-blue-500 border-t-transparent rounded-full animate-spin"></div>
        </div>
      )}

      {/* Error State */}
      {error && (
        <div className="mt-4 p-4 bg-red-50 dark:bg-red-900/20 text-red-600 dark:text-red-400 rounded-lg">
          {error}
        </div>
      )}

      {/* Search Results */}
      {results.length > 0 && (
        <div className="mt-4 space-y-2">
          <h2 className="text-lg font-semibold text-gray-900 dark:text-white mb-3">Search Results</h2>
          {results.map(renderUserCard)}
        </div>
      )}

      {/* No Results + Suggested Users */}
      {!loading && searchTerm && results.length === 0 && !error && suggestedUsers.length > 0 && (
        <div className="mt-4">
          <div className="text-center text-gray-500 dark:text-gray-400 mb-6">
            No users found matching your search.
          </div>
          <h2 className="text-lg font-semibold text-gray-900 dark:text-white mb-3">Suggested Users</h2>
          <div className="space-y-2">
            {suggestedUsers.map(renderUserCard)}
          </div>
        </div>
      )}

      {/* Show Suggested Users when no search term */}
      {!searchTerm && suggestedUsers.length > 0 && (
        <div className="mt-4 space-y-2">
          <h2 className="text-lg font-semibold text-gray-900 dark:text-white mb-3">Suggested Users</h2>
          {suggestedUsers.map(renderUserCard)}
        </div>
      )}
    </div>
  );
};

export default UserSearch;
