import React, { useState, useEffect } from 'react';
import { 
  HiHome,
  HiDocumentText, 
  HiPlusCircle,
  HiUserGroup,
  HiClock,
  HiX,
  HiMenu,
  HiMoon,
  HiSun,
  HiInformationCircle,
  HiChartBar,
  HiUser,
  HiCog,
  HiLogin,
  HiLogout,
  HiUsers,
  HiShare
} from 'react-icons/hi';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';

const Sidebar = ({ isOpen, toggleSidebar, darkMode, toggleDarkMode, onAuthClick }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { user, logout } = useAuth();
  const [isInitialLoad, setIsInitialLoad] = useState(true);

  // Enable transitions after initial render
  useEffect(() => {
    const timer = setTimeout(() => {
      setIsInitialLoad(false);
    }, 100);
    return () => clearTimeout(timer);
  }, []); // Only run once on mount

  // Handle scroll lock when sidebar is open on mobile
  useEffect(() => {
    const body = document.body;
    const isMobile = window.innerWidth < 1024;
    
    if (isMobile && isOpen) {
      body.style.overflow = 'hidden';
    } else {
      body.style.overflow = '';
    }

    return () => {
      body.style.overflow = '';
    };
  }, [isOpen]);

  const publicMenuItems = [
    { 
      title: 'Network Map', 
      icon: <HiShare size={24} />, 
      path: '/network-map',
      description: 'Visualize network connections'
    }
  ];

  const protectedMenuItems = [
    { 
      title: 'Profile', 
      icon: <HiUser size={24} />, 
      path: '/profile',
      description: 'Manage your profile'
    },
    { 
      title: 'Connections', 
      icon: <HiUsers size={24} />, 
      path: '/connections',
      description: 'Manage your network connections'
    }
  ];

  const authMenuItems = [
    {
      title: 'About',
      icon: <HiInformationCircle size={24} />,
      path: '/about',
      description: 'Learn about Agreebits'
    },
    { 
      title: 'Login', 
      icon: <HiLogin size={24} />, 
      action: () => onAuthClick('login'),
      description: 'Sign in to your account'
    }
  ];

  const handleNavigation = (item) => {
    if (item.action) {
      item.action();
    } else if (item.path) {
      navigate(item.path);
    }
    
    // Close sidebar after navigation regardless of screen size
    toggleSidebar(false);
  };

  const handleLogout = () => {
    logout();
    navigate('/');
    toggleSidebar(false);
  };

  const handleToggle = () => {
    toggleSidebar(!isOpen);
  };

  return (
    <>
      {/* Mobile Menu Button */}
      <button 
        onClick={handleToggle}
        className="lg:hidden fixed top-4 left-4 z-50 p-2.5 rounded-lg bg-blue-600 text-white hover:bg-blue-700 transition-colors duration-200 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 active:scale-95 transform"
        aria-label="Toggle Menu"
      >
        {isOpen ? <HiX size={24} /> : <HiMenu size={24} />}
      </button>

      {/* Desktop Toggle Button */}
      <button
        onClick={handleToggle}
        className="hidden lg:flex fixed top-4 left-4 z-50 p-2.5 rounded-lg bg-blue-600 text-white hover:bg-blue-700 transition-all duration-300 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 active:scale-95 transform"
        aria-label="Toggle Sidebar"
      >
        {isOpen ? <HiX size={24} /> : <HiMenu size={24} />}
      </button>

      {/* Overlay (both mobile and desktop) */}
      <div 
        className={`
          fixed inset-0 bg-black z-30
          ${isInitialLoad ? '' : 'transition-opacity duration-300 ease-in-out'}
          ${isOpen ? 'opacity-50 backdrop-blur-sm' : 'opacity-0 pointer-events-none'}
        `}
        onClick={() => toggleSidebar(false)}
        aria-hidden="true"
      />

      {/* Sidebar */}
      <aside 
        className={`
          fixed top-0 left-0 h-full z-40
          w-[280px]
          ${isInitialLoad ? '' : 'transition-transform duration-300 ease-in-out'}
          ${isOpen ? 'translate-x-0' : '-translate-x-[280px]'}
          bg-white dark:bg-gray-800
          flex flex-col
          shadow-lg
        `}
        aria-label="Sidebar Navigation"
      >
        <div className="flex flex-col h-full">
          {/* Menu Button Spacer */}
          <div className="h-16 lg:h-0" />

          {/* Header Section */}
          <div className="sticky top-16 lg:top-0 z-10 bg-white dark:bg-gray-800 px-4 pb-4 border-b border-gray-200 dark:border-gray-700">
            <div className="flex justify-between items-center">
              <div>
                <h1 className="text-2xl font-bold text-blue-600 dark:text-blue-400">Agreebits</h1>
                <p className="text-sm text-gray-600 dark:text-gray-400">Simplify your agreements</p>
              </div>
              <button
                onClick={toggleDarkMode}
                className="p-2.5 rounded-lg bg-gray-100 dark:bg-gray-700 text-gray-600 dark:text-gray-300 hover:bg-gray-200 dark:hover:bg-gray-600 transition-colors duration-200 focus:outline-none focus:ring-2 focus:ring-gray-300 dark:focus:ring-gray-600 active:scale-95 transform"
                aria-label="Toggle Dark Mode"
              >
                {darkMode ? <HiSun size={20} /> : <HiMoon size={20} />}
              </button>
            </div>
          </div>

          <div className="flex-1 overflow-y-auto overscroll-contain px-4 py-4 space-y-1">
            <nav className="space-y-1">
              {/* Public Menu Items - Always visible */}
              {publicMenuItems.map((item, index) => (
                <MenuItem 
                  key={`public-${index}`}
                  item={item}
                  location={location}
                  handleNavigation={handleNavigation}
                />
              ))}

              {(user || (!user && authMenuItems.length > 0)) && (
                <div className="my-4 border-t border-gray-200 dark:border-gray-700" />
              )}

              {user && protectedMenuItems.map((item, index) => (
                <MenuItem 
                  key={`protected-${index}`}
                  item={item}
                  location={location}
                  handleNavigation={handleNavigation}
                />
              ))}

              {!user && authMenuItems.map((item, index) => (
                <MenuItem 
                  key={`auth-${index}`}
                  item={item}
                  location={location}
                  handleNavigation={handleNavigation}
                />
              ))}
            </nav>
          </div>

          {user && (
            <div className="sticky bottom-0 bg-white dark:bg-gray-800 border-t border-gray-200 dark:border-gray-700 p-4">
              <div
                onClick={handleLogout}
                className="flex items-center space-x-3 p-3 rounded-lg cursor-pointer hover:bg-gray-50 dark:hover:bg-gray-700/50 text-red-600 dark:text-red-400 transition-colors duration-200 focus:outline-none focus:ring-2 focus:ring-red-500 dark:focus:ring-red-400"
                role="button"
                tabIndex={0}
              >
                <div className="text-red-600 dark:text-red-400">
                  <HiLogout size={24} />
                </div>
                <div>
                  <div className="font-medium">Logout</div>
                  <div className="text-xs text-gray-500 dark:text-gray-400">
                    Sign out of your account
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </aside>
    </>
  );
};

const MenuItem = ({ item, location, handleNavigation }) => (
  <div
    onClick={() => handleNavigation(item)}
    className={`
      flex items-center space-x-3 p-3 rounded-lg cursor-pointer
      transition-all duration-200
      focus:outline-none focus:ring-2 focus:ring-blue-500 dark:focus:ring-blue-400
      active:scale-[0.98] transform
      ${location.pathname === item.path 
        ? 'bg-blue-50 dark:bg-blue-900/30 text-blue-600 dark:text-blue-400' 
        : 'hover:bg-gray-50 dark:hover:bg-gray-700/50'
      }
    `}
    role="button"
    tabIndex={0}
    aria-current={location.pathname === item.path ? 'page' : undefined}
  >
    <div className={`
      ${location.pathname === item.path 
        ? 'text-blue-600 dark:text-blue-400' 
        : 'text-gray-600 dark:text-gray-400'
      }
    `}>
      {item.icon}
    </div>
    <div>
      <div className={`
        font-medium
        ${location.pathname === item.path 
          ? 'text-blue-600 dark:text-blue-400' 
          : 'text-gray-800 dark:text-gray-200'
        }
      `}>
        {item.title}
      </div>
      <div className="text-xs text-gray-500 dark:text-gray-400">
        {item.description}
      </div>
    </div>
  </div>
);

export default Sidebar;
