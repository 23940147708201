import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { 
  HiUserAdd, 
  HiDotsVertical, 
  HiMail,
  HiUserGroup,
  HiDocumentText,
  HiCog,
  HiX,
  HiArrowLeft
} from 'react-icons/hi';

const TeamPage = () => {
  const { teamId } = useParams();
  const [team, setTeam] = useState(null);
  const [showInviteModal, setShowInviteModal] = useState(false);
  const [inviteEmail, setInviteEmail] = useState('');
  const [showActionMenu, setShowActionMenu] = useState(false);

  // Simulated team data - in real app, fetch from API
  useEffect(() => {
    // Mock data - replace with actual API call
    setTeam({
      id: parseInt(teamId),
      name: 'Development Team',
      description: 'Software development and engineering team',
      members: [
        { id: 1, name: 'John Doe', role: 'Team Lead', email: 'john@example.com', avatar: 'JD' },
        { id: 2, name: 'Sarah Smith', role: 'Developer', email: 'sarah@example.com', avatar: 'SS' },
        { id: 3, name: 'Mike Johnson', role: 'Developer', email: 'mike@example.com', avatar: 'MJ' }
      ],
      agreements: 3,
      pendingInvites: 2
    });
  }, [teamId]);

  const handleInviteMember = () => {
    // Implement invite logic
    setInviteEmail('');
    setShowInviteModal(false);
  };

  const handleTeamAction = (action) => {
    setShowActionMenu(false);
    console.log(`${action} team ${teamId}`);
    // Implement team actions
  };

  if (!team) {
    return (
      <div className="flex justify-center items-center min-h-screen">
        <div className="text-gray-600 dark:text-gray-400">Loading...</div>
      </div>
    );
  }

  return (
    <div className="max-w-6xl mx-auto">
      {/* Header with Back Button */}
      <div className="flex items-center mb-6">
        <Link to="/teams" className="text-gray-600 hover:text-gray-800 dark:text-gray-400 dark:hover:text-gray-200">
          <HiArrowLeft className="w-5 h-5" />
        </Link>
        <h1 className="text-2xl font-bold ml-4 text-gray-800 dark:text-gray-100">{team.name}</h1>
      </div>

      <div className="bg-white dark:bg-gray-800 rounded-lg shadow-sm border border-gray-100 dark:border-gray-700">
        <div className="p-6">
          <div className="flex justify-between items-start">
            <div>
              <p className="text-sm text-gray-500 dark:text-gray-400 mt-1">{team.description}</p>
            </div>
            <div className="relative">
              <button
                onClick={() => setShowActionMenu(!showActionMenu)}
                className="p-2 hover:bg-gray-100 dark:hover:bg-gray-700 rounded-full"
              >
                <HiDotsVertical className="w-5 h-5 text-gray-500 dark:text-gray-400" />
              </button>

              {showActionMenu && (
                <div className="absolute right-0 mt-2 w-48 rounded-md shadow-lg bg-white dark:bg-gray-800 ring-1 ring-black ring-opacity-5 z-10">
                  <div className="py-1">
                    <button
                      onClick={() => handleTeamAction('settings')}
                      className="flex items-center px-4 py-2 text-sm text-gray-700 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700 w-full text-left"
                    >
                      <HiCog className="mr-3 w-4 h-4" />
                      Settings
                    </button>
                    <button
                      onClick={() => setShowInviteModal(true)}
                      className="flex items-center px-4 py-2 text-sm text-gray-700 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700 w-full text-left"
                    >
                      <HiUserAdd className="mr-3 w-4 h-4" />
                      Invite Members
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className="mt-6 flex items-center space-x-4">
            <div className="flex items-center space-x-2">
              <HiUserGroup className="w-5 h-5 text-gray-400 dark:text-gray-500" />
              <span className="text-sm text-gray-600 dark:text-gray-400">{team.members.length} Members</span>
            </div>
            <div className="flex items-center space-x-2">
              <HiDocumentText className="w-5 h-5 text-gray-400 dark:text-gray-500" />
              <span className="text-sm text-gray-600 dark:text-gray-400">{team.agreements} Agreements</span>
            </div>
          </div>

          <div className="mt-8">
            <div className="flex items-center justify-between mb-4">
              <h3 className="text-lg font-semibold text-gray-800 dark:text-gray-100">Team Members</h3>
              {team.pendingInvites > 0 && (
                <span className="text-xs text-yellow-600 dark:text-yellow-500 bg-yellow-50 dark:bg-yellow-900/30 px-2 py-1 rounded-full">
                  {team.pendingInvites} pending invites
                </span>
              )}
            </div>
            <div className="grid gap-4 md:grid-cols-2 lg:grid-cols-3">
              {team.members.map(member => (
                <div key={member.id} className="bg-gray-50 dark:bg-gray-700/50 rounded-lg p-4">
                  <div className="flex items-center space-x-3">
                    <div className="w-10 h-10 rounded-full bg-gray-200 dark:bg-gray-700 flex items-center justify-center text-sm font-medium text-gray-600 dark:text-gray-300">
                      {member.avatar}
                    </div>
                    <div>
                      <div className="text-sm font-medium text-gray-800 dark:text-gray-200">{member.name}</div>
                      <div className="text-xs text-gray-500 dark:text-gray-400">{member.role}</div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      {/* Invite Member Modal */}
      {showInviteModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
          <div className="bg-white dark:bg-gray-800 rounded-lg max-w-md w-full">
            <div className="p-6">
              <div className="flex justify-between items-center mb-4">
                <h2 className="text-xl font-semibold text-gray-800 dark:text-gray-100">Invite Team Member</h2>
                <button
                  onClick={() => setShowInviteModal(false)}
                  className="p-1 hover:bg-gray-100 dark:hover:bg-gray-700 rounded-full"
                >
                  <HiX className="w-5 h-5 text-gray-500 dark:text-gray-400" />
                </button>
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                  Email Address
                </label>
                <input
                  type="email"
                  value={inviteEmail}
                  onChange={(e) => setInviteEmail(e.target.value)}
                  className="w-full px-4 py-2 rounded-lg border border-gray-200 dark:border-gray-600 bg-white dark:bg-gray-700 text-gray-800 dark:text-gray-100 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                  placeholder="Enter email address"
                />
              </div>
              <div className="mt-6 flex justify-end space-x-3">
                <button
                  onClick={() => setShowInviteModal(false)}
                  className="px-4 py-2 text-gray-600 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700 rounded-lg"
                >
                  Cancel
                </button>
                <button
                  onClick={handleInviteMember}
                  className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 flex items-center space-x-2"
                >
                  <HiMail className="w-5 h-5" />
                  <span>Send Invite</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default TeamPage;
