import React, { useState, useEffect } from 'react';
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';
import { AuthProvider, useAuth } from './contexts/AuthContext';
import { ModulesProvider } from './contexts/ModulesContext';
import { AuthModalProvider } from './contexts/AuthModalContext';
import { ProtectedRoute, PublicRoute } from './components/RouteGuard';

// Auth Modal
import AuthModal from './components/AuthModal';

// Main Pages
import About from './pages/About';
import TeamPage from './pages/TeamPage';
import TemplateBuilder from './pages/TemplateBuilder';
import Profile from './pages/Profile';
import UserProfile from './pages/UserProfile';
import MinimalUserProfile from './pages/MinimalUserProfile';
import ForgotPassword from './pages/Auth/ForgotPassword';
import ProjectPage from './pages/ProjectPage';
import Search from './pages/Search';
import Connections from './pages/Connections';
import NetworkMapPage from './pages/NetworkMapPage';

// Components
import Sidebar from './components/Sidebar';
import GlobalSearch from './components/GlobalSearch';

// Conditional redirect based on auth status
const ConditionalRedirect = () => {
  const { user, loading } = useAuth();
  
  if (loading) {
    return null;
  }
  
  return <Navigate to={user ? "/profile" : "/about"} replace />;
};

// Component to handle logged-in users trying to access public routes
const LoggedInRedirect = () => {
  const { user, loading } = useAuth();
  
  if (loading) {
    return null;
  }
  
  return user ? <Navigate to="/profile" replace /> : <About />;
};

// Layout wrapper for protected routes
const DashboardLayout = ({ children, darkMode, toggleDarkMode, onAuthClick }) => {
  // Always start with sidebar closed
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);

  const toggleSidebar = (value) => {
    setIsSidebarOpen(typeof value === 'boolean' ? value : !isSidebarOpen);
  };

  // Handle scroll shadow effect
  useEffect(() => {
    const handleScroll = (e) => {
      const target = e.target;
      setIsScrolled(target.scrollTop > 0);
    };

    const mainContent = document.getElementById('main-content');
    if (mainContent) {
      mainContent.addEventListener('scroll', handleScroll);
      return () => mainContent.removeEventListener('scroll', handleScroll);
    }
  }, []);

  return (
    <div className={`flex h-screen ${darkMode ? 'dark' : ''}`}>
      <div className="flex w-full">
        <Sidebar 
          isOpen={isSidebarOpen} 
          toggleSidebar={toggleSidebar}
          darkMode={darkMode}
          toggleDarkMode={toggleDarkMode}
          onAuthClick={onAuthClick}
        />

        <main 
          id="main-content"
          className={`
            flex-1 overflow-y-auto bg-gray-50 dark:bg-gray-900 pb-safe
            transition-all duration-300
            ${isSidebarOpen ? 'lg:ml-[280px]' : 'lg:ml-0'}
          `}
        >
          {/* Header with search icon */}
          <div className={`
            sticky top-0 z-10 
            transition-shadow duration-200
            ${isScrolled ? 'shadow-md dark:shadow-gray-800/30' : ''}
            bg-gray-50 dark:bg-gray-900
            pt-safe
          `}>
            <div className="h-16 px-4 flex items-center justify-end">
              <GlobalSearch />
            </div>
          </div>

          {/* Main content */}
          <div className="px-4 sm:px-6 lg:px-8 py-6 max-w-7xl mx-auto">
            {children}
          </div>
        </main>
      </div>
    </div>
  );
};

function App() {
  const [darkMode, setDarkMode] = useState(false);
  const [isAuthModalOpen, setIsAuthModalOpen] = useState(false);
  const [authModalMode, setAuthModalMode] = useState('login');

  // Set viewport meta tag for mobile
  useEffect(() => {
    const viewport = document.querySelector('meta[name=viewport]');
    if (!viewport) {
      const meta = document.createElement('meta');
      meta.name = 'viewport';
      meta.content = 'width=device-width, initial-scale=1, viewport-fit=cover, user-scalable=no';
      document.head.appendChild(meta);
    } else {
      viewport.content = 'width=device-width, initial-scale=1, viewport-fit=cover, user-scalable=no';
    }

    // Add CSS variables for safe area insets
    const updateSafeAreaInsets = () => {
      document.documentElement.style.setProperty(
        '--sat', 'env(safe-area-inset-top, 0px)'
      );
      document.documentElement.style.setProperty(
        '--sab', 'env(safe-area-inset-bottom, 0px)'
      );
      document.documentElement.style.setProperty(
        '--sal', 'env(safe-area-inset-left, 0px)'
      );
      document.documentElement.style.setProperty(
        '--sar', 'env(safe-area-inset-right, 0px)'
      );
    };

    updateSafeAreaInsets();
    window.addEventListener('resize', updateSafeAreaInsets);
    return () => window.removeEventListener('resize', updateSafeAreaInsets);
  }, []);

  // Initialize dark mode from localStorage
  useEffect(() => {
    const savedDarkMode = localStorage.getItem('darkMode') === 'true';
    setDarkMode(savedDarkMode);
    if (savedDarkMode) {
      document.documentElement.classList.add('dark');
    }

    // Add touch-action manipulation for better mobile performance
    document.documentElement.style.touchAction = 'manipulation';
  }, []);

  const toggleDarkMode = () => {
    setDarkMode(prev => {
      const newValue = !prev;
      localStorage.setItem('darkMode', newValue);
      if (newValue) {
        document.documentElement.classList.add('dark');
      } else {
        document.documentElement.classList.remove('dark');
      }
      return newValue;
    });
  };

  const openAuthModal = (mode = 'login') => {
    setAuthModalMode(mode);
    setIsAuthModalOpen(true);
  };

  const closeAuthModal = () => {
    setIsAuthModalOpen(false);
    setAuthModalMode('login');
  };

  return (
    <AuthProvider>
      <ModulesProvider>
        <AuthModalProvider onAuthClick={openAuthModal}>
          <div className={`${darkMode ? 'dark' : ''} min-h-screen touch-manipulation`}>
            <style data-jsx="true" data-global="true">{`
              .pt-safe { padding-top: var(--sat) }
              .pb-safe { padding-bottom: var(--sab) }
              .pl-safe { padding-left: var(--sal) }
              .pr-safe { padding-right: var(--sar) }
              
              @supports(padding: max(0px)) {
                .pt-safe { padding-top: max(var(--sat), env(safe-area-inset-top, 0px)) }
                .pb-safe { padding-bottom: max(var(--sab), env(safe-area-inset-bottom, 0px)) }
                .pl-safe { padding-left: max(var(--sal), env(safe-area-inset-left, 0px)) }
                .pr-safe { padding-right: max(var(--sar), env(safe-area-inset-right, 0px)) }
              }
            `}</style>

            <AuthModal 
              isOpen={isAuthModalOpen} 
              onClose={closeAuthModal} 
              initialMode={authModalMode}
            />

            <Routes>
              {/* Public Routes - Only accessible when logged out */}
              <Route
                path="/about"
                element={
                  <PublicRoute>
                    <DashboardLayout darkMode={darkMode} toggleDarkMode={toggleDarkMode} onAuthClick={openAuthModal}>
                      <About onAuthClick={openAuthModal} />
                    </DashboardLayout>
                  </PublicRoute>
                }
              />

              {/* Search Route - Accessible to everyone */}
              <Route path="/search" element={<Search />} />

              {/* Network Map - Accessible to everyone */}
              <Route
                path="/network-map"
                element={
                  <DashboardLayout darkMode={darkMode} toggleDarkMode={toggleDarkMode} onAuthClick={openAuthModal}>
                    <NetworkMapPage />
                  </DashboardLayout>
                }
              />

              {/* Public Profile Routes - Accessible to everyone */}
              <Route
                path="/profile/:username"
                element={
                  <PublicRoute>
                    <DashboardLayout darkMode={darkMode} toggleDarkMode={toggleDarkMode} onAuthClick={openAuthModal}>
                      <UserProfile />
                    </DashboardLayout>
                  </PublicRoute>
                }
              />

              {/* Minimal Profile Route - No layout wrapper */}
              <Route
                path="/minimal-profile/:username"
                element={<MinimalUserProfile />}
              />

              {/* Protected Routes */}
              <Route
                path="/connections"
                element={
                  <ProtectedRoute>
                    <DashboardLayout darkMode={darkMode} toggleDarkMode={toggleDarkMode} onAuthClick={openAuthModal}>
                      <Connections />
                    </DashboardLayout>
                  </ProtectedRoute>
                }
              />
              <Route
                path="/templates/builder"
                element={
                  <ProtectedRoute>
                    <TemplateBuilder />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/templates/builder/:templateId"
                element={
                  <ProtectedRoute>
                    <TemplateBuilder />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/teams/:teamId"
                element={
                  <ProtectedRoute>
                    <DashboardLayout darkMode={darkMode} toggleDarkMode={toggleDarkMode} onAuthClick={openAuthModal}>
                      <TeamPage />
                    </DashboardLayout>
                  </ProtectedRoute>
                }
              />
              <Route
                path="/profile"
                element={
                  <ProtectedRoute>
                    <DashboardLayout darkMode={darkMode} toggleDarkMode={toggleDarkMode} onAuthClick={openAuthModal}>
                      <Profile />
                    </DashboardLayout>
                  </ProtectedRoute>
                }
              />
              <Route
                path="/project/:projectId"
                element={
                  <ProtectedRoute>
                    <DashboardLayout darkMode={darkMode} toggleDarkMode={toggleDarkMode} onAuthClick={openAuthModal}>
                      <ProjectPage />
                    </DashboardLayout>
                  </ProtectedRoute>
                }
              />

              {/* Special Routes */}
              <Route path="/forgot-password" element={<ForgotPassword />} />

              {/* Default redirect */}
              <Route path="/" element={<ConditionalRedirect />} />
              <Route path="*" element={<ConditionalRedirect />} />
            </Routes>
          </div>
        </AuthModalProvider>
      </ModulesProvider>
    </AuthProvider>
  );
}

export default App;
