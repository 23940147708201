// YouTube profile fetching using RapidAPI
const RAPID_API_KEY = '94dfe38c00msh2095861bdba1943p179b8ejsna0dc6af4cd8b';
const RAPID_API_HOST = 'yt-api.p.rapidapi.com';

// Calculate subscriber to following ratio
const calculateRatio = (subscribers, following) => {
  if (following === 0) return 0;
  const ratio = subscribers / following;
  return Number(ratio.toFixed(2));
};

// Convert subscriber count string to number
const parseSubscriberCount = (subscriberCount) => {
  if (typeof subscriberCount === 'number') return subscriberCount;
  if (!subscriberCount) return 0;

  // Handle cases like "1.2M", "500K", etc.
  const multipliers = {
    'K': 1000,
    'M': 1000000,
    'B': 1000000000
  };

  try {
    // Remove any commas and spaces
    let count = subscriberCount.toString().replace(/,|\s/g, '');
    
    // Check for multiplier suffix
    const suffix = count.slice(-1).toUpperCase();
    if (multipliers[suffix]) {
      count = parseFloat(count.slice(0, -1)) * multipliers[suffix];
    } else {
      count = parseFloat(count);
    }

    return isNaN(count) ? 0 : Math.round(count);
  } catch (error) {
    console.error('Error parsing subscriber count:', error);
    return 0;
  }
};

// Extract channel ID from URL or handle
const extractChannelId = (input) => {
  try {
    // If input is already a channel ID (starts with UC), return it
    if (input.startsWith('UC')) {
      return input;
    }

    // Check if input is a URL
    if (input.includes('youtube.com') || input.includes('youtu.be')) {
      const url = new URL(input);
      const pathname = url.pathname.split('/').filter(Boolean);
      
      // Handle different URL formats
      if (pathname[0] === 'channel') {
        return pathname[1]; // Should be a UC... ID
      }
      
      // For custom URLs (@handle, /c/, /user/), we need to handle differently
      // These might require a different API endpoint to resolve to channel ID
      throw new Error('Please provide a full channel URL (youtube.com/channel/UC...) or channel ID (UC...)');
    }
    
    throw new Error('Please provide a full channel URL (youtube.com/channel/UC...) or channel ID (UC...)');
  } catch (error) {
    console.error('Error parsing YouTube identifier:', error);
    throw error;
  }
};

// Get user stats
export const scrapeYoutubeStats = async (input) => {
  if (!input) {
    throw new Error('YouTube channel URL or ID is required');
  }

  try {
    const channelId = extractChannelId(input);
    
    // Log the extracted channel ID for debugging
    console.log('Extracted Channel ID:', channelId);

    const response = await fetch(
      `https://${RAPID_API_HOST}/channel/about?id=${channelId}`,
      {
        method: 'GET',
        headers: {
          'X-RapidAPI-Key': RAPID_API_KEY,
          'X-RapidAPI-Host': RAPID_API_HOST
        }
      }
    );

    if (!response.ok) {
      const error = await response.json();
      throw new Error(error.message || error.error || 'Failed to fetch YouTube stats');
    }

    const data = await response.json();
    
    
    if (!data || data.error) {
      throw new Error(data.error || 'Channel not found');
    }

    const subscribers = parseSubscriberCount(data.subscriberCount || data.stats?.subscriberCount || '0');
    const views = parseSubscriberCount(data.viewCount || data.stats?.viewCount || '0');
    const videos = parseInt(data.videosCount || data.stats?.videoCount || '0', 10);
    // YouTube doesn't expose following count, using 0 as placeholder
    const following = 0;

    console.log(data.channelHandle);

    return {
      username: data.username || data.channelHandle || channelId,
      fullName: data.title || data.name || channelId,
      profilePic: data.avatar?.[0]?.url || data.thumbnails?.[0]?.url || '',
      followers: subscribers,
      following,
      ratio: calculateRatio(subscribers, following),
      posts: videos,
      views: views,
      bio: data.description || '',
      isVerified: data.badges?.includes('Verified') || false,
      isPrivate: false // YouTube channels are public by default
    };
  } catch (error) {
    console.error('Error fetching YouTube stats:', error);
    throw error;
  }
};
