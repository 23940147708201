// Instagram profile fetching using RapidAPI
const RAPID_API_KEY = '94dfe38c00msh2095861bdba1943p179b8ejsna0dc6af4cd8b';
const RAPID_API_HOST = 'instagram-scraper-api2.p.rapidapi.com';

// Get user stats
export const scrapeInstagramStats = async (handle) => {
  if (!handle) {
    throw new Error('Instagram handle is required');
  }

  // Clean the handle (remove @ if present)
  const cleanHandle = handle.startsWith('@') ? handle.substring(1) : handle;

  // Fetch from RapidAPI
  try {
    const response = await fetch(
      `https://${RAPID_API_HOST}/v1/info?username_or_id_or_url=${encodeURIComponent(cleanHandle)}`,
      {
        headers: {
          'X-RapidAPI-Key': RAPID_API_KEY,
          'X-RapidAPI-Host': RAPID_API_HOST,
          'Accept': 'application/json'
        }
      }
    );

    if (!response.ok) {
      const error = await response.json();
      throw new Error(error.message || 'Failed to fetch Instagram stats');
    }

    const data = await response.json();
    
    if (!data.data) {
      throw new Error('Profile not found');
    }

    return {
      username: cleanHandle,
      platformUsername: data.data.username,
      followers: data.data.follower_count || 0,
      following: data.data.following_count || 0,
      posts: data.data.media_count || 0,
      profilePic: data.data.profile_pic_url_hd || data.data.profile_pic_url || null,
      description: data.data.biography || null,
      displayName: data.data.full_name || null,
      isVerified: Boolean(data.data.is_verified),
      isPrivate: Boolean(data.data.is_private)
    };
  } catch (error) {
    console.error('Error fetching Instagram stats:', error);
    throw error;
  }
};
