import React from 'react';
import NetworkMap from '../components/NetworkMap';

const NetworkMapPage = () => {
    return (
        <div className="flex-1">
            <NetworkMap />
        </div>
    );
};

export default NetworkMapPage;
