import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { FaInstagram, FaSoundcloud, FaTwitter, FaYoutube, FaExternalLinkAlt, FaMapMarkerAlt, FaCheckCircle, FaLock } from 'react-icons/fa';

const fadeInUp = {
  hidden: { opacity: 0, y: 20 },
  visible: { opacity: 1, y: 0 }
};

const contentVariants = {
  enter: { 
    x: 20, 
    opacity: 0,
    transition: { duration: 0.3 }
  },
  center: { 
    x: 0, 
    opacity: 1,
    transition: { duration: 0.3 }
  },
  exit: { 
    x: -20, 
    opacity: 0,
    transition: { duration: 0.3 }
  }
};

const statsVariants = {
  hidden: { opacity: 0, y: 20 },
  visible: (index) => ({
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.3,
      delay: index * 0.1,
    },
  }),
};

const platformIcons = {
  instagram: FaInstagram,
  soundcloud: FaSoundcloud,
  twitter: FaTwitter,
  youtube: FaYoutube
};

const platformGradients = {
  instagram: 'from-[#833ab4] via-[#fd1d1d] to-[#fcb045]',
  soundcloud: 'from-[#ff3300] via-[#ff7100] to-[#ff8800]',
  twitter: 'from-[#1DA1F2] via-[#1a91da] to-[#0d47a1]',
  youtube: 'from-[#FF0000] via-[#FF5722] to-[#F44336]'
};

const platformDomains = {
  instagram: ['instagram.com', 'www.instagram.com'],
  twitter: ['twitter.com', 'www.twitter.com', 'x.com', 'www.x.com'],
  youtube: ['youtube.com', 'www.youtube.com', 'youtu.be'],
  soundcloud: ['soundcloud.com', 'www.soundcloud.com']
};

const platformColors = {
  instagram: 'text-pink-600 hover:text-pink-700',
  soundcloud: 'text-orange-500 hover:text-orange-600',
  twitter: 'text-blue-400 hover:text-blue-500',
  youtube: 'text-red-600 hover:text-red-700'
};

const isValidUrl = (string) => {
  try {
    new URL(string);
    return true;
  } catch (_) {
    return false;
  }
};

const extractUsername = (platform, url) => {
  if (!url) return '';
  
  // If it's not a URL, return as is
  if (!url.includes('http') && !url.includes('.com')) {
    return url.replace('@', ''); // Remove @ if present
  }

  try {
    const urlObj = new URL(url.startsWith('http') ? url : `https://${url}`);
    const pathname = urlObj.pathname.replace(/\/$/, ''); // Remove trailing slash
    
    switch (platform) {
      case 'instagram':
        return pathname.split('/')[1] || '';
      case 'twitter':
        return pathname.split('/')[1] || '';
      case 'youtube':
        // Handle different YouTube URL formats
        if (pathname.includes('/c/') || pathname.includes('/@')) {
          return pathname.split('/').pop() || '';
        } else if (pathname.includes('/channel/')) {
          return pathname.split('/channel/')[1] || '';
        }
        return pathname.split('/').pop() || '';
      case 'soundcloud':
        return pathname.split('/')[1] || '';
      default:
        return pathname.split('/')[1] || '';
    }
  } catch (e) {
    // If URL parsing fails, try to extract username from string
    const parts = url.split('/');
    return parts[parts.length - 1].replace('@', '') || url;
  }
};

const generateProfileUrl = (platform, input) => {
  if (!input) return '#';

  // If input is already a URL, validate and format it
  if (input.includes('http') || input.includes('.com')) {
    try {
      const url = input.startsWith('http') ? input : `https://${input}`;
      const urlObj = new URL(url);
      
      // Check if URL matches the platform
      if (platformDomains[platform].includes(urlObj.hostname)) {
        // Clean up the URL
        let cleanUrl = url;
        // Remove tracking parameters
        const urlWithoutParams = cleanUrl.split('?')[0];
        // Remove trailing slash
        cleanUrl = urlWithoutParams.replace(/\/$/, '');
        // Ensure https
        if (!cleanUrl.startsWith('https://')) {
          cleanUrl = cleanUrl.replace('http://', 'https://');
        }
        return cleanUrl;
      }
    } catch (e) {
      // If URL parsing fails, fall back to username-based URL
    }
  }

  // If not a valid URL or doesn't match platform, construct URL from username
  const cleanUsername = input.replace('@', '').trim();

  switch (platform) {
    case 'instagram':
      return `https://instagram.com/${cleanUsername}`;
    case 'twitter':
      return `https://twitter.com/${cleanUsername}`;
    case 'youtube':
      return `https://youtube.com/@${cleanUsername}`;
    case 'soundcloud':
      return `https://soundcloud.com/${cleanUsername}`;
    default:
      return '#';
  }
};

const SocialStatsModule = ({ allStats }) => {
  const availablePlatforms = Object.entries(allStats)
    .filter(([_, stats]) => stats !== null)
    .map(([platform]) => platform);

  const [activePlatform, setActivePlatform] = useState(availablePlatforms[0]);
  const [direction, setDirection] = useState(0);

  if (availablePlatforms.length === 0) return null;

  const stats = allStats[activePlatform];
  const ActiveIcon = platformIcons[activePlatform];

  const formatNumber = (num) => {
    if (!num && num !== 0) return '0';
    if (num >= 1000000) {
      return (num / 1000000).toFixed(1) + 'M';
    }
    if (num >= 1000) {
      return (num / 1000).toFixed(1) + 'K';
    }
    return num.toString();
  };

  const handlePlatformChange = (newPlatform) => {
    const oldIndex = availablePlatforms.indexOf(activePlatform);
    const newIndex = availablePlatforms.indexOf(newPlatform);
    setDirection(newIndex > oldIndex ? 1 : -1);
    setActivePlatform(newPlatform);
  };

  const gradient = platformGradients[activePlatform];
  const username = extractUsername(activePlatform, stats.platform_username);
  const displayName = stats.display_name || username;
  const profileUrl = generateProfileUrl(activePlatform, stats.platform_username);

  // Get platform-specific stats configuration
  const getStatsConfig = () => {
    if (activePlatform === 'youtube') {
     
      return [
        { label: 'Subscribers', value: stats.followers },
        { label: 'Videos', value: stats.posts },
        { label: 'Total Views', value: stats.views }
      ];
    } else if (activePlatform === 'soundcloud') {
      console.log(stats);
      return [
        { label: 'Followers', value: stats.followers },
        { label: 'Following', value: stats.following },
        { label: 'Songs', value: stats.posts }
      ];
    } else {
      return [
        { label: 'Followers', value: stats.followers },
        { label: 'Following', value: stats.following },
        { label: 'Posts', value: stats.posts }
      ];
    }
  };

  return (
    <motion.div
      variants={fadeInUp}
      className="mb-6"
    >
      <div className={`relative bg-gradient-to-br ${gradient} p-[2px] rounded-xl overflow-hidden shadow-lg hover:shadow-xl transition-shadow duration-300 backdrop-blur-sm`}>
        <div className="bg-white dark:bg-gray-900 rounded-xl">
          {/* Platform Tabs */}
          <div className="flex border-b border-gray-200 dark:border-gray-700">
            {availablePlatforms.map(platform => {
              const Icon = platformIcons[platform];
              const isActive = platform === activePlatform;
              return (
                <motion.button
                  key={platform}
                  onClick={() => handlePlatformChange(platform)}
                  className={`flex-1 py-4 px-4 flex items-center justify-center gap-2 text-sm font-medium transition-all duration-300 ${
                    isActive 
                      ? `${platformColors[platform]} border-b-2 border-current scale-105` 
                      : 'text-gray-500 dark:text-gray-400 hover:text-gray-700 dark:hover:text-gray-300 hover:bg-gray-50 dark:hover:bg-gray-800'
                  }`}
                  whileHover={{ scale: isActive ? 1.05 : 1.02 }}
                  whileTap={{ scale: 0.98 }}
                >
                  <Icon className={`h-5 w-5 transition-transform duration-300 ${isActive ? 'scale-110' : ''}`} />
                </motion.button>
              );
            })}
          </div>

          <AnimatePresence mode="wait" initial={false} custom={direction}>
            <motion.div
              key={activePlatform}
              custom={direction}
              variants={contentVariants}
              initial="enter"
              animate="center"
              exit="exit"
              className="p-5"
            >
              {/* Header */}
              <div className="flex items-center justify-between mb-5">
                <div className="flex items-center gap-4">
                  <motion.div 
                    className={`p-3 rounded-xl bg-gradient-to-br ${gradient} shadow-md`}
                    initial={{ rotate: -180, scale: 0 }}
                    animate={{ rotate: 0, scale: 1 }}
                    transition={{ type: "spring", duration: 0.5 }}
                  >
                    <ActiveIcon className="h-6 w-6 text-white" />
                  </motion.div>
                  <div>
                    <motion.h3 
                      initial={{ opacity: 0, x: -20 }}
                      animate={{ opacity: 1, x: 0 }}
                      transition={{ duration: 0.3 }}
                      className="text-base font-semibold text-gray-800 dark:text-gray-200 flex items-center gap-2 mb-1"
                    >
                      {displayName}
                      {stats.is_verified && (
                        <motion.span
                          initial={{ scale: 0 }}
                          animate={{ scale: 1 }}
                          transition={{ type: "spring", duration: 0.5 }}
                        >
                          <FaCheckCircle className="text-[#3897f0] h-4 w-4" />
                        </motion.span>
                      )}
                      {stats.is_private && (
                        <motion.span
                          initial={{ scale: 0 }}
                          animate={{ scale: 1 }}
                          transition={{ type: "spring", duration: 0.5 }}
                        >
                          <FaLock className="text-gray-500 h-3.5 w-3.5" />
                        </motion.span>
                      )}
                    </motion.h3>
                    <motion.div 
                      initial={{ opacity: 0, y: 10 }}
                      animate={{ opacity: 1, y: 0 }}
                      transition={{ duration: 0.3, delay: 0.1 }}
                      className="flex items-center gap-2"
                    >
                      <span className="text-sm text-gray-600 dark:text-gray-400">
                        @{username}
                      </span>
                      {activePlatform === 'twitter' && stats.extra_data?.location && (
                        <span className="flex items-center gap-1 text-sm text-gray-500 dark:text-gray-400">
                          <FaMapMarkerAlt className="h-3.5 w-3.5" />
                          {stats.extra_data.location}
                        </span>
                      )}
                    </motion.div>
                  </div>
                </div>
                <motion.a
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  initial={{ opacity: 0, x: 20 }}
                  animate={{ opacity: 1, x: 0 }}
                  transition={{ duration: 0.3 }}
                  href={profileUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                  className={`group flex items-center gap-2 px-4 py-2 rounded-xl bg-gradient-to-r ${gradient} text-white font-medium text-sm hover:shadow-lg transition-all duration-300`}
                >
                  Visit
                  <FaExternalLinkAlt className="h-3 w-3 transform group-hover:translate-x-0.5 group-hover:-translate-y-0.5 transition-transform duration-300" />
                </motion.a>
              </div>

              {/* Stats Grid */}
              <div className="grid grid-cols-3 gap-4">
                {getStatsConfig().map((stat, index) => (
                  <motion.div
                    key={index}
                    custom={index}
                    variants={statsVariants}
                    initial="hidden"
                    animate="visible"
                    whileHover={{ scale: 1.02 }}
                    className="bg-gradient-to-br from-gray-50 to-gray-100 dark:from-gray-800 dark:to-gray-900 rounded-xl p-4 text-center shadow-sm hover:shadow-md transition-all duration-300"
                  >
                    <motion.div 
                      initial={{ scale: 0 }}
                      animate={{ scale: 1 }}
                      transition={{ type: "spring", duration: 0.5, delay: index * 0.1 }}
                      className={`text-xl font-bold bg-gradient-to-r ${gradient} bg-clip-text text-transparent mb-1`}
                    >
                      {formatNumber(stat.value)}
                    </motion.div>
                    <motion.div 
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      transition={{ duration: 0.3, delay: 0.2 + index * 0.1 }}
                      className="text-sm text-gray-600 dark:text-gray-400 font-medium"
                    >
                      {stat.label}
                    </motion.div>
                  </motion.div>
                ))}
              </div>
            </motion.div>
          </AnimatePresence>
        </div>
      </div>
    </motion.div>
  );
};

export default SocialStatsModule;
