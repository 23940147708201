import React from 'react';
import { createPortal } from 'react-dom';
import { FaTimes } from 'react-icons/fa';

const Modal = ({ isOpen, onClose, title, children }) => {
  if (!isOpen) return null;

  // Create portal to render modal at the root level, outside any nested contexts
  return createPortal(
    <div className="fixed inset-0 z-[9999] overflow-y-auto md:overflow-hidden">
      {/* Backdrop - hidden on mobile for full-screen experience */}
      <div 
        className="fixed inset-0 bg-black bg-opacity-50 transition-opacity hidden md:block"
        onClick={onClose}
      />
      
      {/* Modal */}
      <div className="flex items-start md:items-center justify-center min-h-screen">
        <div className="relative bg-white dark:bg-gray-800 w-full min-h-screen md:min-h-0 md:rounded-lg md:shadow-xl md:max-w-2xl md:w-full md:mx-auto md:my-8">
          {/* Header - sticky on mobile */}
          <div className="sticky top-0 z-10 flex items-center justify-between p-4 bg-white dark:bg-gray-800 border-b dark:border-gray-700">
            <h3 className="text-lg font-semibold text-gray-800 dark:text-white">
              {title}
            </h3>
            <button
              onClick={onClose}
              className="p-2 -mr-2 text-gray-500 hover:text-gray-700 dark:hover:text-gray-300 transition-colors"
            >
              <FaTimes className="w-6 h-6" />
            </button>
          </div>

          {/* Content - scrollable on mobile */}
          <div className="p-4 md:p-6 overflow-y-auto" style={{ maxHeight: 'calc(100vh - 64px)' }}>
            {children}
          </div>

          {/* Optional bottom padding for mobile scrolling */}
          <div className="h-6 md:h-0" />
        </div>
      </div>
    </div>,
    document.body // Render directly to body element
  );
};

export default Modal;
