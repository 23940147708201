import React from 'react';
import { motion } from 'framer-motion';
import { 
  FaGithub, 
  FaLinkedin, 
  FaTwitter, 
  FaGlobe, 
  FaInstagram, 
  FaYoutube, 
  FaFacebook, 
  FaTiktok, 
  FaMedium, 
  FaReddit, 
  FaSoundcloud,
  FaPlus,
  FaTrash
} from 'react-icons/fa';

const ProfileSocialLinks = ({ socialLinks, isEditing, onAdd, onUpdate, onRemove }) => {
  const fadeInUp = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 }
  };

  const staggerContainer = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1
      }
    }
  };

  const getSocialIcon = (url) => {
    if (!url) return { icon: FaGlobe, color: 'text-gray-400 hover:text-white' };
    
    try {
      const domain = new URL(url).hostname.toLowerCase();
      
      const socialIcons = {
        'github.com': { icon: FaGithub, color: 'text-gray-400 hover:text-white' },
        'linkedin.com': { icon: FaLinkedin, color: 'text-gray-400 hover:text-[#0A66C2]' },
        'twitter.com': { icon: FaTwitter, color: 'text-gray-400 hover:text-[#1DA1F2]' },
        'instagram.com': { icon: FaInstagram, color: 'text-gray-400 hover:text-[#E4405F]' },
        'youtube.com': { icon: FaYoutube, color: 'text-gray-400 hover:text-[#FF0000]' },
        'facebook.com': { icon: FaFacebook, color: 'text-gray-400 hover:text-[#1877F2]' },
        'tiktok.com': { icon: FaTiktok, color: 'text-gray-400 hover:text-white' },
        'medium.com': { icon: FaMedium, color: 'text-gray-400 hover:text-white' },
        'reddit.com': { icon: FaReddit, color: 'text-gray-400 hover:text-[#FF4500]' },
        'soundcloud.com': { icon: FaSoundcloud, color: 'text-gray-400 hover:text-[#FF3300]' }
      };

      for (const [key, value] of Object.entries(socialIcons)) {
        if (domain.includes(key)) return value;
      }

      return { icon: FaGlobe, color: 'text-gray-400 hover:text-white' };
    } catch {
      return { icon: FaGlobe, color: 'text-gray-400 hover:text-white' };
    }
  };

  if (isEditing) {
    return (
      <div className="space-y-4">
        <h3 className="text-lg font-semibold text-white mb-4">
          Social Links
        </h3>
        
        {/* Social Links Editor */}
        <div className="space-y-3">
          {socialLinks?.map((social, index) => (
            <div key={index} className="flex items-center gap-2">
              <input
                type="text"
                value={social.url || ''}
                onChange={(e) => onUpdate(index, 'url', e.target.value)}
                placeholder="Enter social media URL"
                className="flex-grow px-3 py-2 bg-gray-800 border border-gray-700 rounded-lg text-white placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
              <button
                onClick={() => onRemove(index)}
                className="p-2 text-red-400 hover:text-red-300 transition-colors"
                title="Remove link"
              >
                <FaTrash className="w-5 h-5" />
              </button>
            </div>
          ))}
          
          {/* Add Link Button */}
          {socialLinks?.length < 4 && (
            <button
              onClick={onAdd}
              className="w-full flex items-center justify-center gap-2 px-4 py-2 text-sm font-medium text-blue-400 border border-blue-400/50 rounded-lg hover:bg-blue-400/10 transition-all duration-200"
            >
              <FaPlus className="w-4 h-4" />
              Add Social Link
            </button>
          )}
        </div>
      </div>
    );
  }

  if (!socialLinks?.length) return null;

  return (
    <motion.div 
      variants={staggerContainer}
      className="flex flex-wrap justify-center gap-4"
    >
      {socialLinks.map((social, index) => {
        if (!social?.url) return null;
        const { icon: Icon, color } = getSocialIcon(social.url);
        return (
          <motion.a
            key={index}
            variants={fadeInUp}
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.95 }}
            href={social.url}
            target="_blank"
            rel="noopener noreferrer"
            className={`p-3 rounded-xl bg-gray-800/50 backdrop-blur-sm transition-all duration-200 hover:shadow-lg hover:shadow-blue-500/10 ${color}`}
          >
            <Icon className="w-5 h-5" />
          </motion.a>
        );
      })}
    </motion.div>
  );
};

export default ProfileSocialLinks;
