import React, { useState } from 'react';
import { FaPlus, FaEdit, FaTrash, FaExternalLinkAlt } from 'react-icons/fa';
import Modal from '../common/Modal';

const WorkExperienceForm = ({ experience, onSave, onCancel }) => {
  const [formData, setFormData] = useState(experience);

  const handleChange = (field, value) => {
    setFormData(prev => ({
      ...prev,
      [field]: value
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSave(formData);
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-4">
      <div>
        <label className="block text-sm font-medium text-gray-600 dark:text-gray-400 mb-1">
          Company Name
        </label>
        <input
          type="text"
          value={formData.company}
          onChange={(e) => handleChange('company', e.target.value)}
          placeholder="Enter company name"
          className="w-full px-3 py-2 border rounded-lg dark:bg-gray-700 dark:border-gray-600 focus:ring-2 focus:ring-blue-500 focus:border-transparent"
          required
        />
      </div>
      
      <div>
        <label className="block text-sm font-medium text-gray-600 dark:text-gray-400 mb-1">
          Company Website (optional)
        </label>
        <input
          type="url"
          value={formData.companyLink || ''}
          onChange={(e) => handleChange('companyLink', e.target.value)}
          placeholder="https://example.com"
          className="w-full px-3 py-2 border rounded-lg dark:bg-gray-700 dark:border-gray-600 focus:ring-2 focus:ring-blue-500 focus:border-transparent"
        />
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-600 dark:text-gray-400 mb-1">
          Position
        </label>
        <input
          type="text"
          value={formData.position}
          onChange={(e) => handleChange('position', e.target.value)}
          placeholder="Enter your position"
          className="w-full px-3 py-2 border rounded-lg dark:bg-gray-700 dark:border-gray-600 focus:ring-2 focus:ring-blue-500 focus:border-transparent"
          required
        />
      </div>

      <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
        <div>
          <label className="block text-sm font-medium text-gray-600 dark:text-gray-400 mb-1">
            Start Date
          </label>
          <input
            type="date"
            value={formData.startDate}
            onChange={(e) => handleChange('startDate', e.target.value)}
            className="w-full px-3 py-2 border rounded-lg dark:bg-gray-700 dark:border-gray-600 focus:ring-2 focus:ring-blue-500 focus:border-transparent"
            required
          />
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-600 dark:text-gray-400 mb-1">
            End Date
          </label>
          <input
            type="date"
            value={formData.endDate}
            onChange={(e) => handleChange('endDate', e.target.value)}
            className="w-full px-3 py-2 border rounded-lg dark:bg-gray-700 dark:border-gray-600 focus:ring-2 focus:ring-blue-500 focus:border-transparent"
          />
        </div>
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-600 dark:text-gray-400 mb-1">
          Responsibilities & Achievements
        </label>
        <textarea
          value={formData.responsibilities}
          onChange={(e) => handleChange('responsibilities', e.target.value)}
          placeholder="Describe your key responsibilities and achievements"
          rows="4"
          className="w-full px-3 py-2 border rounded-lg dark:bg-gray-700 dark:border-gray-600 focus:ring-2 focus:ring-blue-500 focus:border-transparent resize-y"
          required
        />
      </div>

      <div className="flex flex-col sm:flex-row justify-end space-y-2 sm:space-y-0 sm:space-x-2 pt-2">
        <button
          type="button"
          onClick={onCancel}
          className="w-full sm:w-auto px-4 py-2 bg-gray-500 text-white rounded-lg hover:bg-gray-600 transition-colors duration-200"
        >
          Cancel
        </button>
        <button
          type="submit"
          className="w-full sm:w-auto px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition-colors duration-200"
        >
          Save
        </button>
      </div>
    </form>
  );
};

const WorkExperienceEntry = ({ experience, onEdit, onDelete, readOnly }) => {
  return (
    <div className="bg-white dark:bg-gray-800 p-4 rounded-lg shadow mb-4">
      <div className="relative">
        <div className="pr-12">
          <h3 className="text-lg font-semibold text-gray-800 dark:text-white mb-1">
            {experience.position}
          </h3>
          <div className="flex items-center gap-2 mb-1">
            <p className="text-gray-600 dark:text-gray-300">{experience.company}</p>
            {experience.companyLink && (
              <a
                href={experience.companyLink}
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-500 hover:text-blue-600 inline-flex items-center"
              >
                <FaExternalLinkAlt className="h-3 w-3" />
              </a>
            )}
          </div>
          <p className="text-sm text-gray-500 dark:text-gray-400 mb-2">
            {experience.startDate} - {experience.endDate || 'Present'}
          </p>
          <p className="text-gray-700 dark:text-gray-300 whitespace-pre-line">
            {experience.responsibilities}
          </p>
        </div>

        {!readOnly && (
          <div className="absolute top-0 right-0 flex flex-col space-y-2">
            <button
              onClick={() => onEdit(experience)}
              className="p-2 text-blue-500 hover:text-blue-600 transition-colors duration-200"
              aria-label="Edit"
            >
              <FaEdit />
            </button>
            <button
              onClick={() => onDelete(experience.id)}
              className="p-2 text-red-500 hover:text-red-600 transition-colors duration-200"
              aria-label="Delete"
            >
              <FaTrash />
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

const WorkHistory = ({ workHistory, setWorkHistory, readOnly = false }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentExperience, setCurrentExperience] = useState(null);

  const addNewExperience = () => {
    const newExperience = {
      id: Date.now(),
      company: '',
      companyLink: '',
      position: '',
      startDate: '',
      endDate: '',
      responsibilities: ''
    };
    setCurrentExperience(newExperience);
    setIsModalOpen(true);
  };

  const handleSave = (savedExperience) => {
    if (workHistory.find(exp => exp.id === savedExperience.id)) {
      setWorkHistory(workHistory.map(exp => 
        exp.id === savedExperience.id ? savedExperience : exp
      ));
    } else {
      setWorkHistory([...workHistory, savedExperience]);
    }
    setIsModalOpen(false);
  };

  const handleEdit = (experience) => {
    setCurrentExperience(experience);
    setIsModalOpen(true);
  };

  const handleDelete = (id) => {
    setWorkHistory(workHistory.filter(exp => exp.id !== id));
  };

  return (
    <div className="max-w-4xl mx-auto p-4">
      <div className="flex flex-col sm:flex-row justify-between items-center mb-6 space-y-4 sm:space-y-0">
        <h2 className="text-xl sm:text-2xl font-bold text-gray-800 dark:text-white">
          Experience
        </h2>
        {!readOnly && (
          <button
            onClick={addNewExperience}
            className="w-full sm:w-auto px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition-colors duration-200 flex items-center justify-center"
          >
            <FaPlus className="mr-2" />
            <span>Add Experience</span>
          </button>
        )}
      </div>
      <div className="space-y-4">
        {workHistory.map(experience => (
          <WorkExperienceEntry
            key={experience.id}
            experience={experience}
            onEdit={handleEdit}
            onDelete={handleDelete}
            readOnly={readOnly}
          />
        ))}
        {workHistory.length === 0 && (
          <div className="text-center py-8 text-gray-500 dark:text-gray-400">
            {readOnly ? 'No work experience available.' : 'No work experience added yet. Click "Add Experience" to get started.'}
          </div>
        )}
      </div>

      <Modal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        title={currentExperience?.id ? 'Edit Experience' : 'Add Experience'}
      >
        {currentExperience && (
          <WorkExperienceForm
            experience={currentExperience}
            onSave={handleSave}
            onCancel={() => setIsModalOpen(false)}
          />
        )}
      </Modal>
    </div>
  );
};

export default WorkHistory;
