import React, { useState } from 'react';
import { FaPlus, FaEdit, FaTrash, FaStar } from 'react-icons/fa';
import Modal from '../common/Modal';

const RatingStars = ({ rating, onChange, interactive = false }) => {
  const [hoverRating, setHoverRating] = useState(0);

  const handleStarClick = (index) => {
    if (interactive && onChange) {
      onChange(index + 1);
    }
  };

  const handleStarHover = (index) => {
    if (interactive) {
      setHoverRating(index + 1);
    }
  };

  return (
    <div className="flex justify-center gap-1">
      {[...Array(5)].map((_, index) => (
        <button
          key={index}
          type={interactive ? "button" : "div"}
          onClick={() => handleStarClick(index)}
          onMouseEnter={() => handleStarHover(index)}
          onMouseLeave={() => setHoverRating(0)}
          className={`
            ${interactive ? 'cursor-pointer focus:outline-none focus:ring-2 focus:ring-blue-500 rounded-full p-1 -m-1' : ''}
            ${index < (hoverRating || rating) ? 'text-yellow-400' : 'text-gray-300 dark:text-gray-600'}
            transition-colors duration-150
          `}
          aria-label={`${index + 1} star${index !== 0 ? 's' : ''}`}
        >
          <FaStar className="w-6 h-6 sm:w-5 sm:h-5" />
        </button>
      ))}
    </div>
  );
};

const SkillForm = ({ skill, onSave, onCancel }) => {
  const [formData, setFormData] = useState(skill);

  const handleChange = (field, value) => {
    setFormData(prev => ({
      ...prev,
      [field]: value
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSave(formData);
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-4">
      <div>
        <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
          Skill Name
        </label>
        <input
          type="text"
          value={formData.name}
          onChange={(e) => handleChange('name', e.target.value)}
          placeholder="Enter skill name"
          className="w-full px-3 py-2 border rounded-lg dark:bg-gray-700 dark:border-gray-600 focus:ring-2 focus:ring-blue-500 focus:border-transparent"
          required
        />
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
          Category
        </label>
        <input
          type="text"
          value={formData.category}
          onChange={(e) => handleChange('category', e.target.value)}
          placeholder="e.g., Programming Languages, Tools"
          className="w-full px-3 py-2 border rounded-lg dark:bg-gray-700 dark:border-gray-600 focus:ring-2 focus:ring-blue-500 focus:border-transparent"
          required
        />
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
          Proficiency Level
        </label>
        <RatingStars 
          rating={formData.rating} 
          onChange={(rating) => handleChange('rating', rating)}
          interactive={true}
        />
      </div>

      <div className="flex flex-col sm:flex-row justify-end space-y-2 sm:space-y-0 sm:space-x-2 pt-2">
        <button
          type="button"
          onClick={onCancel}
          className="w-full sm:w-auto px-4 py-2 bg-gray-500 text-white rounded-lg hover:bg-gray-600 transition-colors duration-200"
        >
          Cancel
        </button>
        <button
          type="submit"
          className="w-full sm:w-auto px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition-colors duration-200"
        >
          Save
        </button>
      </div>
    </form>
  );
};

const SkillEntry = ({ skill, onEdit, onDelete, readOnly }) => {
  return (
    <div className="bg-white dark:bg-gray-800 p-4 sm:p-5 rounded-lg shadow-sm mb-4">
      <div className="relative">
        <div className="pr-12">
          <h3 className="text-lg font-semibold text-gray-800 dark:text-white break-words">
            {skill.name}
          </h3>
          <p className="text-sm text-gray-500 dark:text-gray-400 mt-1 mb-2">
            {skill.category}
          </p>
          <div className="mt-2">
            <RatingStars rating={skill.rating} />
          </div>
        </div>

        {!readOnly && (
          <div className="absolute top-0 right-0 flex flex-col space-y-2">
            <button
              onClick={() => onEdit(skill)}
              className="p-2 text-blue-500 hover:text-blue-600 transition-colors duration-200"
              aria-label="Edit"
            >
              <FaEdit />
            </button>
            <button
              onClick={() => onDelete(skill.id)}
              className="p-2 text-red-500 hover:text-red-600 transition-colors duration-200"
              aria-label="Delete"
            >
              <FaTrash />
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

const Skills = ({ skills, setSkills, readOnly = false }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentSkill, setCurrentSkill] = useState(null);

  const addNewSkill = () => {
    const newSkill = {
      id: Date.now(),
      name: '',
      category: '',
      rating: 0
    };
    setCurrentSkill(newSkill);
    setIsModalOpen(true);
  };

  const handleSave = (savedSkill) => {
    if (skills.find(skill => skill.id === savedSkill.id)) {
      setSkills(skills.map(skill => 
        skill.id === savedSkill.id ? savedSkill : skill
      ));
    } else {
      setSkills([...skills, savedSkill]);
    }
    setIsModalOpen(false);
  };

  const handleEdit = (skill) => {
    setCurrentSkill(skill);
    setIsModalOpen(true);
  };

  const handleDelete = (id) => {
    setSkills(skills.filter(skill => skill.id !== id));
  };

  // Group skills by category
  const groupedSkills = skills.reduce((acc, skill) => {
    const category = skill.category || 'Uncategorized';
    if (!acc[category]) {
      acc[category] = [];
    }
    acc[category].push(skill);
    return acc;
  }, {});

  return (
    <div className="max-w-4xl mx-auto p-4">
      <div className="flex flex-col sm:flex-row justify-between items-center mb-6 space-y-4 sm:space-y-0">
        <h2 className="text-xl sm:text-2xl font-bold text-gray-800 dark:text-white">
          Skills
        </h2>
        {!readOnly && (
          <button
            onClick={addNewSkill}
            className="w-full sm:w-auto px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition-colors duration-200 flex items-center justify-center"
          >
            <FaPlus className="mr-2" />
            <span>Add Skill</span>
          </button>
        )}
      </div>

      {skills.length === 0 ? (
        <div className="text-center py-8 text-gray-500 dark:text-gray-400">
          {readOnly ? 'No skills available.' : 'No skills added yet. Click "Add Skill" to get started.'}
        </div>
      ) : (
        Object.entries(groupedSkills).map(([category, categorySkills]) => (
          <div key={category} className="mb-8">
            <h3 className="text-lg font-semibold text-gray-700 dark:text-gray-300 mb-4 px-1">
              {category}
            </h3>
            <div className="space-y-4">
              {categorySkills.map(skill => (
                <SkillEntry
                  key={skill.id}
                  skill={skill}
                  onEdit={handleEdit}
                  onDelete={handleDelete}
                  readOnly={readOnly}
                />
              ))}
            </div>
          </div>
        ))
      )}

      <Modal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        title={currentSkill?.id ? 'Edit Skill' : 'Add Skill'}
      >
        {currentSkill && (
          <SkillForm
            skill={currentSkill}
            onSave={handleSave}
            onCancel={() => setIsModalOpen(false)}
          />
        )}
      </Modal>
    </div>
  );
};

export default Skills;
