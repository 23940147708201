import React from 'react';
import { motion } from 'framer-motion';
import { HiCash } from 'react-icons/hi';
import { FaDollarSign } from 'react-icons/fa';

const ProfilePaymentLinks = ({ paymentLinks }) => {
  if (!paymentLinks?.length) return null;

  const fadeInUp = {
    hidden: { opacity: 0, y: 20 },
    visible: { 
      opacity: 1, 
      y: 0,
      transition: {
        duration: 0.6,
        ease: "easeOut"
      }
    }
  };

  const staggerContainer = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.15,
        delayChildren: 0.2
      }
    }
  };

  const shimmer = {
    hidden: {
      backgroundPosition: "200% 0",
    },
    visible: {
      backgroundPosition: "-200% 0",
      transition: {
        duration: 8,
        ease: "linear",
        repeat: Infinity
      }
    }
  };

  const float = {
    initial: { y: 0 },
    animate: {
      y: [-4, 4, -4],
      transition: {
        duration: 3,
        repeat: Infinity,
        ease: "easeInOut"
      }
    }
  };

  const cardHover = {
    rest: {
      scale: 1,
      boxShadow: "0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)"
    },
    hover: {
      scale: 1.03,
      boxShadow: "0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04)",
      transition: {
        type: "spring",
        stiffness: 400,
        damping: 17
      }
    }
  };

  const iconHover = {
    rest: { scale: 1 },
    hover: { 
      scale: 1.1,
      rotate: [0, -10, 10, -10, 10, 0],
      transition: {
        rotate: {
          duration: 0.5,
          ease: "easeInOut"
        }
      }
    }
  };

  const getPaymentIcon = () => {
    return { icon: FaDollarSign, color: 'text-emerald-400' };
  };

  const getPlatformUrl = (platform, username) => {
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    const platformMap = {
      cashapp: {
        web: `https://cash.app/$${username}`,
        mobile: `https://cash.app/$${username}`
      },
      venmo: {
        web: `https://venmo.com/${username}`,
        mobile: `venmo://users/${username}`
      },
      paypal: {
        web: `https://paypal.me/${username}`,
        mobile: `https://paypal.me/${username}`
      },
      zelle: {
        web: `#`, // Zelle doesn't support direct links
        mobile: `#`
      }
    };

    const normalizedPlatform = platform.toLowerCase().replace(/\s+/g, '');
    const platformUrls = platformMap[normalizedPlatform] || {
      web: `https://${platform}.com/${username}`,
      mobile: `https://${platform}.com/${username}`
    };

    return isMobile ? platformUrls.mobile : platformUrls.web;
  };

  return (
    <motion.div 
      initial="hidden"
      animate="visible"
      variants={fadeInUp}
      className="mt-8 rounded-2xl shadow-lg overflow-hidden relative"
    >
      {/* Animated gradient background */}
      <motion.div
        variants={shimmer}
        initial="hidden"
        animate="visible"
        className="absolute inset-0 bg-gradient-to-r from-transparent via-emerald-300/10 to-transparent dark:via-emerald-400/5"
        style={{
          backgroundSize: "200% 100%"
        }}
      />
      
      <div className="relative p-6 bg-gradient-to-br from-emerald-500/10 via-emerald-400/5 to-emerald-300/10 dark:from-emerald-900/20 dark:via-emerald-800/15 dark:to-emerald-700/20 backdrop-blur-sm">
        <motion.div 
          variants={float}
          initial="initial"
          animate="animate"
          className="mb-6"
        >
          <motion.h2 
            variants={fadeInUp}
            className="text-xl font-bold text-gray-900 dark:text-white flex items-center gap-2"
          >
            <motion.div
              whileHover={{ rotate: [0, -10, 10, -10, 0], transition: { duration: 0.5 } }}
            >
              <HiCash className="w-6 h-6 text-emerald-500 dark:text-emerald-400" />
            </motion.div>
            Payment Links
          </motion.h2>
        </motion.div>
        
        <motion.div 
          variants={staggerContainer}
          className="grid gap-4"
        >
          {paymentLinks.map((link, index) => {
            const { icon: Icon, color } = getPaymentIcon();
            const platformUrl = getPlatformUrl(link.platform, link.username);
            
            // Don't make Zelle entries clickable since they don't support direct links
            const isZelle = link.platform.toLowerCase() === 'zelle';
            const Component = isZelle ? motion.div : motion.a;
            const props = isZelle ? {} : {
              href: platformUrl,
              target: "_blank",
              rel: "noopener noreferrer"
            };

            return (
              <Component
                key={index}
                variants={cardHover}
                initial="rest"
                whileHover="hover"
                {...props}
                className="flex items-center gap-4 p-4 bg-white/50 dark:bg-gray-800/50 rounded-xl transition-all duration-200 backdrop-blur-sm border border-emerald-200/20 dark:border-emerald-700/20 group"
              >
                <motion.div 
                  variants={iconHover}
                  className={`w-12 h-12 rounded-lg bg-gradient-to-br from-emerald-400 to-emerald-500 dark:from-emerald-500 dark:to-emerald-600 flex items-center justify-center shadow-lg shadow-emerald-500/20 dark:shadow-emerald-900/30`}
                >
                  <Icon className="w-6 h-6 text-white" />
                </motion.div>
                <div>
                  <h3 className="font-medium text-gray-900 dark:text-white capitalize transform transition-transform group-hover:translate-x-1">
                    {link.platform}
                  </h3>
                  <p className="text-sm text-emerald-600 dark:text-emerald-400 transform transition-transform group-hover:translate-x-1">
                    @{link.username}
                  </p>
                </div>
              </Component>
            );
          })}
        </motion.div>
      </div>
    </motion.div>
  );
};

export default ProfilePaymentLinks;
