import React, { useState } from 'react';
import { FaPlus, FaEdit, FaTrash, FaExternalLinkAlt } from 'react-icons/fa';
import Modal from '../common/Modal';

const ProjectForm = ({ project, onSave, onCancel }) => {
  const [formData, setFormData] = useState(project);

  const handleChange = (field, value) => {
    setFormData(prev => ({
      ...prev,
      [field]: value
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSave(formData);
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-4">
      <div>
        <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
          Project Title
        </label>
        <input
          type="text"
          value={formData.title}
          onChange={(e) => handleChange('title', e.target.value)}
          placeholder="Enter project title"
          className="w-full px-3 py-2 border rounded-lg dark:bg-gray-700 dark:border-gray-600 focus:ring-2 focus:ring-blue-500 focus:border-transparent"
          required
        />
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
          Project Description
        </label>
        <textarea
          value={formData.description}
          onChange={(e) => handleChange('description', e.target.value)}
          placeholder="Describe your project"
          rows="4"
          className="w-full px-3 py-2 border rounded-lg dark:bg-gray-700 dark:border-gray-600 focus:ring-2 focus:ring-blue-500 focus:border-transparent resize-y"
          required
        />
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
          Technologies Used
        </label>
        <input
          type="text"
          value={formData.technologies}
          onChange={(e) => handleChange('technologies', e.target.value)}
          placeholder="e.g., React, Node.js, MongoDB"
          className="w-full px-3 py-2 border rounded-lg dark:bg-gray-700 dark:border-gray-600 focus:ring-2 focus:ring-blue-500 focus:border-transparent"
          required
        />
        <p className="mt-1 text-sm text-gray-500 dark:text-gray-400">
          Separate technologies with commas
        </p>
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
          Project Link
        </label>
        <input
          type="url"
          value={formData.link}
          onChange={(e) => handleChange('link', e.target.value)}
          placeholder="https://example.com"
          className="w-full px-3 py-2 border rounded-lg dark:bg-gray-700 dark:border-gray-600 focus:ring-2 focus:ring-blue-500 focus:border-transparent"
        />
      </div>

      <div className="flex flex-col sm:flex-row justify-end space-y-2 sm:space-y-0 sm:space-x-2 pt-2">
        <button
          type="button"
          onClick={onCancel}
          className="w-full sm:w-auto px-4 py-2 bg-gray-500 text-white rounded-lg hover:bg-gray-600 transition-colors duration-200"
        >
          Cancel
        </button>
        <button
          type="submit"
          className="w-full sm:w-auto px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition-colors duration-200"
        >
          Save
        </button>
      </div>
    </form>
  );
};

const ProjectEntry = ({ project, onEdit, onDelete, readOnly }) => {
  return (
    <div className="bg-white dark:bg-gray-800 p-4 sm:p-5 rounded-lg shadow-sm mb-4">
      <div className="relative">
        <div className="pr-12">
          <h3 className="text-lg font-semibold text-gray-800 dark:text-white break-words">
            {project.title}
          </h3>
          <p className="mt-2 text-gray-700 dark:text-gray-300 text-sm sm:text-base leading-relaxed break-words">
            {project.description}
          </p>
          <div className="mt-3 flex flex-wrap gap-2">
            {(project.technologies || '').split(',')
              .map(tech => tech.trim())
              .filter(tech => tech.length > 0)
              .map((tech, index) => (
                <span 
                  key={index}
                  className="inline-block bg-blue-100 dark:bg-blue-900 text-blue-800 dark:text-blue-200 
                           rounded-full px-3 py-1.5 text-sm font-medium"
                >
                  {tech}
                </span>
              ))}
          </div>
          {project.link && (
            <a 
              href={project.link}
              target="_blank"
              rel="noopener noreferrer"
              className="mt-3 inline-flex items-center text-blue-500 hover:text-blue-600 text-sm font-medium 
                       transition-colors duration-200"
            >
              View Project
              <FaExternalLinkAlt className="ml-1 h-3 w-3" />
            </a>
          )}
        </div>

        {!readOnly && (
          <div className="absolute top-0 right-0 flex flex-col space-y-2">
            <button
              onClick={() => onEdit(project)}
              className="p-2 text-blue-500 hover:text-blue-600 transition-colors duration-200"
              aria-label="Edit project"
            >
              <FaEdit />
            </button>
            <button
              onClick={() => onDelete(project.id)}
              className="p-2 text-red-500 hover:text-red-600 transition-colors duration-200"
              aria-label="Delete project"
            >
              <FaTrash />
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

const Projects = ({ projects, setProjects, readOnly = false }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentProject, setCurrentProject] = useState(null);

  const addNewProject = () => {
    const newProject = {
      id: Date.now(),
      title: '',
      description: '',
      technologies: '',
      link: ''
    };
    setCurrentProject(newProject);
    setIsModalOpen(true);
  };

  const handleSave = (savedProject) => {
    if (projects.find(proj => proj.id === savedProject.id)) {
      setProjects(projects.map(proj => 
        proj.id === savedProject.id ? savedProject : proj
      ));
    } else {
      setProjects([...projects, savedProject]);
    }
    setIsModalOpen(false);
  };

  const handleEdit = (project) => {
    setCurrentProject(project);
    setIsModalOpen(true);
  };

  const handleDelete = (id) => {
    setProjects(projects.filter(proj => proj.id !== id));
  };

  return (
    <div className="max-w-4xl mx-auto p-4">
      <div className="flex flex-col sm:flex-row justify-between items-center mb-6 space-y-4 sm:space-y-0">
        <h2 className="text-xl sm:text-2xl font-bold text-gray-800 dark:text-white">
          Projects
        </h2>
        {!readOnly && (
          <button
            onClick={addNewProject}
            className="w-full sm:w-auto px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 
                     transition-colors duration-200 flex items-center justify-center"
          >
            <FaPlus className="mr-2" />
            <span>Add Project</span>
          </button>
        )}
      </div>

      <div className="space-y-4">
        {projects.map(project => (
          <ProjectEntry
            key={project.id}
            project={project}
            onEdit={handleEdit}
            onDelete={handleDelete}
            readOnly={readOnly}
          />
        ))}
        {projects.length === 0 && (
          <div className="text-center py-8 text-gray-500 dark:text-gray-400">
            {readOnly ? 'No projects available.' : 'No projects added yet. Click "Add Project" to get started.'}
          </div>
        )}
      </div>

      <Modal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        title={currentProject?.id ? 'Edit Project' : 'Add Project'}
      >
        {currentProject && (
          <ProjectForm
            project={currentProject}
            onSave={handleSave}
            onCancel={() => setIsModalOpen(false)}
          />
        )}
      </Modal>
    </div>
  );
};

export default Projects;
