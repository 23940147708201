import React, { useState } from 'react';

const ThemeCustomizer = ({ currentTheme, onSave }) => {
  const patterns = [
    { name: 'None', value: 'none' },
    { name: 'Dots', value: 'dots' },
    { name: 'Lines', value: 'lines' },
    { name: 'Grid', value: 'grid' },
    { name: 'Diagonal', value: 'diagonal' }
  ];

  const [theme, setTheme] = useState(currentTheme || {
    primaryColor: '#4F46E5',
    secondaryColor: '#9333EA',
    gradientType: 'linear',
    gradientDirection: '45deg',
    gradientPosition: 'center',
    textColor: '#FFFFFF',
    pattern: 'none'
  });

  const handleColorChange = (field, value) => {
    setTheme(prev => ({
      ...prev,
      [field]: value
    }));
  };

  const handleDirectionChange = (event) => {
    setTheme(prev => ({
      ...prev,
      gradientDirection: event.target.value
    }));
  };

  const handleSave = () => {
    onSave(theme);
  };

  const getPatternBackground = (pattern) => {
    switch (pattern) {
      case 'dots':
        return `radial-gradient(circle at 1px 1px, rgba(255,255,255,0.15) 1px, transparent 0)`;
      case 'lines':
        return `linear-gradient(0deg, transparent 9px, rgba(255,255,255,0.15) 10px)`;
      case 'grid':
        return `linear-gradient(0deg, transparent 9px, rgba(255,255,255,0.15) 10px),
                linear-gradient(90deg, transparent 9px, rgba(255,255,255,0.15) 10px)`;
      case 'diagonal':
        return `linear-gradient(45deg, rgba(255,255,255,0.15) 25%, transparent 25%),
                linear-gradient(-45deg, rgba(255,255,255,0.15) 25%, transparent 25%)`;
      default:
        return 'none';
    }
  };

  const getPatternSize = (pattern) => {
    switch (pattern) {
      case 'dots':
        return '20px 20px';
      case 'lines':
        return '20px 20px';
      case 'grid':
        return '20px 20px';
      case 'diagonal':
        return '20px 20px';
      default:
        return 'auto';
    }
  };

  const gradientStyle = {
    background: `${theme.gradientType === 'linear'
      ? `linear-gradient(${theme.gradientDirection}, ${theme.primaryColor}, ${theme.secondaryColor})`
      : `radial-gradient(circle at ${theme.gradientPosition}, ${theme.primaryColor}, ${theme.secondaryColor})`}`,
    backgroundImage: `${getPatternBackground(theme.pattern)}, ${theme.gradientType === 'linear'
      ? `linear-gradient(${theme.gradientDirection}, ${theme.primaryColor}, ${theme.secondaryColor})`
      : `radial-gradient(circle at ${theme.gradientPosition}, ${theme.primaryColor}, ${theme.secondaryColor})`}`,
    backgroundSize: `${getPatternSize(theme.pattern)}, 100% 100%`,
    color: theme.textColor
  };

  return (
    <div className="bg-white dark:bg-gray-800 rounded-lg shadow p-4 space-y-4">
      <h3 className="text-lg font-semibold text-gray-900 dark:text-white mb-4">
        Theme Customization
      </h3>

      {/* Preview */}
      <div 
        className="w-full h-32 rounded-lg flex items-center justify-center mb-6 transition-all duration-300"
        style={gradientStyle}
      >
        <span className="text-lg font-semibold">Preview</span>
      </div>

      {/* Gradient Type and Direction Controls */}
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
        <div>
          <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
            Gradient Type
          </label>
          <select
            value={theme.gradientType}
            onChange={(e) => handleColorChange('gradientType', e.target.value)}
            className="w-full px-3 py-2 border rounded-md text-sm dark:bg-gray-700 dark:border-gray-600 dark:text-white"
          >
            <option value="linear">Linear Gradient</option>
            <option value="radial">Radial Gradient</option>
          </select>
        </div>

        {theme.gradientType === 'linear' ? (
          <div>
            <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
              Gradient Direction
            </label>
            <select
              value={theme.gradientDirection}
              onChange={handleDirectionChange}
              className="w-full px-3 py-2 border rounded-md text-sm dark:bg-gray-700 dark:border-gray-600 dark:text-white"
            >
              <option value="90deg">→ Left to Right</option>
              <option value="270deg">← Right to Left</option>
              <option value="180deg">↓ Top to Bottom</option>
              <option value="0deg">↑ Bottom to Top</option>
              <option value="135deg">↘ Top Left to Bottom Right</option>
              <option value="315deg">↖ Bottom Right to Top Left</option>
              <option value="225deg">↙ Top Right to Bottom Left</option>
              <option value="45deg">↗ Bottom Left to Top Right</option>
            </select>
          </div>
        ) : (
          <div>
            <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
              Gradient Position
            </label>
            <select
              value={theme.gradientPosition}
              onChange={(e) => handleColorChange('gradientPosition', e.target.value)}
              className="w-full px-3 py-2 border rounded-md text-sm dark:bg-gray-700 dark:border-gray-600 dark:text-white"
            >
              <option value="center">Center</option>
              <option value="top">Top</option>
              <option value="top right">Top Right</option>
              <option value="right">Right</option>
              <option value="bottom right">Bottom Right</option>
              <option value="bottom">Bottom</option>
              <option value="bottom left">Bottom Left</option>
              <option value="left">Left</option>
              <option value="top left">Top Left</option>
            </select>
          </div>
        )}
      </div>

      {/* Color Inputs */}
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
        <div>
          <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
            Primary Color
          </label>
          <div className="flex items-center space-x-2">
            <input
              type="color"
              value={theme.primaryColor}
              onChange={(e) => handleColorChange('primaryColor', e.target.value)}
              className="h-10 w-20 rounded cursor-pointer"
            />
            <input
              type="text"
              value={theme.primaryColor}
              onChange={(e) => handleColorChange('primaryColor', e.target.value)}
              className="flex-1 px-3 py-2 border rounded-md text-sm dark:bg-gray-700 dark:border-gray-600 dark:text-white"
            />
          </div>
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
            Secondary Color
          </label>
          <div className="flex items-center space-x-2">
            <input
              type="color"
              value={theme.secondaryColor}
              onChange={(e) => handleColorChange('secondaryColor', e.target.value)}
              className="h-10 w-20 rounded cursor-pointer"
            />
            <input
              type="text"
              value={theme.secondaryColor}
              onChange={(e) => handleColorChange('secondaryColor', e.target.value)}
              className="flex-1 px-3 py-2 border rounded-md text-sm dark:bg-gray-700 dark:border-gray-600 dark:text-white"
            />
          </div>
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
            Text Color
          </label>
          <div className="flex items-center space-x-2">
            <input
              type="color"
              value={theme.textColor}
              onChange={(e) => handleColorChange('textColor', e.target.value)}
              className="h-10 w-20 rounded cursor-pointer"
            />
            <input
              type="text"
              value={theme.textColor}
              onChange={(e) => handleColorChange('textColor', e.target.value)}
              className="flex-1 px-3 py-2 border rounded-md text-sm dark:bg-gray-700 dark:border-gray-600 dark:text-white"
            />
          </div>
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
            Background Pattern
          </label>
          <select
            value={theme.pattern}
            onChange={(e) => handleColorChange('pattern', e.target.value)}
            className="w-full px-3 py-2 border rounded-md text-sm dark:bg-gray-700 dark:border-gray-600 dark:text-white"
          >
            {patterns.map(pattern => (
              <option key={pattern.value} value={pattern.value}>
                {pattern.name}
              </option>
            ))}
          </select>
        </div>

      </div>

      {/* Save Button */}
      <button
        onClick={handleSave}
        className="w-full mt-4 px-4 py-2 bg-blue-600 hover:bg-blue-700 text-white rounded-md transition-colors duration-200"
      >
        Save Theme
      </button>
    </div>
  );
};

export default ThemeCustomizer;