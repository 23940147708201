import React, { useState, useEffect } from 'react';
import { FaInstagram, FaSoundcloud, FaTwitter, FaYoutube, FaExternalLinkAlt, FaChartBar } from 'react-icons/fa';
import LoadingSpinner from '../LoadingSpinner';
import Modal from '../common/Modal';
import { socialStatsService } from '../../services/firestore';
import { scrapeInstagramStats } from '../../services/instagramScraper';
import { scrapeYoutubeStats } from '../../services/youtubeScraper';
import { scrapeTwitterStats } from '../../services/twitterScraper';
import { scrapeSoundcloudStats } from '../../services/soundcloudScraper';
import { useModules } from '../../contexts/ModulesContext';
import { useAuth } from '../../contexts/AuthContext';

const platformIcons = {
  instagram: FaInstagram,
  soundcloud: FaSoundcloud,
  twitter: FaTwitter,
  youtube: FaYoutube
};

const platformColors = {
  instagram: 'text-pink-600',
  soundcloud: 'text-orange-500',
  twitter: 'text-black',
  youtube: 'text-red-600'
};

const platformScrapers = {
  instagram: scrapeInstagramStats,
  soundcloud: scrapeSoundcloudStats,
  twitter: scrapeTwitterStats,
  youtube: scrapeYoutubeStats
};

const platformLabels = {
  instagram: 'Instagram Username or Profile URL',
  soundcloud: 'SoundCloud Username or Profile URL',
  twitter: 'X Username or Profile URL',
  youtube: 'YouTube Username or Channel URL'
};

const platformPlaceholders = {
  instagram: 'Handle or URL',
  soundcloud: 'Handle or URL',
  twitter: 'Handle or URL',
  youtube: 'Handle or URL'
};

// Helper functions to extract usernames from profile URLs
const extractUsername = (input, platform) => {
  if (!input) return '';
  
  // If it's already just a username (no slashes or dots), return as is
  if (!input.includes('/') && !input.includes('.')) {
    return input.replace('@', ''); // Remove @ if present
  }

  try {
    const url = input.startsWith('http') ? new URL(input) : new URL(`https://${input}`);
    
    switch (platform) {
      case 'youtube':
        // Handle youtube.com/@username format
        const path = url.pathname;
        if (path.startsWith('/@')) {
          return path.substring(2);
        }
        // Handle youtube.com/c/username or youtube.com/channel/id format
        const segments = path.split('/').filter(Boolean);
        return segments[segments.length - 1];
        
      case 'instagram':
      case 'twitter':
      case 'soundcloud':
        // These platforms typically use username as the last path segment
        const username = url.pathname.split('/').filter(Boolean)[0];
        return username?.replace('@', '') || '';
        
      default:
        return '';
    }
  } catch (error) {
    // If URL parsing fails, attempt to extract username from string
    const parts = input.split('/').filter(Boolean);
    return parts[parts.length - 1]?.replace('@', '') || input.replace('@', '');
  }
};

const StatsContent = ({ stats }) => {
  // For YouTube, show only subscribers, video count, and total views
  if (stats.platform === 'youtube') {
    return (
      <div className="space-y-4">
        <div className="grid grid-cols-1 sm:grid-cols-3 gap-3">
          <div className="bg-gray-50 dark:bg-gray-700/50 p-3 sm:p-4 rounded-lg text-center">
            <div className="text-xl sm:text-2xl font-bold text-gray-900 dark:text-white">
              {stats.followers?.toLocaleString() || '0'}
            </div>
            <div className="text-sm text-gray-500 dark:text-gray-400">
              Subscribers
            </div>
          </div>
          <div className="bg-gray-50 dark:bg-gray-700/50 p-3 sm:p-4 rounded-lg text-center">
            <div className="text-xl sm:text-2xl font-bold text-gray-900 dark:text-white">
              {stats.posts?.toLocaleString() || '0'}
            </div>
            <div className="text-sm text-gray-500 dark:text-gray-400">
              Videos
            </div>
          </div>
          <div className="bg-gray-50 dark:bg-gray-700/50 p-3 sm:p-4 rounded-lg text-center">
            <div className="text-xl sm:text-2xl font-bold text-gray-900 dark:text-white">
              {stats.views?.toLocaleString() || '0'}
            </div>
            <div className="text-sm text-gray-500 dark:text-gray-400">
              Total Views
            </div>
          </div>
        </div>
      </div>
    );
  }

  // For other platforms, show full stats
  return (
    <div className="space-y-4">
      {/* Profile Header */}
      <div>
        <h4 className="text-lg font-semibold text-gray-900 dark:text-white flex items-center">
          {stats.fullName}
          {stats.isVerified && (
            <span className="ml-1 text-blue-500">
              <svg className="w-4 h-4" viewBox="0 0 20 20" fill="currentColor">
                <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
              </svg>
            </span>
          )}
        </h4>
        <a
          href={`https://${stats.platform}.com/${stats.platform_username}`}
          target="_blank"
          rel="noopener noreferrer"
          className="inline-flex items-center text-sm text-blue-600 hover:text-blue-800 dark:text-blue-400 dark:hover:text-blue-300 transition-colors"
        >
          {stats.platform_username}
          <FaExternalLinkAlt className="ml-1 h-3 w-3" />
        </a>
      </div>

      {/* Stats Grid */}
      <div className="grid grid-cols-1 sm:grid-cols-3 gap-3">
        <div className="bg-gray-50 dark:bg-gray-700/50 p-3 sm:p-4 rounded-lg text-center">
          <div className="text-xl sm:text-2xl font-bold text-gray-900 dark:text-white">
            {stats.followers?.toLocaleString() || '0'}
          </div>
          <div className="text-sm text-gray-500 dark:text-gray-400">
            Followers
          </div>
        </div>
        <div className="bg-gray-50 dark:bg-gray-700/50 p-3 sm:p-4 rounded-lg text-center">
          <div className="text-xl sm:text-2xl font-bold text-gray-900 dark:text-white">
            {stats.following?.toLocaleString() || '0'}
          </div>
          <div className="text-sm text-gray-500 dark:text-gray-400">
            Following
          </div>
        </div>
        <div className="bg-gray-50 dark:bg-gray-700/50 p-3 sm:p-4 rounded-lg text-center">
          <div className="text-xl sm:text-2xl font-bold text-gray-900 dark:text-white">
            {stats.ratio || '0'}
          </div>
          <div className="text-sm text-gray-500 dark:text-gray-400">
            Ratio
          </div>
        </div>
      </div>

      {/* Bio Section */}
      {stats.bio && (
        <div className="bg-gray-50 dark:bg-gray-700/50 p-3 sm:p-4 rounded-lg">
          <p className="text-sm text-gray-700 dark:text-gray-300 whitespace-pre-wrap">
            {stats.bio}
          </p>
        </div>
      )}

      {/* Posts/Content Count */}
      <div className="bg-gray-50 dark:bg-gray-700/50 p-3 sm:p-4 rounded-lg text-center">
        <div className="text-xl sm:text-2xl font-bold text-gray-900 dark:text-white">
          {stats.posts?.toLocaleString() || '0'}
        </div>
        <div className="text-sm text-gray-500 dark:text-gray-400">
          {stats.platform === 'soundcloud' ? 'Tracks' : 'Posts'}
        </div>
      </div>
    </div>
  );
};

const PlatformInput = ({ platform, username, onUsernameChange, onFetch, loading, error, stats, onViewStats }) => {
  const Icon = platformIcons[platform];
  const colorClass = platformColors[platform];

  return (
    <div className="space-y-2">
      <div className="flex flex-col sm:flex-row sm:items-center space-y-2 sm:space-y-0 sm:space-x-2">
        <div className="flex-grow">
          <label htmlFor={`${platform}-handle`} className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
            {platformLabels[platform]}
          </label>
          <div className="relative">
            <span className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
              <Icon className={`h-5 w-5 ${colorClass}`} />
            </span>
            <input
              type="text"
              id={`${platform}-handle`}
              value={username}
              onChange={(e) => onUsernameChange(platform, e.target.value.trim())}
              placeholder={platformPlaceholders[platform]}
              className={`block w-full pl-10 pr-3 py-2 border rounded-md shadow-sm placeholder-gray-400 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:text-white sm:text-sm ${
                stats ? 'border-green-300 dark:border-green-600' : 'border-gray-300 dark:border-gray-600'
              }`}
            />
          </div>
        </div>
        <div className="flex space-x-2 sm:pt-6">
          <button
            onClick={() => onFetch(platform)}
            disabled={!username || loading}
            className="flex-1 sm:flex-none inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:bg-blue-400 disabled:cursor-not-allowed h-[38px] min-w-[100px] justify-center"
          >
            {loading ? <LoadingSpinner /> : 'Get Stats'}
          </button>
          <button
            onClick={() => onViewStats(platform)}
            disabled={!stats}
            className={`flex-1 sm:flex-none inline-flex items-center px-4 py-2 border rounded-md shadow-sm text-sm font-medium h-[38px] min-w-[100px] justify-center transition-colors ${
              stats 
                ? 'border-green-300 dark:border-green-600 text-green-700 dark:text-green-400 bg-green-50 dark:bg-green-900/30 hover:bg-green-100 dark:hover:bg-green-900/50' 
                : 'border-gray-300 dark:border-gray-600 text-gray-700 dark:text-gray-300 bg-white dark:bg-gray-800 hover:bg-gray-50 dark:hover:bg-gray-700'
            } focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:opacity-50 disabled:cursor-not-allowed`}
          >
            <FaChartBar className={`mr-2 ${stats ? 'text-green-500 dark:text-green-400' : ''}`} />
            {stats ? 'View Stats' : 'No Stats'}
          </button>
        </div>
      </div>

      {error && (
        <div className="p-2 rounded-md bg-red-50 dark:bg-red-900/30">
          <p className="text-sm text-red-700 dark:text-red-400">{error}</p>
        </div>
      )}
    </div>
  );
};

const SocialStatsModule = () => {
  const { modules } = useModules();
  const { user } = useAuth();
  const [platformData, setPlatformData] = useState({
    instagram: { username: '', stats: null, loading: false, error: '' },
    soundcloud: { username: '', stats: null, loading: false, error: '' },
    twitter: { username: '', stats: null, loading: false, error: '' },
      youtube: { username: '', stats: null, loading: false, error: '' }
  });
  const [selectedStats, setSelectedStats] = useState(null);

  // Fetch existing stats for all platforms when component mounts
  useEffect(() => {
    const fetchExistingStats = async () => {
      if (!user?.uid) return;

      // Create an array of promises for fetching stats from each platform
      const fetchPromises = Object.keys(platformData).map(async (platform) => {
        try {
          const stats = await socialStatsService.getStats(user.uid, platform);
          // Only update state if stats exist
          if (stats?.platform_username) {
            setPlatformData(prev => ({
              ...prev,
              [platform]: {
                ...prev[platform],
                username: stats.platform_username,
                stats: { ...stats, platform }
              }
            }));
          }
        } catch (error) {
          console.error(`Error fetching ${platform} stats:`, error);
          // Don't update state on error to keep any existing data
        }
      });

      try {
        // Wait for all fetch operations to complete
        await Promise.all(fetchPromises);
      } catch (error) {
        console.error('Error fetching social stats:', error);
      }
    };

    fetchExistingStats();
  }, [user]);

  const handleUsernameChange = (platform, value) => {
    setPlatformData(prev => ({
      ...prev,
      [platform]: { ...prev[platform], username: value, error: '' }
    }));
  };

  const handleViewStats = (platform) => {
    const data = platformData[platform];
    if (data.stats) {
      setSelectedStats(data.stats);
    }
  };

  const fetchStats = async (platform) => {
    const data = platformData[platform];
    if (!data.username || !user?.uid) return;

    setPlatformData(prev => ({
      ...prev,
      [platform]: { ...prev[platform], loading: true, error: '' }
    }));

    try {
      const scraper = platformScrapers[platform];
      if (!scraper) {
        throw new Error(`No scraper available for ${platform}`);
      }

      // Extract username from input (handles both profile URLs and usernames)
      const extractedUsername = extractUsername(data.username, platform);
      if (!extractedUsername) {
        throw new Error('Invalid username or profile URL');
      }

      const statsData = await scraper(extractedUsername);
      
      // Clean and format stats data
      const cleanStats = {
        platform,
        platform_username: platform === 'youtube' ? statsData.username : extractedUsername,
        followers: statsData.followers || 0,
        following: statsData.following || 0,
        posts: statsData.posts || 0,
        views: statsData.views || 0,
        ratio: statsData.ratio || 0,
        fullName: statsData.fullName || '',
        bio: statsData.bio || '',
        isVerified: !!statsData.isVerified
      };

      // Save stats to Firestore
      try {
        await socialStatsService.saveStats(user.uid, platform, cleanStats);
        console.log(`${platform} stats saved successfully`);
        
        // Update local state only after successful save
        setPlatformData(prev => ({
          ...prev,
          [platform]: {
            ...prev[platform],
            stats: cleanStats,
            loading: false,
            error: ''
          }
        }));

        // Show stats modal
        setSelectedStats(cleanStats);
      } catch (saveErr) {
        console.error(`Failed to save ${platform} stats:`, saveErr);
        throw new Error(`Failed to save ${platform} stats. Please try again.`);
      }

    } catch (err) {
      setPlatformData(prev => ({
        ...prev,
        [platform]: {
          ...prev[platform],
          loading: false,
          error: err.message || `Failed to fetch ${platform} stats`
        }
      }));
    }
  };

  if (!modules.socialStats) {
    return null;
  }

  return (
    <div className="space-y-6 p-3 sm:p-4 bg-white dark:bg-gray-800 rounded-lg shadow">
      <div className="space-y-6">
        {Object.keys(platformData).map(platform => (
          <PlatformInput
            key={platform}
            platform={platform}
            username={platformData[platform].username}
            onUsernameChange={handleUsernameChange}
            onFetch={fetchStats}
            loading={platformData[platform].loading}
            error={platformData[platform].error}
            stats={platformData[platform].stats}
            onViewStats={handleViewStats}
          />
        ))}
      </div>

      <Modal
        isOpen={!!selectedStats}
        onClose={() => setSelectedStats(null)}
        title={selectedStats ? `${selectedStats.platform.charAt(0).toUpperCase() + selectedStats.platform.slice(1)} Stats` : ''}
      >
        {selectedStats && <StatsContent stats={selectedStats} />}
      </Modal>
    </div>
  );
};

export default SocialStatsModule;
