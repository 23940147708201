import React from 'react';
import { motion } from 'framer-motion';
import { HiOfficeBuilding, HiLocationMarker } from 'react-icons/hi';
import ProfileSocialLinks from './ProfileSocialLinks';

const getPatternBackground = (pattern) => {
  switch (pattern) {
    case 'dots':
      return `radial-gradient(circle at 1px 1px, rgba(255,255,255,0.15) 1px, transparent 0)`;
    case 'lines':
      return `linear-gradient(0deg, transparent 9px, rgba(255,255,255,0.15) 10px)`;
    case 'grid':
      return `linear-gradient(0deg, transparent 9px, rgba(255,255,255,0.15) 10px),
              linear-gradient(90deg, transparent 9px, rgba(255,255,255,0.15) 10px)`;
    case 'diagonal':
      return `linear-gradient(45deg, rgba(255,255,255,0.15) 25%, transparent 25%),
              linear-gradient(-45deg, rgba(255,255,255,0.15) 25%, transparent 25%)`;
    default:
      return 'none';
  }
};

const getPatternSize = (pattern) => {
  switch (pattern) {
    case 'dots':
      return '20px 20px';
    case 'lines':
      return '20px 20px';
    case 'grid':
      return '20px 20px';
    case 'diagonal':
      return '20px 20px';
    default:
      return 'auto';
  }
};

const ProfileBanner = ({ 
  user, 
  connectionButton, 
  connectionStatus, 
  isRequestReceiver, 
  isProcessing,
  handleAccept,
  handleDecline,
  company,
  about
}) => {
  const fadeInUp = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 }
  };

  // Get initials from name
  const getInitials = (name) => {
    return name
      .split(' ')
      .map(word => word[0])
      .join('')
      .toUpperCase();
  };

  return (
    <div className="rounded-[24px] overflow-hidden bg-[#1a1f2e]">
      <style jsx>{`
        @keyframes gradientAnimation {
          0% {
            background-position: 0% 50%;
          }
          50% {
            background-position: 100% 50%;
          }
          100% {
            background-position: 0% 50%;
          }
        }
      `}</style>

      {/* Banner */}
      <div
        className="h-[170px]"
        style={{
          background: user.theme ? (
            user.theme.gradientType === 'linear' ?
              `linear-gradient(${user.theme.gradientDirection}, ${user.theme.primaryColor}, ${user.theme.secondaryColor})` :
              `radial-gradient(circle at ${user.theme.gradientPosition}, ${user.theme.primaryColor}, ${user.theme.secondaryColor})`
          ) : 'linear-gradient(90deg, #4776E6, #6C5CE7, #8E54E9, #4776E6)',
          backgroundImage: user.theme?.pattern !== 'none' ? `${getPatternBackground(user.theme?.pattern)}, ${
            user.theme.gradientType === 'linear' ?
              `linear-gradient(${user.theme.gradientDirection}, ${user.theme.primaryColor}, ${user.theme.secondaryColor})` :
              `radial-gradient(circle at ${user.theme.gradientPosition}, ${user.theme.primaryColor}, ${user.theme.secondaryColor})`
          }` : undefined,
          backgroundSize: user.theme?.pattern !== 'none' ? `${getPatternSize(user.theme?.pattern)}, 100% 100%` : '100% 100%',
          animation: user.theme ? 'none' : 'gradientAnimation 15s ease infinite'
        }}
      />
      
      {/* Content Area */}
      <div className="relative px-8">
        {/* Profile Circle */}
        <div className="absolute left-1/2 -translate-x-1/2 -top-[72px]">
          <div className="w-36 h-36 rounded-full p-[3px] bg-[#1a1f2e]">
            <div
              className="w-full h-full rounded-full flex items-center justify-center"
              style={{
                background: user.theme ? (
                  user.theme.gradientType === 'linear' ?
                    `linear-gradient(${user.theme.gradientDirection}, ${user.theme.primaryColor}, ${user.theme.secondaryColor})` :
                    `radial-gradient(circle at ${user.theme.gradientPosition}, ${user.theme.primaryColor}, ${user.theme.secondaryColor})`
                ) : 'linear-gradient(90deg, #4776E6, #6C5CE7, #8E54E9, #4776E6)',
                backgroundImage: user.theme?.pattern !== 'none' ? `${getPatternBackground(user.theme?.pattern)}, ${
                  user.theme.gradientType === 'linear' ?
                    `linear-gradient(${user.theme.gradientDirection}, ${user.theme.primaryColor}, ${user.theme.secondaryColor})` :
                    `radial-gradient(circle at ${user.theme.gradientPosition}, ${user.theme.primaryColor}, ${user.theme.secondaryColor})`
                }` : undefined,
                backgroundSize: user.theme?.pattern !== 'none' ? `${getPatternSize(user.theme?.pattern)}, 100% 100%` : '100% 100%'
              }}
            >
              <span style={{ color: user.theme?.textColor || '#FFFFFF' }} className="text-5xl font-bold">
                {getInitials(user.name)}
              </span>
            </div>
          </div>
        </div>

        {/* User Info and Connection Button */}
        <div className="pt-20 pb-8">
          <div className="flex flex-col items-center text-center">
            <div>
              <h1 className="text-3xl font-bold text-white">
                {user.name}
              </h1>
              <p className="text-gray-400">@{user.username}</p>
              {company && (
                <div className="flex items-center justify-center gap-2 text-gray-400 mt-2">
                  <HiOfficeBuilding className="w-4 h-4" />
                  <span>{company}</span>
                </div>
              )}
              {user.location && (
                <div className="flex items-center justify-center gap-2 text-gray-400 mt-2">
                  <HiLocationMarker className="w-4 h-4" />
                  <span>{user.location}</span>
                </div>
              )}
            </div>

            {/* Connection Button */}
            {connectionButton && (
              <div className="mt-4">
                {connectionStatus === 'pending' && isRequestReceiver ? (
                  <div className="flex gap-3">
                    <button
                      onClick={handleAccept}
                      disabled={isProcessing}
                      className="px-5 py-2.5 bg-green-500 hover:bg-green-600 text-white rounded-lg transition-colors duration-200 font-medium"
                    >
                      Accept
                    </button>
                    <button
                      onClick={handleDecline}
                      disabled={isProcessing}
                      className="px-5 py-2.5 bg-red-500 hover:bg-red-600 text-white rounded-lg transition-colors duration-200 font-medium"
                    >
                      Decline
                    </button>
                  </div>
                ) : (
                  <button
                    onClick={connectionButton.onClick}
                    disabled={isProcessing || !connectionButton.onClick}
                    className={connectionButton.classes}
                  >
                    <connectionButton.icon className="w-5 h-5" />
                    {connectionButton.text}
                  </button>
                )}
              </div>
            )}
          </div>

          {/* About Section */}
          {about && (
            <motion.div variants={fadeInUp} className="mt-12 text-center max-w-2xl mx-auto">
              <p className="text-gray-400 leading-relaxed">
                {about}
              </p>
            </motion.div>
          )}

          {/* Social Links Section */}
          <div className="border-t border-gray-800 mt-6 pt-6">
            <ProfileSocialLinks socialLinks={user.socialLinks} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileBanner;
