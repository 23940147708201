import React from 'react';
import { useNavigate } from 'react-router-dom';
import { HiArrowLeft } from 'react-icons/hi';
import UserSearch from '../components/UserSearch';

const Search = () => {
  const navigate = useNavigate();

  return (
    <div className="fixed inset-0 bg-white dark:bg-gray-900 z-50 flex flex-col">
      {/* Header */}
      <div className="sticky top-0 z-10 bg-white dark:bg-gray-800 px-4 py-3 border-b border-gray-200 dark:border-gray-700 flex items-center">
        <button
          onClick={() => navigate(-1)}
          className="p-2 -ml-2 rounded-lg hover:bg-gray-100 dark:hover:bg-gray-700 text-gray-600 dark:text-gray-400 transition-colors duration-200"
          aria-label="Go back"
        >
          <HiArrowLeft size={24} />
        </button>
        <h1 className="ml-2 text-xl font-semibold text-gray-900 dark:text-white">
          Search
        </h1>
      </div>

      {/* Search Content */}
      <div className="flex-1 overflow-y-auto">
        <div className="p-4">
          <UserSearch />
        </div>
      </div>
    </div>
  );
};

export default Search;
