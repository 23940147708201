import React from 'react';
import DraggableComponent from './DraggableComponent';
import {
  HiOutlineDocumentText,
  HiOutlineIdentification,
  HiOutlineCurrencyDollar,
  HiOutlineCalendar,
  HiOutlineUserGroup,
  HiOutlineLockClosed,
  HiOutlineScale,
  HiOutlineTable,
  HiOutlineCheckCircle,
  HiOutlineSelector,
  HiOutlinePencil,
  HiOutlinePhotograph,
  HiOutlineCalculator,
  HiOutlineClipboardList,
  HiOutlineLink
} from 'react-icons/hi';

const ComponentLibrary = () => {
  const components = [
    {
      category: 'Basic Elements',
      items: [
        { type: 'text', label: 'Text Block', icon: HiOutlineDocumentText },
        { type: 'input', label: 'Input Field', icon: HiOutlinePencil },
        { type: 'signature', label: 'Signature Block', icon: HiOutlinePencil },
        { type: 'date', label: 'Date Field', icon: HiOutlineCalendar },
        { type: 'checkbox', label: 'Checkbox Group', icon: HiOutlineCheckCircle },
        { type: 'dropdown', label: 'Dropdown', icon: HiOutlineSelector },
        { type: 'table', label: 'Table', icon: HiOutlineTable },
        { type: 'file', label: 'File Upload', icon: HiOutlinePhotograph },
      ]
    },
    {
      category: 'Agreement Sections',
      items: [
        { type: 'party-info', label: 'Party Information', icon: HiOutlineIdentification },
        { type: 'payment-terms', label: 'Payment Terms', icon: HiOutlineCurrencyDollar },
        { type: 'confidentiality', label: 'Confidentiality', icon: HiOutlineLockClosed },
        { type: 'intellectual-property', label: 'IP Rights', icon: HiOutlineLink },
        { type: 'dispute-resolution', label: 'Dispute Resolution', icon: HiOutlineScale },
      ]
    },
    {
      category: 'Dynamic Elements',
      items: [
        { type: 'calculation', label: 'Calculation Field', icon: HiOutlineCalculator },
        { type: 'repeatable', label: 'Repeatable Section', icon: HiOutlineClipboardList },
        { type: 'conditional', label: 'Conditional Block', icon: HiOutlineSelector },
        { type: 'party-list', label: 'Party List', icon: HiOutlineUserGroup },
      ]
    }
  ];

  return (
    <div className="w-64 h-full overflow-y-auto bg-gray-50 dark:bg-gray-900/50 border-r border-gray-200 dark:border-gray-700">
      <div className="p-4">
        <h2 className="text-lg font-semibold text-gray-800 dark:text-white mb-4">
          Components
        </h2>
        
        {components.map((category) => (
          <div key={category.category} className="mb-6">
            <h3 className="text-sm font-medium text-gray-600 dark:text-gray-400 mb-3">
              {category.category}
            </h3>
            <div className="space-y-2">
              {category.items.map((item) => (
                <DraggableComponent
                  key={item.type}
                  type={item.type}
                  label={item.label}
                  icon={item.icon}
                />
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ComponentLibrary;
