import React from 'react';

const ProfileAbout = ({ about, isEditing, onChange }) => {
  return (
    <div className="mt-8">
      <h3 className="text-lg font-semibold text-gray-800 dark:text-gray-100 border-b border-gray-200 dark:border-gray-700 pb-2">
        About
      </h3>
      {isEditing ? (
        <textarea
          value={about}
          onChange={(e) => onChange(e.target.value)}
          className="mt-4 w-full px-3 py-2 border rounded-lg dark:bg-gray-700 dark:border-gray-600 min-h-[100px]"
          placeholder="Tell us about yourself..."
        />
      ) : (
        <p className="mt-4 text-gray-600 dark:text-gray-400 leading-relaxed">
          {about || 'No description provided'}
        </p>
      )}
    </div>
  );
};

export default ProfileAbout;
