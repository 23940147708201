import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { HiSearch, HiX, HiArrowLeft } from 'react-icons/hi';
import UserSearch from './UserSearch';

const GlobalSearch = () => {
  const [isDesktopPanelOpen, setIsDesktopPanelOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const handleSearchClick = () => {
    if (window.innerWidth >= 1024) {
      setIsDesktopPanelOpen(true);
    } else {
      navigate('/search');
    }
  };

  const closeDesktopPanel = () => {
    setIsDesktopPanelOpen(false);
  };

  // Close panel when route changes
  React.useEffect(() => {
    setIsDesktopPanelOpen(false);
  }, [location.pathname]);

  return (
    <>
      {/* Global Search Button */}
      <button
        onClick={handleSearchClick}
        className="p-2.5 rounded-lg bg-gray-100 dark:bg-gray-700 text-gray-600 dark:text-gray-300 hover:bg-gray-200 dark:hover:bg-gray-600 transition-colors duration-200 focus:outline-none focus:ring-2 focus:ring-gray-300 dark:focus:ring-gray-600 active:scale-95 transform"
        aria-label="Search"
      >
        <HiSearch size={20} />
      </button>

      {/* Desktop Search Panel */}
      {window.innerWidth >= 1024 && (
        <>
          {/* Overlay */}
          <div 
            className={`
              fixed inset-0 bg-black transition-opacity duration-300 ease-in-out z-40
              ${isDesktopPanelOpen ? 'opacity-50 backdrop-blur-sm' : 'opacity-0 pointer-events-none'}
            `}
            onClick={closeDesktopPanel}
            aria-hidden="true"
          />

          {/* Panel */}
          <div 
            className={`
              fixed top-0 right-0 h-full z-50 w-[400px]
              transform transition-transform duration-300 ease-in-out
              ${isDesktopPanelOpen ? 'translate-x-0' : 'translate-x-full'}
              bg-white dark:bg-gray-800 shadow-2xl
              flex flex-col
            `}
          >
            {/* Header */}
            <div className="sticky top-0 z-10 bg-white dark:bg-gray-800 p-4 border-b border-gray-200 dark:border-gray-700 flex items-center justify-between">
              <h2 className="text-xl font-semibold text-gray-900 dark:text-white">Search</h2>
              <button
                onClick={closeDesktopPanel}
                className="p-2 rounded-lg hover:bg-gray-100 dark:hover:bg-gray-700 text-gray-600 dark:text-gray-400 transition-colors duration-200"
                aria-label="Close search panel"
              >
                <HiX size={24} />
              </button>
            </div>

            {/* Search Content */}
            <div className="flex-1 overflow-y-auto">
              <div className="p-4">
                <UserSearch inPanel={true} onUserClick={closeDesktopPanel} />
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default GlobalSearch;
