import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import ReCAPTCHA from 'react-google-recaptcha';
import { 
  HiOutlineMail, 
  HiOutlineLockClosed, 
  HiOutlineUser,
  HiOutlineExclamationCircle,
  HiX
} from 'react-icons/hi';
import { useAuth } from '../contexts/AuthContext';

function AuthModal({ isOpen, onClose, initialMode = 'login' }) {
  const navigate = useNavigate();
  const location = useLocation();
  const { login, register, forgotPassword, simulateAuth } = useAuth();
  const [mode, setMode] = useState(initialMode);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [recaptchaValue, setRecaptchaValue] = useState(null);
  const [submitted, setSubmitted] = useState(false);

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [isOpen]);

  useEffect(() => {
    const handleEscape = (e) => {
      if (e.key === 'Escape') onClose();
    };
    if (isOpen) {
      window.addEventListener('keydown', handleEscape);
      return () => window.removeEventListener('keydown', handleEscape);
    }
  }, [isOpen, onClose]);

  const [formData, setFormData] = useState({
    email: '',
    password: '',
    remember: false,
    firstName: '',
    lastName: '',
    confirmPassword: ''
  });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    const newValue = type === 'checkbox' ? checked : value;
    setFormData(prev => ({
      ...prev,
      [name]: newValue
    }));
    setError('');
  };

  const handleRecaptchaChange = (value) => {
    setRecaptchaValue(value);
    setError('');
  };

  const validateRegistration = () => {
    if (!recaptchaValue && mode === 'register') {
      setError('Please verify that you are human');
      return false;
    }
    if (formData.password !== formData.confirmPassword) {
      setError('Passwords do not match');
      return false;
    }
    if (formData.password.length < 8) {
      setError('Password must be at least 8 characters long');
      return false;
    }
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (mode === 'login') {
      try {
        setError('');
        setLoading(true);
        const result = await login(formData.email, formData.password, formData.remember);
        
        if (result.success) {
          onClose();
          const from = location.state?.from?.pathname || '/profile';
          navigate(from, { replace: true });
        } else {
          setError(result.error || 'Failed to sign in');
        }
      } catch (err) {
        setError('Failed to sign in');
      } finally {
        setLoading(false);
      }
    } else if (mode === 'forgot-password') {
      try {
        setError('');
        setLoading(true);
        const result = await forgotPassword(formData.email);
        
        if (result.success) {
          setSubmitted(true);
        } else {
          setError(result.error || 'Failed to send reset instructions');
        }
      } catch (err) {
        setError('Failed to send reset instructions');
      } finally {
        setLoading(false);
      }
    } else {
      if (!validateRegistration()) {
        return;
      }
      try {
        setError('');
        setLoading(true);
        const registrationData = {
          firstName: formData.firstName,
          lastName: formData.lastName,
          email: formData.email,
          password: formData.password,
          recaptchaToken: recaptchaValue
        };
        
        const result = await register(registrationData);
        
        if (result.success) {
          onClose();
          navigate('/profile');
        } else {
          setError(result.error || 'Failed to create account');
        }
      } catch (err) {
        setError('Failed to create account');
      } finally {
        setLoading(false);
      }
    }
  };

  const switchMode = (newMode) => {
    setMode(newMode);
    setError('');
    setRecaptchaValue(null);
    setSubmitted(false);
    setFormData({
      email: '',
      password: '',
      remember: false,
      firstName: '',
      lastName: '',
      confirmPassword: ''
    });
  };

  const renderError = () => {
    if (!error) return null;
    
    return (
      <div className="bg-red-50 dark:bg-red-900/50 border border-red-200 dark:border-red-800 rounded-lg p-4 mb-6">
        <div className="flex">
          <div className="flex-shrink-0">
            <HiOutlineExclamationCircle className="h-5 w-5 text-red-400" aria-hidden="true" />
          </div>
          <div className="ml-3">
            <p className="text-sm text-red-700 dark:text-red-200" role="alert">{error}</p>
          </div>
        </div>
      </div>
    );
  };

  const inputClasses = `
    appearance-none rounded-lg relative block w-full px-12 py-4 sm:py-3
    border border-gray-300 dark:border-gray-700 
    placeholder-gray-500 dark:placeholder-gray-400 
    text-gray-900 dark:text-white 
    bg-white dark:bg-gray-800 
    focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent
    transition-colors duration-200
    text-base sm:text-sm
    touch-manipulation
  `;

  const buttonClasses = `
    relative flex justify-center items-center
    px-6 py-4 sm:py-3
    border border-transparent
    text-base sm:text-sm font-medium rounded-lg
    transition-all duration-200
    focus:outline-none focus:ring-2 focus:ring-offset-2
    touch-manipulation
    disabled:opacity-50 disabled:cursor-not-allowed
  `;

  const renderLogin = () => (
    <>
      <div className="space-y-4">
        <div className="relative">
          <label htmlFor="email" className="sr-only">Email address</label>
          <HiOutlineMail className="absolute top-1/2 -translate-y-1/2 left-4 text-gray-400" size={20} />
          <input
            id="email"
            name="email"
            type="email"
            autoComplete="email"
            required
            className={inputClasses}
            placeholder="Email address"
            value={formData.email}
            onChange={handleChange}
            disabled={loading}
          />
        </div>
        <div className="relative">
          <label htmlFor="password" className="sr-only">Password</label>
          <HiOutlineLockClosed className="absolute top-1/2 -translate-y-1/2 left-4 text-gray-400" size={20} />
          <input
            id="password"
            name="password"
            type="password"
            autoComplete="current-password"
            required
            className={inputClasses}
            placeholder="Password"
            value={formData.password}
            onChange={handleChange}
            disabled={loading}
          />
        </div>
      </div>

      <div className="flex items-center justify-between mt-6">
        <div className="flex items-center">
          <input
            id="remember"
            name="remember"
            type="checkbox"
            className="h-5 w-5 text-blue-600 focus:ring-blue-500 border-gray-300 dark:border-gray-700 rounded cursor-pointer"
            checked={formData.remember}
            onChange={handleChange}
            disabled={loading}
          />
          <label 
            htmlFor="remember" 
            className="ml-3 block text-base sm:text-sm text-gray-900 dark:text-gray-300 cursor-pointer"
            onClick={() => {
              if (!loading) {
                setFormData(prev => ({
                  ...prev,
                  remember: !prev.remember
                }));
              }
            }}
          >
            Remember me
          </label>
        </div>

        <div className="text-base sm:text-sm">
          <button
            type="button"
            onClick={() => switchMode('forgot-password')}
            className="font-medium text-blue-600 hover:text-blue-500 dark:text-blue-400 dark:hover:text-blue-300"
          >
            Forgot password?
          </button>
        </div>
      </div>
    </>
  );

  const renderRegister = () => (
    <>
      <div className="space-y-4">
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
          <div className="relative">
            <label htmlFor="firstName" className="sr-only">First Name</label>
            <HiOutlineUser className="absolute top-1/2 -translate-y-1/2 left-4 text-gray-400" size={20} />
            <input
              id="firstName"
              name="firstName"
              type="text"
              required
              className={inputClasses}
              placeholder="First Name"
              value={formData.firstName}
              onChange={handleChange}
              disabled={loading}
            />
          </div>
          <div className="relative">
            <label htmlFor="lastName" className="sr-only">Last Name</label>
            <HiOutlineUser className="absolute top-1/2 -translate-y-1/2 left-4 text-gray-400" size={20} />
            <input
              id="lastName"
              name="lastName"
              type="text"
              required
              className={inputClasses}
              placeholder="Last Name"
              value={formData.lastName}
              onChange={handleChange}
              disabled={loading}
            />
          </div>
        </div>
        
        <div className="relative">
          <label htmlFor="email" className="sr-only">Email address</label>
          <HiOutlineMail className="absolute top-1/2 -translate-y-1/2 left-4 text-gray-400" size={20} />
          <input
            id="email"
            name="email"
            type="email"
            autoComplete="email"
            required
            className={inputClasses}
            placeholder="Email address"
            value={formData.email}
            onChange={handleChange}
            disabled={loading}
          />
        </div>

        <div className="relative">
          <label htmlFor="password" className="sr-only">Password</label>
          <HiOutlineLockClosed className="absolute top-1/2 -translate-y-1/2 left-4 text-gray-400" size={20} />
          <input
            id="password"
            name="password"
            type="password"
            required
            className={inputClasses}
            placeholder="Password"
            value={formData.password}
            onChange={handleChange}
            disabled={loading}
          />
        </div>

        <div className="relative">
          <label htmlFor="confirmPassword" className="sr-only">Confirm Password</label>
          <HiOutlineLockClosed className="absolute top-1/2 -translate-y-1/2 left-4 text-gray-400" size={20} />
          <input
            id="confirmPassword"
            name="confirmPassword"
            type="password"
            required
            className={inputClasses}
            placeholder="Confirm Password"
            value={formData.confirmPassword}
            onChange={handleChange}
            disabled={loading}
          />
        </div>

        <div className="flex justify-center mt-4">
          <ReCAPTCHA
            sitekey="6Lf7gZIqAAAAAGOilLiCS-f-cWPFeqGnMtFkKj1P"
            onChange={handleRecaptchaChange}
            theme="light"
            className="transform scale-90 sm:scale-100"
          />
        </div>
      </div>
    </>
  );

  const renderForgotPassword = () => {
    if (submitted) {
      return (
        <div className="text-center space-y-4">
          <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
            Check your email
          </h3>
          <div className="text-gray-600 dark:text-gray-400">
            <p className="mb-4">
              We've sent password reset instructions to:
            </p>
            <p className="font-medium text-gray-900 dark:text-white mb-6">
              {formData.email}
            </p>
            <p className="text-sm">
              Make sure to check your spam folder if you don't see the email in your inbox.
            </p>
          </div>
          <button
            type="button"
            onClick={() => switchMode('login')}
            className="mt-4 font-medium text-blue-600 hover:text-blue-500 dark:text-blue-400 dark:hover:text-blue-300"
          >
            Return to login
          </button>
        </div>
      );
    }

    return (
      <div className="space-y-4">
        <p className="text-center text-sm text-gray-600 dark:text-gray-400">
          Enter your email address and we'll send you instructions to reset your password.
        </p>
        <div className="relative">
          <label htmlFor="email" className="sr-only">Email address</label>
          <HiOutlineMail className="absolute top-1/2 -translate-y-1/2 left-4 text-gray-400" size={20} />
          <input
            id="email"
            name="email"
            type="email"
            autoComplete="email"
            required
            className={inputClasses}
            placeholder="Email address"
            value={formData.email}
            onChange={handleChange}
            disabled={loading}
          />
        </div>
      </div>
    );
  };

  if (!isOpen) return null;

  return (
    <div 
      className="fixed inset-0 z-50 overflow-y-auto" 
      aria-labelledby="modal-title" 
      role="dialog" 
      aria-modal="true"
    >
      <div className="min-h-screen px-4 text-center">
        <div 
          className="fixed inset-0 bg-gray-500 bg-opacity-75 backdrop-blur-sm transition-opacity" 
          aria-hidden="true"
          onClick={onClose}
        ></div>

        <span className="inline-block h-screen align-middle" aria-hidden="true">&#8203;</span>

        <div className="inline-block w-full max-w-md p-6 my-8 text-left align-middle transition-all transform bg-white dark:bg-gray-800 shadow-xl rounded-2xl relative">
          <div className="absolute right-4 top-4">
            <button
              type="button"
              onClick={onClose}
              className="rounded-lg p-2 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-500 touch-manipulation"
            >
              <span className="sr-only">Close</span>
              <HiX className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>

          <div className="text-center mb-8">
            <h3 
              className="text-2xl sm:text-xl font-semibold text-gray-900 dark:text-white" 
              id="modal-title"
            >
              {mode === 'login' ? 'Sign in to Agreebits' : 
               mode === 'register' ? 'Create your account' : 
               'Reset your password'}
            </h3>
            {mode !== 'forgot-password' && (
              <div className="mt-2">
                <p className="text-base sm:text-sm text-gray-500 dark:text-gray-400">
                  {mode === 'login' ? (
                    <>
                      Don't have an account?{' '}
                      <button
                        type="button"
                        onClick={() => switchMode('register')}
                        className="font-medium text-blue-600 hover:text-blue-500 dark:text-blue-400 dark:hover:text-blue-300"
                      >
                        Sign up
                      </button>
                    </>
                  ) : (
                    <>
                      Already have an account?{' '}
                      <button
                        type="button"
                        onClick={() => switchMode('login')}
                        className="font-medium text-blue-600 hover:text-blue-500 dark:text-blue-400 dark:hover:text-blue-300"
                      >
                        Sign in
                      </button>
                    </>
                  )}
                </p>
              </div>
            )}
          </div>

          {renderError()}

          <form onSubmit={handleSubmit} className="space-y-6">
            {mode === 'login' && renderLogin()}
            {mode === 'register' && renderRegister()}
            {mode === 'forgot-password' && renderForgotPassword()}

            {(!submitted || mode !== 'forgot-password') && (
              <div className="flex justify-between mt-8">
                <button
                  type="submit"
                  disabled={loading || (mode === 'register' && !recaptchaValue)}
                  className={`${buttonClasses} w-full bg-blue-600 hover:bg-blue-700 text-white focus:ring-blue-500`}
                >
                  {loading ? (
                    <span className="flex items-center">
                      <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                      </svg>
                      <span>
                        {mode === 'login' ? 'Signing in...' : 
                         mode === 'register' ? 'Creating Account...' :
                         'Sending...'}
                      </span>
                    </span>
                  ) : (
                    <span>
                      {mode === 'login' ? 'Sign in' : 
                       mode === 'register' ? 'Create Account' :
                       'Send reset instructions'}
                    </span>
                  )}
                </button>
              </div>
            )}

            {mode === 'forgot-password' && !submitted && (
              <div className="text-center">
                <button
                  type="button"
                  onClick={() => switchMode('login')}
                  className="font-medium text-blue-600 hover:text-blue-500 dark:text-blue-400 dark:hover:text-blue-300"
                >
                  Back to login
                </button>
              </div>
            )}
          </form>
        </div>
      </div>
    </div>
  );
}

export default AuthModal;
