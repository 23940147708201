import { useState, useEffect, useCallback } from 'react';
import { db } from '../../../services/firebase';
import { collection, query, where, getDocs, doc, getDoc } from 'firebase/firestore';
import { calculateNodePosition, getNodeSize } from '../utils/positioning';

export const useNetworkData = (currentUserId, user, dimensions) => {
    const [graphData, setGraphData] = useState({ nodes: [], links: [] });
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [connections, setConnections] = useState([]);
    const [allUsers, setAllUsers] = useState([]);
    const [visibleLinks, setVisibleLinks] = useState(new Set());
    const [expandedNodes, setExpandedNodes] = useState(new Set());

    const updateGraphData = useCallback((currentConnections, users = []) => {
        try {
            const nodes = [];
            const links = [];
            const newVisibleLinks = new Set();
            const usedPositions = [];

            // Calculate maxRadius based on screen size
            const baseRadius = Math.min(dimensions.width, dimensions.height) * 0.35;
            const nodeCount = users.length;
            const maxRadius = baseRadius * (1 + Math.log10(nodeCount + 1) * 0.2);
            const minDistance = maxRadius * (0.3 / Math.log10(nodeCount + 2));

            // Add center node only if user is logged in
            if (currentUserId && user) {
                const centerPos = { x: 0, y: 0 };
                usedPositions.push(centerPos);
                nodes.push({
                    id: currentUserId,
                    name: 'You',
                    colorKey: 'you',
                    size: getNodeSize('you', dimensions),
                    username: user.username,
                    x: centerPos.x,
                    y: centerPos.y,
                    fx: centerPos.x,
                    fy: centerPos.y
                });
            }

            // Track direct connections if user is logged in
            const directConnectionIds = new Set();
            if (currentUserId && user) {
                currentConnections.forEach(conn => {
                    if (conn.from === currentUserId) {
                        directConnectionIds.add(conn.to);
                    } else if (conn.to === currentUserId) {
                        directConnectionIds.add(conn.from);
                    }
                });
            }

            // For logged in users: Add all nodes, but position direct connections in inner circle
            // For guests: Add all nodes in a circular layout
            const nodesToAdd = users.filter(u => u.id !== currentUserId);

            nodesToAdd.forEach((user, index) => {
                const isDirectConnection = directConnectionIds.has(user.id);
                const radius = currentUserId
                    ? (isDirectConnection ? maxRadius * 0.5 : maxRadius)
                    : maxRadius * 0.7; // For guests, use a medium radius for all nodes

                const position = calculateNodePosition(
                    user.id + index,
                    usedPositions,
                    dimensions,
                    radius,
                    minDistance
                );
                usedPositions.push(position);

                nodes.push({
                    id: user.id,
                    name: user.name || 'Unknown User',
                    colorKey: isDirectConnection ? 'connected' : 'secondary',
                    size: getNodeSize(isDirectConnection ? 'direct' : 'secondary', dimensions),
                    username: user.username,
                    x: position.x,
                    y: position.y,
                    fx: position.x,
                    fy: position.y
                });
            });

            // Add connections
            currentConnections.forEach(conn => {
                const sourceId = conn.from;
                const targetId = conn.to;
                const isDirectConnection = currentUserId && (sourceId === currentUserId || targetId === currentUserId);
                const linkId = `${Math.min(sourceId, targetId)}-${Math.max(sourceId, targetId)}`;

                links.push({
                    id: linkId,
                    source: sourceId,
                    target: targetId,
                    isDirectConnection
                });

                // For logged in users: Show only direct connections by default
                // For guests: Show all connections
                if (!currentUserId || isDirectConnection) {
                    newVisibleLinks.add(linkId);
                }
            });

            setVisibleLinks(newVisibleLinks);
            setGraphData({ nodes, links });

        } catch (err) {
            console.error('Error updating graph data:', err);
            setError('Error updating network visualization');
        }
    }, [currentUserId, user, dimensions]);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            setError(null);
            try {
                let fetchedConnections = [];
                let userIds = new Set();

                // Only fetch user's direct connections if logged in
                if (currentUserId) {
                    // Get all connections
                    const connectionsRef = collection(db, 'connections');
                    const [fromSnapshot, toSnapshot] = await Promise.all([
                        getDocs(query(
                            connectionsRef,
                            where('status', '==', 'accepted'),
                            where('from', '==', currentUserId)
                        )),
                        getDocs(query(
                            connectionsRef,
                            where('status', '==', 'accepted'),
                            where('to', '==', currentUserId)
                        ))
                    ]);

                    // Combine connections
                    fetchedConnections = [
                        ...fromSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })),
                        ...toSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }))
                    ];

                    // Get unique user IDs from connections
                    fetchedConnections.forEach(conn => {
                        userIds.add(conn.from);
                        userIds.add(conn.to);
                    });
                    userIds.delete(currentUserId); // Remove current user
                }

                // Fetch all users
                const snapshot = await getDocs(collection(db, 'users'));
                const users = snapshot.docs
                    .filter(doc => doc && doc.exists() && doc.data().username) // Only include users with usernames
                    .map(doc => ({
                        id: doc.id,
                        name: doc.data().name || doc.data().displayName || doc.data().username || 'Unknown User',
                        username: doc.data().username
                    }))
                    .slice(0, 20); // Limit to 20 users for performance

                // For non-logged in users, fetch connections between displayed users
                if (!currentUserId && users.length > 0) {
                    const connectionsRef = collection(db, 'connections');
                    const connectionsSnapshot = await getDocs(
                        query(connectionsRef,
                            where('status', '==', 'accepted'),
                            where('from', 'in', users.slice(0, 10).map(u => u.id)) // Limit to first 10 users' connections
                        )
                    );
                    
                    fetchedConnections = connectionsSnapshot.docs
                        .map(doc => ({ id: doc.id, ...doc.data() }))
                        .filter(conn => users.some(u => u.id === conn.to)); // Only keep connections between fetched users
                }

                setConnections(fetchedConnections);
                setAllUsers(users);
                setExpandedNodes(new Set());
                updateGraphData(fetchedConnections, users);
            } catch (err) {
                console.error('[NetworkMap] Error fetching data:', err);
                setError('Failed to load network data');
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [currentUserId, updateGraphData]);

    return {
        graphData,
        loading,
        error,
        connections,
        allUsers,
        visibleLinks,
        expandedNodes,
        setExpandedNodes,
        setVisibleLinks,
        updateGraphData
    };
};

export default useNetworkData;
