// X (formerly Twitter) profile fetching using RapidAPI
const RAPID_API_KEY = '94dfe38c00msh2095861bdba1943p179b8ejsna0dc6af4cd8b';
const RAPID_API_HOST = 'real-time-x-com-data-scraper.p.rapidapi.com';

// Get user stats
export const scrapeTwitterStats = async (handle) => {
  if (!handle) {
    throw new Error('X username is required');
  }

  // Clean the handle (remove @ if present)
  const cleanHandle = handle.startsWith('@') ? handle.substring(1) : handle;

  try {
    const response = await fetch(
      `https://${RAPID_API_HOST}/v2/UserByScreenName/?username=${encodeURIComponent(cleanHandle)}`,
      {
        headers: {
          'X-RapidAPI-Key': RAPID_API_KEY,
          'X-RapidAPI-Host': RAPID_API_HOST,
          'Accept': 'application/json'
        }
      }
    );

    if (!response.ok) {
      const error = await response.json();
      throw new Error(error.message || 'Failed to fetch X stats');
    }

    const data = await response.json();
    
    if (!data || !data.data) {
      throw new Error('Profile not found');
    }

    const userData = data.data;
    const legacy = userData.user.result?.legacy || {};

    return {
      username: cleanHandle,
      platformUsername: legacy.screen_name || null,
      followers: legacy.followers_count || 0,
      following: legacy.friends_count || 0,
      posts: legacy.statuses_count || 0,
      profilePic: legacy.profile_image_url_https || null,
      description: legacy.description || null,
      displayName: legacy.name || null,
      isVerified: Boolean(legacy.verified),
      location: legacy.location || null
    };
  } catch (error) {
    console.error('Error fetching X stats:', error);
    throw error;
  }
};
