import React from 'react';
import { motion, useScroll, useTransform } from 'framer-motion';
import { 
  HiOutlineGlobe,
  HiOutlineUserCircle,
  HiShare,
  HiOutlinePuzzle,
  HiOutlineLink,
  HiColorSwatch,
  HiArrowRight
} from 'react-icons/hi';
import { useAuth } from '../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';

function About({ onAuthClick }) {
  const { user } = useAuth();
  const navigate = useNavigate();
  const { scrollYProgress } = useScroll();
  const opacity = useTransform(scrollYProgress, [0, 0.2], [1, 0]);

  const keyFeatures = [
    {
      icon: HiOutlineGlobe,
      emoji: "🌟",
      title: "One Link Does It All",
      description: "No more juggling multiple links! Share everything about you with just one simple link that looks great everywhere."
    },
    {
      icon: HiOutlineUserCircle,
      emoji: "✨",
      title: "Show Off Your Best Self",
      description: "Create an awesome page that lets your personality shine through. It's like your own corner of the internet, but way cooler!"
    },
    {
      icon: HiShare,
      emoji: "🚀",
      title: "See Your Network Grow",
      description: "Watch your connections come to life with our fun Network Map. Discover amazing people and opportunities just waiting to be found!"
    },
    {
      icon: HiOutlinePuzzle,
      emoji: "🎮",
      title: "Build It Your Way",
      description: "Mix and match different blocks to create your perfect page. It's like LEGO, but for your online presence!"
    },
    {
      icon: HiOutlineLink,
      emoji: "🎯",
      title: "Everything in One Spot",
      description: "Bring all your social media, projects, and cool stuff together. No more sending people to a dozen different places!"
    },
    {
      icon: HiColorSwatch,
      emoji: "🎨",
      title: "Make It Pop",
      description: "Pick colors, themes, and layouts that match your vibe. Your page should be as unique as you are!"
    }
  ];

  const handleGetStarted = () => {
    if (user) {
      navigate('/dashboard');
    } else {
      onAuthClick('register');
    }
  };

  const fadeInUp = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 }
  };

  const staggerContainer = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.2
      }
    }
  };

  const cardHover = {
    scale: 1.05,
    transition: { duration: 0.2 }
  };

  const floatingAnimation = {
    y: [0, -10, 0],
    transition: {
      duration: 3,
      repeat: Infinity,
      ease: "easeInOut"
    }
  };

  return (
    <div className="relative bg-gradient-to-b from-purple-50/50 via-white to-blue-50/50 dark:from-gray-900 dark:via-gray-900 dark:to-gray-900 min-h-screen">
      {/* Animated background shapes */}
      <div className="absolute inset-0 overflow-hidden pointer-events-none">
        <div className="absolute -top-1/2 -right-1/2 w-full h-full bg-gradient-to-b from-purple-200/20 to-transparent dark:from-purple-500/10 rounded-full blur-3xl transform rotate-12 animate-pulse" />
        <div className="absolute -bottom-1/2 -left-1/2 w-full h-full bg-gradient-to-t from-blue-200/20 to-transparent dark:from-blue-500/10 rounded-full blur-3xl transform -rotate-12 animate-pulse" />
      </div>

      <motion.div 
        initial="hidden"
        animate="visible"
        className="relative max-w-7xl mx-auto px-4 sm:px-6 py-8 sm:py-12"
      >
        {/* Hero Section */}
        <motion.div 
          variants={fadeInUp}
          className="relative min-h-[80vh] sm:min-h-screen flex items-center justify-center mb-12 sm:mb-20"
        >
          <div className="text-center relative z-10 px-4 sm:px-6">
            <motion.div 
              initial={{ opacity: 0, scale: 0.8 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.8 }}
              className="mb-8"
            >
              <div className="inline-block p-2 px-6 rounded-full bg-gradient-to-r from-purple-600/10 to-blue-600/10 dark:from-purple-400/10 dark:to-blue-400/10 backdrop-blur-sm mb-6">
                <span className="text-transparent bg-clip-text bg-gradient-to-r from-purple-600 to-blue-600 dark:from-purple-400 dark:to-blue-400 font-semibold">
                  Hey there! 👋 Welcome to Agreebits
                </span>
              </div>
            </motion.div>
            
            <motion.h1 
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.6 }}
              className="text-4xl sm:text-6xl md:text-7xl font-bold mb-4 sm:mb-6 leading-tight text-gray-900 dark:text-white"
            >
              Your Online Home,{' '}
              <motion.span
                animate={floatingAnimation}
                className="inline-block bg-clip-text text-transparent bg-gradient-to-r from-purple-600 via-blue-600 to-purple-600 dark:from-purple-400 dark:via-blue-400 dark:to-purple-400 bg-size-200 animate-gradient"
              >
                Made Awesome
              </motion.span>
            </motion.h1>
            <motion.p 
              variants={fadeInUp}
              className="text-lg sm:text-xl md:text-2xl text-gray-600 dark:text-gray-300 max-w-3xl mx-auto leading-relaxed mb-8 sm:mb-12 px-4"
            >
              Finally! A fun way to bring all your cool stuff together in one gorgeous page. No more messy links - just you, being awesome, all in one place.
            </motion.p>
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 0.5 }}
              className="flex justify-center gap-6 px-4"
            >
              <motion.button
                whileHover={{ scale: 1.05, boxShadow: "0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04)" }}
                whileTap={{ scale: 0.95 }}
                onClick={handleGetStarted}
                className="w-full sm:w-auto inline-flex items-center justify-center px-8 py-4 text-lg font-semibold text-white bg-gradient-to-r from-purple-600 via-blue-600 to-purple-600 dark:from-purple-500 dark:via-blue-500 dark:to-purple-500 rounded-full hover:shadow-lg hover:shadow-purple-500/25 dark:hover:shadow-purple-500/10 transition-all duration-200 bg-size-200 animate-gradient"
              >
                Let's Get Started!
                <motion.div
                  animate={{ x: [0, 5, 0] }}
                  transition={{ repeat: Infinity, duration: 1.5 }}
                >
                  <HiArrowRight className="ml-2 h-5 w-5" />
                </motion.div>
              </motion.button>
            </motion.div>
          </div>
        </motion.div>

        {/* Features Section */}
        <motion.section 
          variants={staggerContainer}
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, margin: "-100px" }}
          className="mb-12 sm:mb-20 relative px-4 sm:px-6"
        >
          <motion.h2 
            variants={fadeInUp}
            className="text-3xl sm:text-4xl md:text-5xl font-bold mb-8 sm:mb-12 text-center text-gray-900 dark:text-white"
          >
            Why You'll{' '}
            <span className="text-transparent bg-clip-text bg-gradient-to-r from-purple-600 to-blue-600 dark:from-purple-400 dark:to-blue-400">
              Love It Here
            </span>
          </motion.h2>
          <motion.div 
            variants={staggerContainer}
            className="grid sm:grid-cols-2 lg:grid-cols-3 gap-6 sm:gap-8"
          >
            {keyFeatures.map((feature, index) => (
              <motion.div
                key={index}
                variants={fadeInUp}
                whileHover={cardHover}
                className="group backdrop-blur-xl bg-white/80 dark:bg-gray-800/80 rounded-2xl p-8 shadow-xl hover:shadow-2xl transition-all duration-300 border border-gray-100/50 dark:border-gray-700/50"
              >
                <div className="flex items-center mb-4">
                  <motion.div 
                    whileHover={{ rotate: 360 }}
                    transition={{ duration: 0.6 }}
                    className="relative p-3 bg-gradient-to-br from-purple-100 to-blue-100 dark:from-purple-900 dark:to-blue-900 rounded-xl group-hover:from-purple-200 group-hover:to-blue-200 dark:group-hover:from-purple-800 dark:group-hover:to-blue-800 transition-colors duration-300"
                  >
                    <feature.icon className="h-8 w-8 text-purple-600 dark:text-purple-400" />
                    <span className="absolute -top-2 -right-2 text-2xl transform rotate-12 group-hover:rotate-0 transition-transform duration-300">
                      {feature.emoji}
                    </span>
                  </motion.div>
                  <h3 className="text-2xl font-semibold text-gray-900 dark:text-white ml-4 group-hover:text-transparent group-hover:bg-clip-text group-hover:bg-gradient-to-r group-hover:from-purple-600 group-hover:to-blue-600 dark:group-hover:from-purple-400 dark:group-hover:to-blue-400 transition-all duration-300">
                    {feature.title}
                  </h3>
                </div>
                <p className="text-lg text-gray-600 dark:text-gray-300 leading-relaxed">
                  {feature.description}
                </p>
              </motion.div>
            ))}
          </motion.div>
        </motion.section>

        {/* CTA Section */}
        <motion.section 
          variants={fadeInUp}
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          className="relative overflow-hidden px-4 sm:px-6"
        >
          <div className="relative bg-gradient-to-r from-purple-600 to-blue-600 dark:from-purple-500 dark:to-blue-500 rounded-3xl p-2">
            <div className="absolute inset-0 bg-gradient-to-r from-purple-600 to-blue-600 dark:from-purple-500 dark:to-blue-500 opacity-75 animate-gradient bg-size-200" />
            <div className="relative bg-black/10 backdrop-blur-sm rounded-2xl p-8 sm:p-16 text-center">
              <motion.h2 
                variants={fadeInUp}
                className="text-3xl sm:text-4xl md:text-5xl font-bold text-white mb-6"
              >
                Ready to Make Some Magic? ✨
              </motion.h2>
              <motion.p 
                variants={fadeInUp}
                className="text-xl text-purple-100 mb-8 max-w-2xl mx-auto"
              >
                Join thousands of awesome people who are already rocking their online presence with Agreebits! 🚀
              </motion.p>
              <motion.button
                whileHover={{ scale: 1.05, boxShadow: "0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04)" }}
                whileTap={{ scale: 0.95 }}
                onClick={handleGetStarted}
                className="w-full sm:w-auto inline-flex items-center justify-center px-8 py-4 text-lg font-semibold text-purple-600 dark:text-purple-500 bg-white rounded-full hover:bg-gray-50 transition-all duration-200 hover:shadow-lg hover:shadow-purple-500/25 dark:hover:shadow-purple-500/10"
              >
                Start Creating - It's Free! 🎨
                <motion.div
                  animate={{ x: [0, 5, 0] }}
                  transition={{ repeat: Infinity, duration: 1.5 }}
                >
                  <HiArrowRight className="ml-2 h-5 w-5" />
                </motion.div>
              </motion.button>
            </div>
          </div>
        </motion.section>
      </motion.div>
    </div>
  );
}

export default About;
