const getDistance = (pos1, pos2) => {
    const dx = pos1.x - pos2.x;
    const dy = pos1.y - pos2.y;
    return Math.sqrt(dx * dx + dy * dy);
};

export const calculateNodePosition = (seed, usedPositions, dimensions, maxRadius, minDistance) => {
    const rand = () => {
        seed = (seed * 9301 + 49297) % 233280;
        return seed / 233280;
    };

    let attempts = 0;
    const maxAttempts = 100;

    // Calculate optimal spacing based on the number of existing positions
    const nodeCount = usedPositions.length;
    const spacing = Math.max(minDistance, maxRadius * 0.2);
    const angleStep = (2 * Math.PI) / Math.max(6, nodeCount);
    
    // Use golden ratio for better distribution
    const goldenRatio = 1.61803398875;
    const baseAngle = 2 * Math.PI * goldenRatio;

    while (attempts < maxAttempts) {
        // Calculate radius with some variation
        const radiusBase = maxRadius * (0.4 + 0.3 * rand());
        const radiusVariation = maxRadius * 0.1 * (rand() - 0.5);
        const radius = radiusBase + radiusVariation;

        // Calculate angle with structured variation
        const angleBase = attempts * baseAngle;
        const angleVariation = angleStep * 0.5 * (rand() - 0.5);
        const angle = angleBase + angleVariation;

        // Calculate position
        const x = radius * Math.cos(angle);
        const y = radius * Math.sin(angle);

        // Add slight randomness to break symmetry
        const jitter = spacing * 0.1;
        const position = {
            x: x + jitter * (rand() - 0.5),
            y: y + jitter * (rand() - 0.5)
        };

        // Check if position is valid
        const isFarEnough = usedPositions.every(pos => {
            const dist = getDistance(pos, position);
            // Use dynamic spacing based on radius
            const requiredDistance = spacing * (1 - 0.1 * (radius / maxRadius));
            return dist >= requiredDistance;
        });

        if (isFarEnough) {
            return position;
        }

        attempts++;
        seed = seed * 7919;
    }

    // Fallback: structured placement along a spiral
    const t = (usedPositions.length * goldenRatio) % 1;
    const theta = 2 * Math.PI * t;
    const radiusScale = 0.1 + 0.8 * (usedPositions.length / maxAttempts);
    const fallbackRadius = maxRadius * radiusScale;

    return {
        x: fallbackRadius * Math.cos(theta),
        y: fallbackRadius * Math.sin(theta)
    };
};

export const getNodeSize = (type, dimensions) => {
    // Base size adjusted for screen size
    const baseSize = Math.min(dimensions.width, dimensions.height) * 0.01;
    
    // Scale factors for different node types
    const scales = {
        you: 1.4,
        direct: 1.1,
        secondary: 0.9,
        other: 0.7
    };

    // Get scale based on type
    const scale = scales[type] || scales.other;

    // Calculate final size
    return Math.max(
        6, // minimum size
        Math.min(
            baseSize * scale,
            dimensions.width <= 768 ? 14 : 18 // maximum size
        )
    );
};

export const getFontSize = (dimensions) => {
    // Dynamic font size based on screen size with much smaller values
    const baseFontSize = Math.min(dimensions.width, dimensions.height) * 0.008; // Significantly reduced from 0.015
    return Math.max(
        6, // Reduced minimum font size from 8
        Math.min(
            baseFontSize,
            dimensions.width <= 768 ? 7 : 10 // Significantly reduced maximum font sizes
        )
    );
};
