import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { HiUserAdd } from 'react-icons/hi';
import {
  userService,
  socialLinksService,
  resumeService,
  connectionsService,
  paymentLinksService,
  extraLinksService,
  socialStatsService
} from '../services/firestore';
import ProfileSkeleton from '../components/profile/ProfileSkeleton';
import ProfileBanner from '../components/profile/ProfileBanner';
import ProfilePaymentLinks from '../components/profile/ProfilePaymentLinks';
import ProfileExtraLinks from '../components/profile/ProfileExtraLinks';
import SocialStatsModule from '../components/profile/ProfileSocialStatsReadOnly';
import ProfileResume from '../components/profile/ProfileResume';
import ProfileTeams from '../components/profile/ProfileTeams';
import { useAuth } from '../contexts/AuthContext';
import { useAuthModal } from '../contexts/AuthModalContext';

const MinimalUserProfile = () => {
  const { username } = useParams();
  const navigate = useNavigate();
  const { user: currentUser } = useAuth();
  const onAuthClick = useAuthModal();
  const [user, setUser] = useState(null);
  const [userModules, setUserModules] = useState({});
  const [resumeData, setResumeData] = useState(null);
  const [paymentLinks, setPaymentLinks] = useState([]);
  const [extraLinks, setExtraLinks] = useState([]);
  const [socialStats, setSocialStats] = useState({
    instagram: null,
    twitter: null,
    youtube: null,
    soundcloud: null
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [connectionStatus, setConnectionStatus] = useState('none');
  const [connectionId, setConnectionId] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const [isRequestReceiver, setIsRequestReceiver] = useState(false);

  // Connection handlers
  const handleConnect = async () => {
    try {
      setIsProcessing(true);
      const success = await connectionsService.sendRequest(currentUser.uid, user.id);
      if (success) {
        setConnectionStatus('pending');
        setConnectionId(`${currentUser.uid}_${user.id}`);
        setIsRequestReceiver(false);
      }
    } catch (error) {
      setError(error.message);
    } finally {
      setIsProcessing(false);
    }
  };

  const handleAccept = async () => {
    try {
      setIsProcessing(true);
      const success = await connectionsService.acceptRequest(connectionId);
      if (success) {
        setConnectionStatus('connected');
      }
    } catch (error) {
      setError(error.message);
    } finally {
      setIsProcessing(false);
    }
  };

  const handleDecline = async () => {
    try {
      setIsProcessing(true);
      const success = await connectionsService.declineRequest(connectionId);
      if (success) {
        setConnectionStatus('none');
        setConnectionId(null);
      }
    } catch (error) {
      setError(error.message);
    } finally {
      setIsProcessing(false);
    }
  };

  const handleRemove = async () => {
    try {
      setIsProcessing(true);
      const success = await connectionsService.removeConnection(connectionId);
      if (success) {
        setConnectionStatus('none');
        setConnectionId(null);
      }
    } catch (error) {
      setError(error.message);
    } finally {
      setIsProcessing(false);
    }
  };

  const handleLoginRedirect = () => {
    if (onAuthClick) {
      onAuthClick('login');
    } else {
      navigate('/login', { state: { from: `/user/${username}` } });
    }
  };

  // Get connection button configuration based on status and auth state
  const getConnectionButton = () => {
    const baseClasses = "w-full sm:w-auto mx-auto flex items-center justify-center gap-2 px-6 py-2.5 rounded-lg font-medium shadow-sm transition-all duration-200";
    
    if (!currentUser) {
      return {
        onClick: handleLoginRedirect,
        icon: HiUserAdd,
        text: "Add Connection",
        classes: `${baseClasses} bg-blue-500 hover:bg-blue-600 text-white`
      };
    }

    if (currentUser?.username === username) {
      return null;
    }
    
    switch (connectionStatus) {
      case 'connected':
        return {
          onClick: handleRemove,
          icon: HiUserAdd,
          text: "Remove Connection",
          classes: `${baseClasses} bg-red-500 hover:bg-red-600 text-white`
        };
      case 'pending':
        return {
          onClick: null,
          icon: HiUserAdd,
          text: "Connection Pending",
          classes: `${baseClasses} bg-yellow-500 hover:bg-yellow-600 text-white cursor-not-allowed`
        };
      default:
        return {
          onClick: handleConnect,
          icon: HiUserAdd,
          text: "Add Connection",
          classes: `${baseClasses} bg-blue-500 hover:bg-blue-600 text-white`
        };
    }
  };

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        setLoading(true);
        setError(null);

        // Get user profile
        const userProfile = await userService.getUserProfile(username);
        if (!userProfile) {
          setError('User not found');
          return;
        }

        // Get social links
        const socialLinksData = await socialLinksService.getUserSocialLinks(username);
        const formattedSocialLinks = Object.values(socialLinksData?.links || {})
          .filter(link => link?.url?.trim())
          .map(link => ({
            url: link.url.trim().startsWith('http') ? link.url.trim() : `https://${link.url.trim()}`
          }));

        // Set user with social links and default theme if not set
        const defaultTheme = {
          colors: {
            primary: '#3B82F6', // Default blue
            secondary: '#6B7280', // Default gray
            background: '#FFFFFF', // Default white
            text: '#111827', // Default dark text
            accent: '#F59E0B' // Default amber
          },
          font: {
            heading: 'Inter, sans-serif',
            body: 'Inter, sans-serif'
          }
        };
      
        setUser({
          ...userProfile,
          socialLinks: formattedSocialLinks,
          theme: userProfile.theme || defaultTheme
        });

        // Set modules and module order
        const defaultModules = {
          resume: false,
          payment: false,
          extraLinks: false,
          socialStats: false
        };

        const defaultOrder = ['resume', 'payment', 'extraLinks', 'socialStats'];

        setUserModules({
          modules: userProfile.modules || defaultModules,
          moduleOrder: Array.isArray(userProfile.moduleOrder) ?
            userProfile.moduleOrder.filter(name => name in defaultModules || name === 'socialStats') :
            defaultOrder
        });

        // Only fetch connection status if user is logged in
        if (currentUser) {
          try {
            const connections = await connectionsService.getConnections(currentUser.uid);
            const connection = connections.find(
              conn => conn.from === userProfile.id || conn.to === userProfile.id
            );
            
            if (connection) {
              setConnectionId(connection.id);
              setConnectionStatus(connection.status === 'accepted' ? 'connected' : 'pending');
              setIsRequestReceiver(connection.to === userProfile.id);
            }
          } catch (error) {
            console.error('Error fetching connection status:', error);
          }
        }

        // Fetch resume data
        try {
          const resumeData = await resumeService.getResume(username);
          if (resumeData && Object.values(resumeData).some(section => section?.length > 0)) {
            setResumeData(resumeData);
          }
        } catch (error) {
          console.error('Error fetching resume:', error);
        }

        // Fetch payment links
        try {
          const paymentLinksData = await paymentLinksService.getPaymentLinks(username);
          setPaymentLinks(paymentLinksData || []);
        } catch (error) {
          console.error('Error fetching payment links:', error);
        }

        // Fetch extra links
        try {
          const extraLinksData = await extraLinksService.getExtraLinks(username);
          setExtraLinks(extraLinksData || []);
        } catch (error) {
          console.error('Error fetching extra links:', error);
        }

        // Fetch social stats
        const platforms = ['instagram', 'twitter', 'youtube', 'soundcloud'];
        const statsPromises = platforms.map(async platform => {
          try {
            const stats = await socialStatsService.getStats(userProfile.id, platform);
            if (stats) {
              return { platform, stats };
            }
          } catch (error) {
            console.error(`Error fetching ${platform} stats:`, error);
          }
          return { platform, stats: null };
        });

        const results = await Promise.all(statsPromises);
        const newStats = results.reduce((acc, { platform, stats }) => {
          acc[platform] = stats;
          return acc;
        }, {});
        
        setSocialStats(newStats);

      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    if (username) {
      fetchUserData();
    }
  }, [username, currentUser]);

  if (loading) {
    return <ProfileSkeleton />;
  }

  if (error) {
    return (
      <div className="flex justify-center items-center min-h-[80vh]">
        <div className="bg-red-50 dark:bg-red-900/20 text-red-600 dark:text-red-400 px-6 py-4 rounded-lg shadow-sm">
          {error}
        </div>
      </div>
    );
  }

  if (!user) {
    return (
      <div className="flex justify-center items-center min-h-[80vh]">
        <div className="bg-gray-50 dark:bg-gray-800 text-gray-600 dark:text-gray-400 px-6 py-4 rounded-lg shadow-sm">
          User not found
        </div>
      </div>
    );
  }

  const connectionButton = getConnectionButton();

  return (
    <div
      className="max-w-2xl mx-auto px-4 py-8"
      style={{
        backgroundColor: user.theme?.colors?.background || '#FFFFFF',
        color: user.theme?.colors?.text || '#111827',
        fontFamily: user.theme?.font?.body || 'Inter, sans-serif'
      }}
    >
      {/* Banner section */}
      <div style={{
        backgroundColor: user.theme?.colors?.background || '#FFFFFF',
        color: user.theme?.colors?.text || '#111827'
      }}>
        <ProfileBanner
          user={user}
          connectionButton={connectionButton}
          connectionStatus={connectionStatus}
          connectionId={connectionId}
          isRequestReceiver={isRequestReceiver}
          isProcessing={isProcessing}
          handleAccept={handleAccept}
          handleDecline={handleDecline}
          company={user.company}
          about={user.about}
          theme={user.theme}
        />
      </div>

      {/* Modules Section */}
      {userModules.moduleOrder?.map((moduleName, index) => {
        const marginClass = index === 0 ? "mt-8" : "mt-6";
        
        // Skip disabled modules, but let socialStats through for its own visibility check
        if (moduleName !== 'socialStats' && !userModules.modules[moduleName]) {
          return null;
        }

        switch (moduleName) {
          case 'socialStats':
            if (!userModules.modules.socialStats) return null;
            return (
              <div key={moduleName} className={marginClass}>
                <SocialStatsModule
                  allStats={socialStats}
                  theme={user.theme}
                />
              </div>
            );
          case 'extraLinks':
            return (
              <div key={moduleName} className={marginClass}>
                <ProfileExtraLinks
                  extraLinks={extraLinks}
                  userModules={userModules.modules}
                  theme={user.theme}
                />
              </div>
            );
          case 'resume':
            return (
              <div key={moduleName} className={marginClass}>
                <ProfileResume
                  resumeData={resumeData}
                  userModules={userModules.modules}
                  theme={user.theme}
                />
              </div>
            );
          case 'payment':
            return (
              <div key={moduleName} className={marginClass}>
                <ProfilePaymentLinks
                  paymentLinks={paymentLinks}
                  userModules={userModules.modules}
                  theme={user.theme}
                />
              </div>
            );
          default:
            return null;
        }
      })}

      {/* Teams section is always at the end */}
      <div className="mt-6">
        <ProfileTeams
          teams={user.teams}
        />
      </div>
    </div>
  );
};

export default MinimalUserProfile;
