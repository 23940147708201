import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

function ForgotPassword() {
  const navigate = useNavigate();

  useEffect(() => {
    // Redirect to login page with forgot-password mode
    navigate('/login', { 
      state: { 
        authMode: 'forgot-password'
      }
    });
  }, [navigate]);

  return null; // Component will redirect immediately
}

export default ForgotPassword;
