import React from 'react';
import { useParams, Link } from 'react-router-dom';
import { FaArrowLeft, FaCalendar, FaUsers, FaCheckSquare, FaComments } from 'react-icons/fa';

const ProjectPage = () => {
  const { projectId } = useParams();

  // This would typically come from an API or context
  const projectData = {
    name: 'Project Alpha',
    status: 'In Progress',
    deadline: '2024-03-15',
    description: 'A comprehensive project aimed at developing innovative solutions for our clients.',
    progress: 65,
    team: [
      { name: 'John Doe', role: 'Project Lead' },
      { name: 'Jane Smith', role: 'Developer' },
      { name: 'Mike Johnson', role: 'Designer' },
    ],
    tasks: [
      { id: 1, title: 'Design System Implementation', status: 'Completed' },
      { id: 2, title: 'Frontend Development', status: 'In Progress' },
      { id: 3, title: 'Backend Integration', status: 'Pending' },
    ],
    updates: [
      { date: '2024-01-15', content: 'Completed initial design phase' },
      { date: '2024-01-10', content: 'Team meeting - discussed project timeline' },
      { date: '2024-01-05', content: 'Project kickoff' },
    ]
  };

  return (
    <div className="max-w-6xl mx-auto px-4 py-6">
      {/* Header */}
      <div className="flex items-center mb-6">
        <Link to="/profile" className="text-gray-600 hover:text-gray-800 dark:text-gray-400 dark:hover:text-gray-200">
          <FaArrowLeft className="w-5 h-5" />
        </Link>
        <h1 className="text-2xl font-bold ml-4 text-gray-800 dark:text-gray-100">{projectData.name}</h1>
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
        {/* Main Content */}
        <div className="lg:col-span-2 space-y-6">
          {/* Project Overview */}
          <div className="bg-white dark:bg-gray-800 rounded-xl shadow-lg p-6">
            <h2 className="text-xl font-semibold mb-4 text-gray-800 dark:text-gray-100">Project Overview</h2>
            <p className="text-gray-600 dark:text-gray-400 mb-4">{projectData.description}</p>
            
            {/* Progress Bar */}
            <div className="mt-4">
              <div className="flex justify-between mb-2">
                <span className="text-sm font-medium text-gray-600 dark:text-gray-400">Progress</span>
                <span className="text-sm font-medium text-gray-600 dark:text-gray-400">{projectData.progress}%</span>
              </div>
              <div className="w-full bg-gray-200 rounded-full h-2.5 dark:bg-gray-700">
                <div 
                  className="bg-blue-600 h-2.5 rounded-full"
                  style={{ width: `${projectData.progress}%` }}
                ></div>
              </div>
            </div>
          </div>

          {/* Tasks */}
          <div className="bg-white dark:bg-gray-800 rounded-xl shadow-lg p-6">
            <h2 className="text-xl font-semibold mb-4 flex items-center text-gray-800 dark:text-gray-100">
              <FaCheckSquare className="mr-2" />
              Tasks
            </h2>
            <div className="space-y-4">
              {projectData.tasks.map(task => (
                <div 
                  key={task.id}
                  className="flex items-center justify-between p-4 border border-gray-200 dark:border-gray-700 rounded-lg"
                >
                  <span className="text-gray-800 dark:text-gray-200">{task.title}</span>
                  <span className={`px-3 py-1 rounded-full text-sm ${
                    task.status === 'Completed' ? 'bg-green-100 text-green-800 dark:bg-green-900 dark:text-green-200' :
                    task.status === 'In Progress' ? 'bg-blue-100 text-blue-800 dark:bg-blue-900 dark:text-blue-200' :
                    'bg-gray-100 text-gray-800 dark:bg-gray-700 dark:text-gray-200'
                  }`}>
                    {task.status}
                  </span>
                </div>
              ))}
            </div>
          </div>

          {/* Updates */}
          <div className="bg-white dark:bg-gray-800 rounded-xl shadow-lg p-6">
            <h2 className="text-xl font-semibold mb-4 flex items-center text-gray-800 dark:text-gray-100">
              <FaComments className="mr-2" />
              Recent Updates
            </h2>
            <div className="space-y-4">
              {projectData.updates.map((update, index) => (
                <div key={index} className="border-b border-gray-200 dark:border-gray-700 last:border-0 pb-4 last:pb-0">
                  <div className="text-sm text-gray-500 dark:text-gray-400">{update.date}</div>
                  <div className="mt-1 text-gray-700 dark:text-gray-300">{update.content}</div>
                </div>
              ))}
            </div>
          </div>
        </div>

        {/* Sidebar */}
        <div className="space-y-6">
          {/* Project Details */}
          <div className="bg-white dark:bg-gray-800 rounded-xl shadow-lg p-6">
            <h2 className="text-xl font-semibold mb-4 text-gray-800 dark:text-gray-100">Details</h2>
            <div className="space-y-4">
              <div className="flex items-center">
                <FaCalendar className="w-5 h-5 text-gray-500 dark:text-gray-400" />
                <span className="ml-2 text-gray-600 dark:text-gray-400">
                  Deadline: {new Date(projectData.deadline).toLocaleDateString()}
                </span>
              </div>
              <div className="flex items-center">
                <div className={`px-3 py-1 rounded-full text-sm ${
                  projectData.status === 'In Progress' ? 'bg-blue-100 text-blue-800 dark:bg-blue-900 dark:text-blue-200' :
                  'bg-gray-100 text-gray-800 dark:bg-gray-700 dark:text-gray-200'
                }`}>
                  {projectData.status}
                </div>
              </div>
            </div>
          </div>

          {/* Team Members */}
          <div className="bg-white dark:bg-gray-800 rounded-xl shadow-lg p-6">
            <h2 className="text-xl font-semibold mb-4 flex items-center text-gray-800 dark:text-gray-100">
              <FaUsers className="mr-2" />
              Team
            </h2>
            <div className="space-y-4">
              {projectData.team.map((member, index) => (
                <div key={index} className="flex items-center space-x-3">
                  <div className="w-8 h-8 bg-gray-300 dark:bg-gray-600 rounded-full"></div>
                  <div>
                    <div className="text-gray-800 dark:text-gray-200">{member.name}</div>
                    <div className="text-sm text-gray-500 dark:text-gray-400">{member.role}</div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProjectPage;
