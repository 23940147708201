// Pattern utilities
export const getPatternBackground = (pattern, ctx, size) => {
    switch (pattern) {
        case 'dots':
            ctx.fillStyle = 'rgba(255,255,255,0.15)';
            for (let x = -size; x <= size; x += 5) {
                for (let y = -size; y <= size; y += 5) {
                    ctx.beginPath();
                    ctx.arc(x, y, 0.5, 0, 2 * Math.PI);
                    ctx.fill();
                }
            }
            break;
        case 'lines':
            ctx.strokeStyle = 'rgba(255,255,255,0.15)';
            ctx.lineWidth = 0.5;
            for (let y = -size; y <= size; y += 5) {
                ctx.beginPath();
                ctx.moveTo(-size, y);
                ctx.lineTo(size, y);
                ctx.stroke();
            }
            break;
        case 'grid':
            ctx.strokeStyle = 'rgba(255,255,255,0.15)';
            ctx.lineWidth = 0.5;
            for (let x = -size; x <= size; x += 5) {
                ctx.beginPath();
                ctx.moveTo(x, -size);
                ctx.lineTo(x, size);
                ctx.stroke();
            }
            for (let y = -size; y <= size; y += 5) {
                ctx.beginPath();
                ctx.moveTo(-size, y);
                ctx.lineTo(size, y);
                ctx.stroke();
            }
            break;
        case 'diagonal':
            ctx.strokeStyle = 'rgba(255,255,255,0.15)';
            ctx.lineWidth = 0.5;
            for (let i = -size * 2; i <= size * 2; i += 5) {
                ctx.beginPath();
                ctx.moveTo(i, -size);
                ctx.lineTo(i + size * 2, size);
                ctx.stroke();
                ctx.beginPath();
                ctx.moveTo(i, -size);
                ctx.lineTo(i - size * 2, size);
                ctx.stroke();
            }
            break;
    }
};

export const getColors = (isDarkMode) => ({
    you: {
        primary: '#6366F1',
        gradient: (ctx, size, theme) => {
            if (theme) {
                const gradient = theme.gradientType === 'linear'
                    ? ctx.createLinearGradient(-size, -size, size, size)
                    : ctx.createRadialGradient(0, 0, 0, 0, 0, size);
                gradient.addColorStop(0, theme.primaryColor);
                gradient.addColorStop(1, theme.secondaryColor);
                return {
                    gradient,
                    pattern: theme.pattern
                };
            }
            const gradient = ctx.createRadialGradient(0, 0, 0, 0, 0, size);
            gradient.addColorStop(0, '#818CF8');
            gradient.addColorStop(1, '#4F46E5');
            return { gradient };
        }
    },
    connected: {
        primary: '#EC4899',
        gradient: (ctx, size, theme) => {
            if (theme) {
                const gradient = theme.gradientType === 'linear'
                    ? ctx.createLinearGradient(-size, -size, size, size)
                    : ctx.createRadialGradient(0, 0, 0, 0, 0, size);
                gradient.addColorStop(0, theme.primaryColor);
                gradient.addColorStop(1, theme.secondaryColor);
                return {
                    gradient,
                    pattern: theme.pattern
                };
            }
            const gradient = ctx.createRadialGradient(0, 0, 0, 0, 0, size);
            gradient.addColorStop(0, '#F472B6');
            gradient.addColorStop(1, '#DB2777');
            return { gradient };
        }
    },
    secondary: {
        primary: isDarkMode ? '#10B981' : '#059669',
        gradient: (ctx, size) => {
            const gradient = ctx.createRadialGradient(0, 0, 0, 0, 0, size);
            gradient.addColorStop(0, isDarkMode ? '#34D399' : '#10B981');
            gradient.addColorStop(1, isDarkMode ? '#059669' : '#047857');
            return { gradient };
        }
    },
    expanded: {
        primary: isDarkMode ? '#F59E0B' : '#D97706',
        gradient: (ctx, size) => {
            const gradient = ctx.createRadialGradient(0, 0, 0, 0, 0, size);
            gradient.addColorStop(0, isDarkMode ? '#FBBF24' : '#F59E0B');
            gradient.addColorStop(1, isDarkMode ? '#D97706' : '#B45309');
            return { gradient };
        }
    },
    other: {
        primary: isDarkMode ? '#4B5563' : '#9CA3AF',
        gradient: (ctx, size) => {
            const gradient = ctx.createRadialGradient(0, 0, 0, 0, 0, size);
            gradient.addColorStop(0, isDarkMode ? '#6B7280' : '#D1D5DB');
            gradient.addColorStop(1, isDarkMode ? '#374151' : '#6B7280');
            return { gradient };
        }
    }
});

export const getLinkColors = (isDarkMode) => ({
    direct: isDarkMode ? '#F472B6' : '#EC4899',
    secondary: isDarkMode ? '#34D399' : '#10B981',
    unselected: isDarkMode ? '#4B5563' : '#E5E7EB' // New gray color for unselected connections
});
