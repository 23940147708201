import React, { useState } from 'react';
import { useDrop } from 'react-dnd';
import { 
  HiOutlineTrash, 
  HiOutlineArrowUp, 
  HiOutlineArrowDown,
  HiOutlineCog
} from 'react-icons/hi';

const TemplateCanvas = ({ components, onUpdate }) => {
  const [selectedComponent, setSelectedComponent] = useState(null);

  const [{ isOver }, drop] = useDrop({
    accept: 'TEMPLATE_COMPONENT',
    drop: (item, monitor) => {
      const didDrop = monitor.didDrop();
      if (didDrop) {
        return;
      }

      onUpdate([
        ...components,
        {
          id: Date.now(),
          type: item.type,
          content: '',
          settings: getDefaultSettings(item.type)
        }
      ]);
    },
    collect: (monitor) => ({
      isOver: monitor.isOver({ shallow: true }),
    }),
  });

  const getDefaultSettings = (type) => {
    switch (type) {
      case 'text':
        return { fontSize: '16px', fontWeight: 'normal', required: false };
      case 'input':
        return { placeholder: 'Enter text...', required: false };
      case 'signature':
        return { label: 'Signature', required: true };
      case 'date':
        return { format: 'MM/DD/YYYY', required: false };
      case 'checkbox':
        return { options: [], required: false };
      case 'dropdown':
        return { options: [], required: false };
      default:
        return {};
    }
  };

  const handleComponentClick = (id) => {
    setSelectedComponent(id);
  };

  const handleDelete = (id) => {
    onUpdate(components.filter(comp => comp.id !== id));
    if (selectedComponent === id) {
      setSelectedComponent(null);
    }
  };

  const handleMoveUp = (index) => {
    if (index === 0) return;
    const newComponents = [...components];
    [newComponents[index - 1], newComponents[index]] = [newComponents[index], newComponents[index - 1]];
    onUpdate(newComponents);
  };

  const handleMoveDown = (index) => {
    if (index === components.length - 1) return;
    const newComponents = [...components];
    [newComponents[index], newComponents[index + 1]] = [newComponents[index + 1], newComponents[index]];
    onUpdate(newComponents);
  };

  const renderComponent = (component, index) => {
    const isSelected = selectedComponent === component.id;

    return (
      <div
        key={component.id}
        className={`
          relative p-4 mb-4 rounded-lg
          ${isSelected ? 'ring-2 ring-blue-500' : 'hover:ring-2 hover:ring-blue-200'}
          bg-white dark:bg-gray-800
          border border-gray-200 dark:border-gray-700
          transition-all duration-200
        `}
        onClick={() => handleComponentClick(component.id)}
      >
        {/* Component Preview */}
        <div className="mb-2">
          {/* Add component specific rendering here */}
          <div className="text-sm text-gray-600 dark:text-gray-400">
            {component.type.charAt(0).toUpperCase() + component.type.slice(1)} Component
          </div>
        </div>

        {/* Component Actions */}
        {isSelected && (
          <div className="absolute top-2 right-2 flex space-x-2">
            <button
              onClick={(e) => {
                e.stopPropagation();
                handleMoveUp(index);
              }}
              className="p-1 text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-200"
              disabled={index === 0}
            >
              <HiOutlineArrowUp className="w-4 h-4" />
            </button>
            <button
              onClick={(e) => {
                e.stopPropagation();
                handleMoveDown(index);
              }}
              className="p-1 text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-200"
              disabled={index === components.length - 1}
            >
              <HiOutlineArrowDown className="w-4 h-4" />
            </button>
            <button
              onClick={(e) => {
                e.stopPropagation();
                // TODO: Open settings modal/panel
              }}
              className="p-1 text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-200"
            >
              <HiOutlineCog className="w-4 h-4" />
            </button>
            <button
              onClick={(e) => {
                e.stopPropagation();
                handleDelete(component.id);
              }}
              className="p-1 text-red-500 hover:text-red-700 dark:text-red-400 dark:hover:text-red-200"
            >
              <HiOutlineTrash className="w-4 h-4" />
            </button>
          </div>
        )}
      </div>
    );
  };

  return (
    <div
      ref={drop}
      className={`
        min-h-[600px] p-6 rounded-lg
        ${isOver ? 'bg-blue-50 dark:bg-blue-900/20' : 'bg-gray-50 dark:bg-gray-900/50'}
        border-2 border-dashed
        ${isOver ? 'border-blue-300 dark:border-blue-700' : 'border-gray-300 dark:border-gray-700'}
        transition-colors duration-200
      `}
    >
      {components.length === 0 ? (
        <div className="flex items-center justify-center h-full">
          <p className="text-gray-500 dark:text-gray-400">
            Drag and drop components here to build your template
          </p>
        </div>
      ) : (
        components.map((component, index) => renderComponent(component, index))
      )}
    </div>
  );
};

export default TemplateCanvas;
